import React, { useEffect, useState } from 'react';
import { Table, Spin, Button, Checkbox, Modal, message, Empty } from 'antd';
import { fixAmount } from '@/utils';
import './Footer.less';

interface IProps {
  checked: boolean;
  onChange?: (bool: boolean) => void;
  handleSubmit?: () => void;
  selectedRowKeys?: any[];
  list?: any;
  [props: string]: any;
}
function Footer(props: IProps) {
  const { selectedRowKeys = [], list } = props;
  const [priceCount, setPriceCount] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const handleSubmit = () => {
    props.handleSubmit && props.handleSubmit();
  };
  useEffect(() => {
    let count = 0;
    list.map((current: any) => {
      if (selectedRowKeys.includes(current.orderSkuId)) {
        count = count + current.actualPriceSubtotal;
      }
    });
    setPriceCount(fixAmount(count));
    const tempList = list.map((item: any) => item.orderSkuId);
    const selectedRowKeysTemps = selectedRowKeys.filter((id: number) =>
      tempList.includes(id),
    );
    // const flag =
    //   tempList.sort().toString() === selectedRowKeysTemps.sort().toString();
    const flag = selectedRowKeysTemps.length > 0;
    setDisableBtn(flag);
  }, [selectedRowKeys]);

  return (
    <div className="shopping-footer">
      <div className="col">
        <Checkbox
          checked={props.checked}
          onChange={(e: any) =>
            props.onChange && props.onChange(e.target.checked)
          }
        >
          全选
        </Checkbox>
      </div>
      <div className="col tleft"> </div>
      <div className="col tleft">
        已选商品
        <span className="goods-num">{selectedRowKeys.length}</span>件
      </div>
      <div className="col trow total-price">
        <div className="total">
          货品总金额： <span className="price">¥{priceCount}</span>
        </div>
      </div>
      <div className="col settlement-btn">
        <Button
          type="primary"
          className="btn"
          disabled={!disableBtn}
          onClick={handleSubmit}
        >
          确定
        </Button>
      </div>
    </div>
  );
}

export default Footer;
