import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
// Import Swiper styles
import './index.less';
// install Swiper modules
import Icon from '@/components/Icon';
import { connect, history, Dispatch } from 'umi';
import bannerImg from '@/assets/home/banner.png';
SwiperCore.use([Navigation, Pagination, Autoplay]);
interface IHSwiper {
  banners: any[];
  dispatch: Dispatch;
}
function HSwiper(props: IHSwiper) {
  const swiperRef = useRef<any>();
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => {
    swiperRef.current && swiperRef.current.autoplay.stop();
    setIsHover(true);
  };
  const onMouseLeave = () => {
    swiperRef.current && swiperRef.current.autoplay.start();
    setIsHover(false);
  };
  const handleSlide = async (item: any) => {
    if (item.skipType === 'GOODS') {
      history.push(`/goods/detail/${item.skipId}`);
      return;
    }
    if (item.skipType === 'H5') {
      window.open(item.skipUrl, '_blank');
    }
    // if (!item || !item.skipId) return;
    // if (item.skipType === 'CATEGORY') {
    //   await props.dispatch({ type: 'search/clear' });
    //   props.dispatch({
    //     type: 'search/saveCategoryItem',
    //     payload: {
    //       id: item.skipId,
    //       title: item.skipTitle || '',
    //     },
    //   });
    //   history.push({
    //     pathname: '/search/more',
    //     query: {
    //       tenantId: item.tenantId,
    //     },
    //   });
    // } else {
    //   history.push(`/goods/detail/${item.skipId}`);
    // }
  };

  return (
    <div
      className="home-swiper"
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      <Swiper
        loop={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        autoplay={{
          delay: 5000,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{
          clickable: true,
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            let paginationHtml = '';
            for (var i = 1; i <= total; i++) {
              if (i == current) {
                paginationHtml += `<div class='swiper-pagination-bullet-new swiper-pagination-bullet-active'>
                                  <Image src="${require('@/assets/home/home_icon_lunbo.png')}" />
                                </div>`;
              } else {
                paginationHtml += `<div class='swiper-pagination-bullet'>
                                  <span class='pagination_icon icon${i}'></span>
                                </div>`;
              }
            }
            return paginationHtml;
          },
        }}
        // onSlideChange={({ activeIndex }) => console.log(activeIndex)}
      >
        {props.banners.map((item, index: number) => (
          <SwiperSlide key={index} onClick={() => handleSlide(item)}>
            <div
              className="img"
              style={{ backgroundImage: `url(${item.pcUrl || bannerImg})` }}
            ></div>
          </SwiperSlide>
        ))}
        <div
          className={`swiper-button swiper-button-prev ${
            isHover ? 'hover' : ''
          }`}
        >
          <Icon className="icon" type="iconzuo" />
        </div>
        <div
          className={`swiper-button swiper-button-next ${
            isHover ? 'hover' : ''
          }`}
        >
          <Icon className="icon" type="iconyou" />
        </div>
      </Swiper>
    </div>
  );
}

export default connect()(HSwiper);
