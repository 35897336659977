import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  connect,
  Loading,
  Dispatch,
  SearchModelState,
  GoodsModelState,
} from 'umi';
import { Spin, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ossProcess } from '@/utils';
import Toggle from '@/pages/Order/components/ConfirmCoupon/toggle';
import './index.less';
interface IProps {
  params: any;
  loading: Loading;
  dispatch: Dispatch;
  goods: GoodsModelState;
  search: SearchModelState;
  onChange?: (selectedBrandList: any[]) => void;
}
function Index(props: IProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isShowMore, setIsShowMore] = useState(false);
  const [brandFilterList] = useState(
    '所有品牌,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z'.split(','),
  );

  const handleBrandItem = (value: number, label: string) => {
    const index = props.search.selectedBrandList.findIndex(
      (item) => item.value === value,
    );
    if (props.search.selectedBrandList.length >= 6 && index === -1) {
      message.warning('最多同时选择6个品牌');
      return;
    }
    if (index > -1) {
      props.dispatch({
        type: 'search/sliceSelectedBrandList',
        payload: {
          index,
        },
      });
    } else {
      props.dispatch({
        type: 'search/pushSelectedBrandList',
        payload: {
          value,
          label,
        },
      });
    }
  };
  const isBrandSelected = (value: number) => {
    return (
      props.search.selectedBrandList.findIndex((item) => item.value === value) >
      -1
    );
  };
  useEffect(() => {
    const pyHeader = selectedIndex === 0 ? '' : brandFilterList[selectedIndex];
    console.log('props', props);
    // if (!props.params.categoryId && !props.params.queryStr) {
    //   return;
    // }
    props.dispatch({
      type: 'goods/getBrandList',
      payload: {
        ...props.params,
        queryStr: props.params.queryStr,
        sortAsc: props.params.sort === 'ASC',
        namePy: pyHeader,
        name: '',
        brandIds: undefined,
      },
    });
  }, [selectedIndex, props.params]);
  return (
    <div className="search-more-brand-container">
      <div className="l">
        <div className="brand-filter-con">
          {brandFilterList.map((item, index) => (
            <div
              className={`item ${selectedIndex === index && 'selected'}`}
              onClick={() => setSelectedIndex(index)}
              key={item}
            >
              {item}
            </div>
          ))}
        </div>
        <Spin spinning={props.loading.effects['goods/getBrandList']}>
          <div className={`list ${isShowMore && 'more'}`}>
            {props.goods.brandList &&
              props.goods.brandList.map((item: any) => (
                <div
                  className={`brand-item ${
                    isBrandSelected(item.id) ? 'selected' : ''
                  }`}
                  key={item.id}
                  onClick={() => handleBrandItem(item.id, item.name)}
                  style={{ backgroundImage: `url('${item.logoUrl}')` }}
                  title={item.name}
                >
                  <div className="select-con">
                    <CheckOutlined />
                  </div>
                </div>
              ))}
          </div>
        </Spin>
      </div>
      <div className="handle-con">
        <Toggle onChange={setIsShowMore} />
      </div>
    </div>
  );
}
export default connect(
  ({
    search,
    loading,
    goods,
  }: {
    search: SearchModelState;
    loading: Loading;
    goods: GoodsModelState;
  }) => ({
    search,
    goods,
    loading,
  }),
)(Index);
