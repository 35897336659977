import { Button } from 'antd';
import { IUser } from 'umi';
import fundebug from 'fundebug-javascript';
import { useEffect } from 'react';
import './index.less';
fundebug.init({
  apikey: '41024ae68c0f9dbab3f9a8ee6bff91753fb2cd71b0a23bbf0e13420d8482b155',
  silent: process.env.CURRENT_ENV !== 'prod',
  filters: [
    {
      message: /请输入/,
    },
    {
      type: /^uncaught error$/,
    },
  ],
});
export default function ErrorFallback({
  error,
  resetErrorBoundary,
  user,
  token,
}: {
  error: any;
  resetErrorBoundary: () => void;
  user: IUser;
  token: string;
}) {
  useEffect(() => {
    if (error && process.env.CURRENT_ENV === 'prod') {
      fundebug.notifyError(error, {
        metaData: {
          token,
          // username: user.username,
          username: user.legalPerson,
        },
      });
    }
  }, [error]);
  return (
    <div className="error-fallback-container">
      <div>
        <p>抱歉，出现了一些问题</p>
        <Button type="primary" onClick={resetErrorBoundary}>
          刷新
        </Button>
      </div>
    </div>
  );
}
