import React, { useEffect, useMemo, useState } from 'react';
import { Image, Rate, Empty, Pagination, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './index.less';
import { connect, Dispatch, history, Loading } from 'umi';

interface ISearchShopItem {
  detail: any;
}

function SearchShopItem(props: ISearchShopItem) {
  const scrollDiv = React.useRef<HTMLDivElement>(null);
  const handleScroll = (x: number) => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollLeft += x;
    }
  };
  const handleShopClick = () => {
    history.push(`/shop/${props.detail.id}/category`);
  };
  const year = useMemo(() => {
    return Math.floor(props.detail.time / 365);
  }, [props.detail]);
  return (
    <div className="item-con">
      <div className="shop-item" onClick={handleShopClick}>
        <div className="media-con">
          <Image src={props.detail.enterpriseLogo} preview={false} />
        </div>
        <div className="shop-info-con">
          <h2 className="shop-title">{props.detail.companyName}</h2>
          <span className="star">2个工作日内发货</span>
          {/* <div className="date">
            经营时间：<span>{year}年</span>
          </div>
          <div className="service-star">
            <span className="star">服务星级：</span>
            <Rate
              className="rate"
              disabled
              allowHalf
              value={props.detail.serviceStar}
            />
          </div>
          <div className="bottom-con">
            <div className="bottom-title">主营：</div>
            <div
              className="bottom-value-con"
              title={props.detail.businessScope}
            >
              {props.detail.businessScope}
            </div>
          </div> */}
        </div>
      </div>
      {props.detail.spuVOS && props.detail.spuVOS.length ? (
        <div className="goods-list-con">
          <div
            className="icon-arrow icon-left"
            onClick={() => handleScroll(-110)}
          >
            <LeftOutlined color="#fff" />
          </div>
          <div className="goods-list" ref={scrollDiv}>
            {props.detail.spuVOS &&
              props.detail.spuVOS.map((item) => (
                <Link
                  key={item.id}
                  to={`/goods/detail/${item.id}`}
                  className="goods-item"
                >
                  <Image src={item.img} preview={false} />
                </Link>
              ))}
          </div>
          <div
            className="icon-arrow icon-right"
            onClick={() => handleScroll(110)}
          >
            <RightOutlined color="#fff" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

interface IProps {
  dispatch: Dispatch;
  loading: Loading;
}

function Index(props: IProps) {
  const [list, setList] = useState([]);
  const [pagin, setPagin] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const onPaginChange = (pageNo: number) => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setPagin({
      ...pagin,
      pageNo,
    });
  };
  useEffect(() => {
    const name = history.location.query?.keyWord;
    props
      .dispatch({
        type: 'user/tenantList',
        payload: {
          name,
          ...pagin,
        },
      })
      .then((res: any) => {
        setTotal(res.data.count);
        setList(res.data.items);
      });
  }, [history.location.query, pagin]);
  return (
    <div className="search-shop-container">
      <div className="result-con">
        <div className="w1200">
          找到 <span className="primary-color">{total}</span> 家商家
        </div>
      </div>
      <div className="search-shop-body">
        <Spin spinning={props.loading.effects['user/tenantList']}>
          <div className="w1200">
            <div className="list">
              {list && list.length ? (
                list.map((item, index) => (
                  <SearchShopItem key={index} detail={item} />
                ))
              ) : (
                <Empty style={{ padding: '30px 0 50px' }} />
              )}
            </div>
            {total > pagin.pageSize ? (
              <div className="pagin-con">
                <Pagination
                  total={total}
                  onChange={onPaginChange}
                  showSizeChanger={false}
                  showQuickJumper
                />
              </div>
            ) : null}
          </div>
        </Spin>
      </div>
    </div>
  );
}
export default connect(({ loading }: { loading: Loading }) => ({ loading }))(
  Index,
);
