import React from 'react';
import './index.less';

const list = [
  {
    title: '1.进货单',
  },
  {
    title: '2.确认订单',
  },
  {
    title: '3.支付订单',
  },
  {
    title: '4.确认收货',
  },
  {
    title: '5.完成订单',
  },
];
interface IProps {
  current: number;
}
function SuProgressbar(props: IProps) {
  const { current } = props;
  return (
    <div className="su-progress">
      {list.map((item: any, index: number) => (
        <div
          className={`progress-item ${current > index + 1 ? `actived` : ''} ${
            current === index + 1 ? 'active' : ''
          }`}
        >
          <div className="circle">{index + 1}</div>
          <div className="title">{item.title}</div>
          <div className="line"></div>
        </div>
      ))}
    </div>
  );
}

export default SuProgressbar;
