import React, { useState } from 'react';
import { Image, Button, Tooltip } from 'antd';
import Icon from '@/components/Icon';
import { history } from 'umi';
import { refundStatusMap } from '@/utils/enum';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.less';
interface IItemProps {
  id: number;
  /**
   * 公司名字
   */
  tenantName: number;
  /**
   * 实付款
   */
  actualRefundAmount: number;
  /**
   * 创建时间
   */
  gmtCreate: string;
  /**
   * 订单号
   */
  orderNo: string;
  /**
   * 退款订单号
   */
  refundOrderNo: string;
  /**
   * 运费
   */
  freightPrice: string;
  /**
   * 订单sku
   */
  refundOrderSkuVOList: any[];
  /**
   * 订单状态
   */
  status: string | number;
  refundType: number;
  /**
   * 是否售后 转退款(0-非退款状态，1-退款状态)
   */
  goRefund: string;
  [props: string]: any;
  /**
   * 撤销订单
   */
  handleDelete: (val: any) => void;
  /**
   * 一键入库
   */
  onOpenWarehousingModal: (val: any) => void;
}

type IProps = IItemProps;
function GoodRow(props: IProps) {
  const {
    id,
    orderNo,
    gmtCreate,
    freightPrice,
    actualRefundAmount,
    tenantName,
    tenantId,
    refundOrderSkuVOList = [],
    status,
    refundOrderNo,
    adminRemark,
    refundType,
  } = props;
  const [showMoreGood, setShowMoreGood] = useState(false);
  const handleDelete = () =>
    props.handleDelete && props.handleDelete(refundOrderNo);

  const handleGoto = (pathname: string, query?: any) => {
    history.push({
      pathname,
      query,
    });
  };
  return (
    <div className="good-row" key={id}>
      <div className="good-title">
        <p className="t-l">
          <span className="t-label">退款单号：</span>
          <span className="t-value">{refundOrderNo}</span>
          <span className="t-label">订单号：</span>
          <span className="t-value">{orderNo}</span>
          <span
            className="t-company"
            onClick={() => handleGoto(`/shop/${tenantId}/category`)}
          >
            {tenantName}
          </span>
        </p>
        <p className="t-r">
          <Icon className="icon" type="iconshijian1" />
          <span>{gmtCreate}</span>
        </p>
      </div>
      <div className="good-content">
        <div className={`g-l ${showMoreGood ? 'more' : ''}`}>
          {refundOrderSkuVOList.length > 0 &&
            refundOrderSkuVOList.map((item: any) => (
              <div className="good-item-info">
                <Image src={item.skuImg} className="g-img" />
                <div className="good-info">
                  <div className="g-info-left">
                    <div className="g-title">{item.spuTitle || ''}</div>
                    <div className="g-skuTitle">
                      {item.attributeVOList &&
                        item.attributeVOList.map((attr: any) => (
                          <span className="sku-tag">
                            {attr.name}：{attr.value}
                          </span>
                        ))}
                    </div>
                  </div>

                  <div className="g-num">x{item.refundNum || 0}</div>
                  <div className="g-price">
                    {item.isPresent === 1 ? (
                      <span className="gift-tag">赠品</span>
                    ) : (
                      <p className="price">¥{item.actualPrice || 0}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          {!showMoreGood && refundOrderSkuVOList.length > 3 && (
            <div className="g-more" onClick={() => setShowMoreGood(true)}>
              查看更多货品
            </div>
          )}
        </div>
        <div className="g-r border">
          <div className="info-item" style={{ flex: '10%' }}>
            <p className="price">¥{actualRefundAmount || 0}</p>
            <p className="freight">(含运费：¥{freightPrice || 0})</p>
          </div>
          <div
            className="info-item"
            style={{ textAlign: 'left', paddingLeft: 14 }}
          >
            <span className="o-type">
              {refundType === 0 ? '仅退款' : refundType === 1 ? '退货退款' : ''}
            </span>
          </div>
          <div className="info-item">
            <p className="status">
              {(status === 1300 || status === 1400) && (
                <Tooltip
                  placement="topRight"
                  title={adminRemark}
                  color="#ffffff"
                >
                  {`退款失败`}
                  <ExclamationCircleOutlined className="icon" />
                </Tooltip>
              )}
              {status !== 1300 && status !== 1400 && refundStatusMap[status]}
            </p>
            <p
              className="detail"
              onClick={() =>
                history.push({
                  pathname: '/user/order/refund/detail',
                  query: {
                    refundOrderNo: refundOrderNo,
                  },
                })
              }
            >
              订单详情
            </p>
          </div>
          <div className="info-item">
            {status === 1000 && (
              <Button className="btn-link" type="link" onClick={handleDelete}>
                撤销申请
              </Button>
            )}
            {/* {(status === 1300 || status === 1400) && (
              <Button
                className="btn-link"
                type="link"
                onClick={() =>
                  history.push(`/user/order/applyRefund?orderNo=${orderNo}`)
                }
              >
                申请退款/退货退款
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoodRow;
