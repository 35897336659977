import React, { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Spin, Select } from 'antd';
/**
 * 防抖远程搜索select，用于搜索会员
 * @returns
 */
export default function LogisticsDebounceSelect({
  fetchOptions,
  debounceTimeout = 300,
  ...props
}) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  const [scrollLogisticsPage, setScrollLogisticsPage] = React.useState(1);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      setKeyword(value);
      setScrollLogisticsPage(1);
      fetchOptions({ value, pageNo: 1 }).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const handleLogisticsOnPopupScroll = (e: any) => {
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const nextScrollPage = scrollLogisticsPage + 1;
      setScrollLogisticsPage(nextScrollPage);
      setFetching(true);
      fetchOptions({ value: keyword, pageNo: nextScrollPage }).then(
        (newOptions: any) => {
          setOptions(options.concat(newOptions));
          setFetching(false);
        },
      );
    }
  };
  useEffect(() => {
    props.initkeyword && debounceFetcher(props.initkeyword);
  }, [props.initkeyword]);
  return (
    <Select
      allowClear={props.allowClear}
      onClear={props.onClear}
      filterOption={false}
      disabled={props.disabled}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      onPopupScroll={handleLogisticsOnPopupScroll}
    />
  );
} // Usage of DebounceSelect

LogisticsDebounceSelect.defaultProps = {
  onClear: () => ({}),
  allowClear: false,
};
