import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Tabs,
  Select,
  Modal,
  Pagination,
  Empty,
  Spin,
  Tooltip,
  DatePicker,
  Badge,
} from 'antd';
import { history, withRouter, connect, OrderModelState, Loading } from 'umi';
import WarehousingModal from '@/components/User/Order/Warehousing';
import OrderModal from '@/components/User/Order/OrderModal';
import Icon from '@/components/Icon';
import { orderStatusMap } from '@/utils/enum';
import moment from 'moment';
import GoodRow from './components/GoodRow/Index';
import emptyImg from '@/assets/common/empty.png';
import { limitDecimals } from '@/pages/Search/Result';
import './Purchase.less';
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

function Purchase(props: any) {
  // state
  const [form] = Form.useForm();
  const [tabKey, setTabKey] = useState('1');
  const [visible, setVisible] = useState(false);
  const [orderIdNew, setOrderIdNew] = useState(0);
  const [delVisible, setDelVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  // 一键入库弹窗
  const [wareId, setWareId] = useState('');
  const [wareHouseVisible, setWareHouseVisible] = useState(false);
  const [wareSkus, setWareSkus] = useState<any>([]);
  const [data, setData] = useState([]);
  const [listQuery, setListQuery] = useState({
    pageNo: 1,
    pageSize: 10,
    item: {
      searchWord: '',
      status: 0,
      startPrice: undefined,
      endPrice: undefined,
      startTime: undefined,
      endTime: undefined,
    },
  });
  const [menuActive, setMenuActive] = useState(0);
  const [total, setTotal] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const isFirstRun = useRef(true);
  // 立即付款+取消订单+申请退款前的校验
  const [orderModalVisible, setOrderModalVisible] = useState(false);
  const [orderModalId, setOrderModalId] = useState(0);
  const [orderModalOrderNo, setOrderModalOrderNo] = useState('');
  const [orderModalType, setOrderModalType] = useState(1);
  const [purchaseWait, setPurchaseWait] = useState(0);
  useEffect(() => {
    getOrderList();
  }, [listQuery.pageNo, listQuery.item.status]);
  useEffect(() => {
    const query = history.location.query;
    if (query && query.status) {
      setTabKey(query.status + '');
      setTimeout(() => {
        setListQuery({
          ...listQuery,
          pageNo: 1,
          item: {
            ...listQuery.item,
            status: Number(query.status + ''),
          },
        });
      }, 100);
    }
  }, []);
  // methods
  // 获取订单列表
  const getOrderList = () => {
    setTableLoading(true);
    const values = form.getFieldsValue();
    let newItem = {};
    if (tabKey === '1') {
      newItem = {
        ...listQuery.item,
        ...values,
        orderType: 0,
      };
    } else {
      newItem = {
        ...listQuery.item,
        orderType: 0,
      };
    }
    getHomeOrderNum();
    props
      .dispatch({
        type: 'userOrder/getList',
        payload: {
          ...listQuery,
          item: {
            ...newItem,
            searchWord: newItem.searchWord
              ? newItem.searchWord.trim()
              : undefined,
          },
        },
      })
      .then((res: any) => {
        setTotal(res.count);
        setData(res.items || []);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getHomeOrderNum = () => {
    props
      .dispatch({
        type: 'order/getHomeOrderNum',
      })
      .then((data: any) => {
        setPurchaseWait(data.purchaseWait || 0);
      });
  };

  const tabsChange = (key: string) => {
    form.resetFields();
    setMenuActive(0);
    setTabKey(key);
    if (key !== '1') {
      setListQuery({
        ...listQuery,
        pageNo: 1,
        item: {
          ...listQuery.item,
          searchWord: '',
          status: Number(key),
        },
      });
    } else {
      setListQuery({
        ...listQuery,
        pageNo: 1,
        item: {
          ...listQuery.item,
          searchWord: '',
          status: 1,
        },
      });
    }
  };
  // tabs里边更多的点击事件
  const moreChange = async (orderType: number) => {
    form.resetFields();
    setMenuActive(orderType);
    setTabKey(String(orderType));
    setListQuery({
      ...listQuery,
      pageNo: 1,
      item: {
        searchWord: '',
        status: orderType,
      },
    });
  };
  // 表单提交
  const onFinish = (values: any) => {
    if (listQuery.pageNo === 1) {
      getOrderList();
    } else {
      setListQuery({
        ...listQuery,
        pageNo: 1,
      });
    }
  };
  // 取消订单弹窗
  // 打开弹窗
  const onOpenModal = (data: any) => {
    setOrderIdNew(data.id);
    setVisible(true);
  };
  // 关闭弹窗
  const onCancelModal = () => {
    setVisible(false);
  };
  // 弹窗确认
  const handleModalOk = () => {
    props
      .dispatch({
        type: 'userOrder/cancelOrder',
        payload: {
          orderId: orderIdNew,
        },
      })
      .then((res: boolean) => {
        setVisible(false);
        getOrderList();
      });
  };
  // 删除订单弹窗
  // 打开弹窗
  const onOpenModalDel = () => {
    setDelVisible(true);
  };
  // 关闭弹窗
  const onCancelModalDel = () => {
    setDelVisible(false);
  };
  // 弹窗确认
  const handleModalDel = (data: any) => {
    props
      .dispatch({
        type: 'userOrder/delOrder',
        payload: {
          orderId: data.id,
        },
      })
      .then((res: boolean) => {
        setDelVisible(false);
      });
  };
  // 确认收货弹窗
  // 打开弹窗
  const onOpenConfirmModal = (data: any) => {
    setOrderIdNew(data.id);
    setConfirmVisible(true);
  };
  // 关闭弹窗
  const onCanceConfirmModal = () => {
    setConfirmVisible(false);
  };
  // 弹窗确认
  const handleConfirmModalOk = (data: any) => {
    props
      .dispatch({
        type: 'userOrder/orderConfrim',
        payload: {
          orderId: orderIdNew,
        },
      })
      .then((res: boolean) => {
        // 设置状态
        // setListQuery({
        //   ...listQuery,
        //   item: {
        //     searchWord: '',
        //     status: Number(tabKey),
        //   },
        // });
        getOrderList();
        setConfirmVisible(false);
      });
  };
  // 一键入库弹窗
  // 打开弹窗
  const onOpenWarehousingModal = (data: any) => {
    setWareId(data.id);
    setWareSkus([...data.orderSkuVOList]);
    setWareHouseVisible(true);
  };
  // 关闭弹窗
  const onCanceWarehousingModal = () => {
    setWareHouseVisible(false);
  };
  // 弹窗确认
  const handleWarehousingModalOk = () => {
    setWareHouseVisible(false);
    getOrderList();
  };
  // 立即付款+取消订单+申请退款前的校验
  // 查询是否有关联订单
  // 打开校验弹窗
  const openOrderModal = (item: any, type: number) => {
    setOrderModalId(item.id);
    setOrderModalOrderNo(item.orderNo);
    setOrderModalType(type);
    setOrderModalVisible(true);
  };
  // 弹窗回调
  const changeVisible = (flag: boolean, refresh: boolean) => {
    setOrderModalVisible(flag);
    if (refresh) {
      getOrderList();
    }
  };
  return (
    <div className="purchase-container">
      {/* 头部tabs */}
      <div className="tabs">
        <Tabs activeKey={tabKey} onChange={tabsChange}>
          <TabPane tab="全部订单" key="1" />
          <TabPane
            tab={
              <Badge count={purchaseWait} offset={[2, -6]}>
                待付款
              </Badge>
            }
            key="10"
          />
          <TabPane tab="待发货" key="20" />
          <TabPane tab="待收货" key="30" />
        </Tabs>
        <div className="more">
          更多
          <Icon
            className="icon"
            style={{ fontSize: '12px', marginLeft: '14px' }}
            type="iconleixingshaixuanjiantou"
          />
          <div className="menu-inner">
            <div
              className={menuActive === 50 ? 'item active' : 'item'}
              onClick={() => moreChange(50)}
            >
              交易完成
            </div>
            <div
              className={menuActive === -1 ? 'item active' : 'item'}
              onClick={() => moreChange(-1)}
            >
              交易关闭
            </div>
            <div
              className={menuActive === -2 ? 'item active' : 'item'}
              onClick={() => moreChange(-2)}
            >
              售后处理中
            </div>
          </div>
        </div>
      </div>
      {/* 搜索区域 */}
      {tabKey === '1' && (
        <div className="search">
          <Form layout="inline" form={form} onFinish={onFinish}>
            <Form.Item name="searchWord" label="订单关键词">
              <Input
                style={{ width: 190 }}
                allowClear
                placeholder="请输入货品名称/订单号"
              />
            </Form.Item>
            <Form.Item name="status" label="订单状态">
              <Select style={{ width: 190 }} allowClear placeholder="全部订单">
                <Option value={10}>待付款</Option>
                <Option value={20}>待发货</Option>
                <Option value={30}>待收货</Option>
                <Option value={50}>交易成功</Option>
                <Option value={70}>交易关闭(已退款)</Option>
                <Option value={80}>交易关闭(已取消)</Option>
                <Option value={90}>交易关闭(超时)</Option>
                <Option value={-2}>售后处理中</Option>
              </Select>
            </Form.Item>
            <Form.Item name="time" label="下单时间">
              <RangePicker
                style={{ width: 340 }}
                showTime
                onChange={(val: any) => {
                  setListQuery({
                    ...listQuery,
                    item: {
                      ...listQuery.item,
                      startTime:
                        val && val.length > 0
                          ? val[0].format('YYYY-MM-DD HH:mm:ss')
                          : undefined,
                      endTime:
                        val && val.length > 0
                          ? val[1].format('YYYY-MM-DD HH:mm:ss')
                          : undefined,
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item name="price" label="或金额区间">
              <Input.Group compact>
                <Input
                  style={{ width: 100, textAlign: 'center' }}
                  placeholder="输入金额"
                  onChange={(e: any) => {
                    const val = limitDecimals(e.target.value);
                    setListQuery({
                      ...listQuery,
                      item: {
                        ...listQuery.item,
                        startPrice: val === '' ? undefined : val,
                      },
                    });
                  }}
                />
                <Input
                  className="site-input-split"
                  style={{
                    width: 30,
                    borderLeft: 0,
                    borderRight: 0,
                    pointerEvents: 'none',
                    background: '#fff',
                  }}
                  placeholder="~"
                  disabled
                />
                <Input
                  className="site-input-right"
                  style={{
                    width: 100,
                    textAlign: 'center',
                    borderLeft: 0,
                  }}
                  placeholder="输入金额"
                  onChange={(e: any) => {
                    const val = limitDecimals(e.target.value);
                    setListQuery({
                      ...listQuery,
                      item: {
                        ...listQuery.item,
                        endPrice: val === '' ? undefined : val,
                      },
                    });
                  }}
                />
              </Input.Group>
            </Form.Item>
            <Form.Item>
              <Button className="btn" type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      {/* 主体订单 */}
      <div className={`table-main ${tabKey !== '1' ? 'mt20' : ''}`}>
        <Spin spinning={tableLoading}>
          <div className="table-inner">
            <div className="inner-head">
              <div className="head-item1 head-item">货品信息</div>
              <div className="head-item2 head-item">单价（元）</div>
              <div className="head-item3 head-item">数量</div>
              <div className="head-item4 head-item">实付款（元）</div>
              <div className="head-item5 head-item">订单状态</div>
              <div className="head-item6 head-item">操作</div>
            </div>
            {data.length > 0 && (
              <div className="pagination">
                <Pagination
                  current={listQuery.pageNo}
                  total={total}
                  showSizeChanger={false}
                  showQuickJumper
                  onChange={(page, pageSize) => {
                    setListQuery({
                      ...listQuery,
                      pageNo: page,
                    });
                  }}
                />
              </div>
            )}
            {data.length > 0 &&
              data.map((item: any, index: number) => (
                <GoodRow
                  {...item}
                  key={index}
                  onOpenModal={onOpenModal}
                  onOpenConfirmModal={onOpenConfirmModal}
                />
              ))}
            {data.length > 0 && (
              <div className="pagination">
                <Pagination
                  current={listQuery.pageNo}
                  total={total}
                  onChange={(page, pageSize) => {
                    setListQuery({
                      ...listQuery,
                      pageNo: page,
                    });
                  }}
                />
              </div>
            )}
            {data.length === 0 && (
              <Empty
                image={emptyImg}
                description="您还没有订单哦，快去购买"
                imageStyle={{
                  width: '300px',
                  height: '207px',
                  margin: '65px auto',
                  color: '#666',
                }}
              />
            )}
          </div>
        </Spin>
      </div>
      {/* 取消订单弹窗 */}
      <Modal
        visible={visible}
        onOk={handleModalOk}
        onCancel={onCancelModal}
        wrapClassName="purchase-modal-custom"
        width="640px"
        confirmLoading={props.loading.effects['userOrder/cancelOrder']}
        title={
          <div className="modal-tit">
            <Icon
              className="icon"
              style={{ fontSize: '24px', marginRight: '13px' }}
              type="icondingdanhao"
            />
            <p>操作提醒</p>
          </div>
        }
      >
        <div className="modal-body">
          <p className="modal-p-child1">您确定要取消该订单吗？</p>
          <p className="modal-p-child2">
            您确定要取消该订单吗？取消订单后，不能恢复。
          </p>
        </div>
      </Modal>
      {/* 删除订单弹窗 */}
      <Modal
        visible={delVisible}
        onOk={handleModalDel}
        onCancel={onCancelModalDel}
        title={
          <div className="modal-tit">
            <Icon
              className="icon"
              style={{ fontSize: '24px', marginRight: '13px' }}
              type="icondingdanhao"
            />
            <p>操作提醒</p>
          </div>
        }
        width="640px"
      >
        <div className="modal-body">
          <p className="modal-p-child1">您确定要删除该订单吗？</p>
          <p className="modal-p-child2">
            您确定要删除该订单吗？删除订单后，不能恢复。
          </p>
        </div>
      </Modal>
      {/* 确认收货弹窗 */}
      <Modal
        visible={confirmVisible}
        onOk={handleConfirmModalOk}
        onCancel={onCanceConfirmModal}
        className="order-confirm-ok"
        title={
          <div className="modal-tit">
            <Icon
              className="icon"
              style={{ fontSize: '24px', marginRight: '13px' }}
              type="icondingdanhao"
            />
            <p>操作提醒</p>
          </div>
        }
        width="640px"
      >
        <div className="modal-body">
          <p className="modal-p-child1">请确认是否收到货？</p>
        </div>
      </Modal>
      {/* 一键入库弹窗 */}
      {wareHouseVisible && (
        <WarehousingModal
          visible={wareHouseVisible}
          orderId={wareId}
          skus={wareSkus}
          onSuccess={handleWarehousingModalOk}
          onCancel={onCanceWarehousingModal}
        />
      )}
      {/* 立即付款+取消订单+申请退款前对于关联订单的校验弹窗 */}
      <OrderModal
        visible={orderModalVisible}
        id={orderModalId}
        orderNo={orderModalOrderNo}
        type={orderModalType}
        changeVisible={changeVisible}
      />
    </div>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(Purchase);
