import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Tabs,
  Select,
  Modal,
  Pagination,
  Empty,
  Spin,
  DatePicker,
} from 'antd';
import { history, connect, OrderModelState, Loading } from 'umi';
import tishiIcon from '@/assets/common/tishi.svg';
import GoodRow from './components/GoodRow/Index';
import emptyImg from '@/assets/common/empty.png';
const { RangePicker } = DatePicker;
import './index.less';
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;

function Refund(props: any) {
  // state
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [listQuery, setListQuery] = useState({
    pageNo: 1,
    pageSize: 10,
    item: {},
    total: 0,
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [tabKey, setTabKey] = useState('1');
  const [menuActive, setMenuActive] = useState(0);
  // 生命周期
  useEffect(() => {
    getRefundList();
  }, [listQuery.pageNo, listQuery.item.status]);
  // methods
  // 获取订单列表
  const getRefundList = () => {
    setTableLoading(true);
    const values = form.getFieldsValue();

    let newItem = {};
    if (tabKey === '1') {
      const status =
        values.status === 1500
          ? [1300, 1400, 1500]
          : values.status === 1300
          ? [1300, 1400]
          : values.status > 0
          ? [values.status]
          : undefined;
      newItem = {
        ...listQuery.item,
        ...values,
        status,
      };
    } else {
      const status =
        tabKey === '1500'
          ? [1300, 1400, 1500]
          : tabKey === '1300'
          ? [1300, 1400]
          : tabKey * 1 > 0
          ? [tabKey]
          : undefined;
      newItem = {
        ...listQuery.item,
        status,
      };
    }
    if (newItem.refundType && newItem.refundType === -1)
      delete newItem.refundType;
    props
      .dispatch({
        type: 'userOrder/getRefundList',
        payload: {
          ...listQuery,
          item: {
            ...newItem,
          },
        },
      })
      .then((res: any) => {
        setData(res?.items || []);
        setListQuery({
          ...listQuery,
          total: res?.count || 0,
        });
      })
      .finally(() => {
        setTableLoading(false);
      });
  };
  // 表单提交
  const handleSearch = () => {
    // const values = form.getFieldsValue();
    // console.log('values', values);
    // const status =
    //   values.status === 1500
    //     ? [1300, 1400, 1500]
    //     : values.status
    //     ? [values.status]
    //     : undefined;
    // const data = {
    //   ...values,
    //   status,
    // };
    if (listQuery.pageNo === 1) {
      getRefundList();
    } else {
      setListQuery({
        ...listQuery,
        pageNo: 1,
      });
    }
  };
  // 跳转订单详情
  const handleDetail = (record: any) => {
    console.log(record);
    history.push({
      pathname: '/user/order/refund/detail',
      query: {
        refundOrderNo: record.refundOrderNo,
      },
    });
  };
  // 撤销申请
  const handleDelete = (refundOrderNo: string) => {
    confirm({
      title: '确认要取消退款申请吗',
      content:
        '取消退款申请后，本次退款将关闭，您还可以再次发起退款，确定继续吗',
      className: 'custom-2',
      icon: <img className="icon" src={tishiIcon} />,
      onOk() {
        props
          .dispatch({
            type: 'userOrder/cancelRefund',
            payload: { refundOrderNo: refundOrderNo },
          })
          .then((res: boolean) => {
            handleSearch();
          });
      },
    });
  };
  const tabsChange = (key: string) => {
    form.resetFields();
    setMenuActive(0);
    setTabKey(key);
    if (key !== '1') {
      setListQuery({
        ...listQuery,
        pageNo: 1,
        item: {
          ...listQuery.item,
          status: Number(key),
        },
      });
    } else {
      setListQuery({
        ...listQuery,
        pageNo: 1,
        item: {
          ...listQuery.item,
          status: 1,
        },
      });
    }
  };
  return (
    <div className="refund-container refund">
      {/* 头部tabs */}
      <div className="tabs">
        <Tabs activeKey={tabKey} onChange={tabsChange}>
          <TabPane tab="全部订单" key="1" />
          <TabPane tab="售后处理中" key="1000" />
          <TabPane tab="退款成功" key="1200" />
          <TabPane tab="退款失败" key="1300" />
          <TabPane tab="退款关闭" key="1500" />
        </Tabs>
      </div>
      {/* 搜索区域 */}

      {tabKey === '1' && (
        <div className="search-refund">
          <div>
            <Form layout="inline" form={form}>
              <Form.Item name="refundOrderNo" label="退款编号">
                <Input
                  style={{ width: 194 }}
                  placeholder="请输入退款编号"
                  allowClear
                />
              </Form.Item>
              <Form.Item name="status" label="退款状态">
                <Select
                  style={{ width: 194 }}
                  placeholder="请选择退款状态"
                  allowClear
                >
                  <Option value={-1}>全部</Option>
                  <Option value={1000}>售后处理中</Option>
                  <Option value={1200}>退款成功</Option>
                  <Option value={1300}>退款失败</Option>
                  <Option value={1500}>退款关闭</Option>
                </Select>
              </Form.Item>
              <Form.Item name="refundType" label="订单类型">
                <Select
                  style={{ width: 194 }}
                  placeholder="请选择订单类型"
                  allowClear
                >
                  <Option value={-1}>全部</Option>
                  <Option value={0}>仅退款</Option>
                  <Option value={1}>退货退款</Option>
                </Select>
              </Form.Item>
              <Form.Item name="time" label="申请时间">
                <RangePicker
                  style={{ width: 340 }}
                  showTime
                  onChange={(val: any) => {
                    setListQuery({
                      ...listQuery,
                      item: {
                        ...listQuery.item,
                        startTime:
                          val && val.length > 0
                            ? val[0].format('YYYY-MM-DD HH:mm:ss')
                            : undefined,
                        endTime:
                          val && val.length > 0
                            ? val[1].format('YYYY-MM-DD HH:mm:ss')
                            : undefined,
                      },
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="btn"
                  type="primary"
                  onClick={() => handleSearch()}
                >
                  搜索
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
      {/* 主体订单 */}
      <div className="table-main">
        <Spin spinning={tableLoading}>
          <div className="table-inner">
            <div className="inner-head">
              <div className="head-item1 head-item">货品信息</div>
              <div className="head-item7 head-item">数量</div>
              <div className="head-item2 head-item">已付金额（元）</div>
              <div className="head-item3 head-item">退款款额（元）</div>
              <div className="head-item4 head-item">退款类型</div>
              <div className="head-item5 head-item">订单状态</div>
              <div className="head-item6 head-item">操作</div>
            </div>
            {data.length > 0 && (
              <div className="pagination">
                <Pagination
                  current={listQuery.pageNo}
                  total={listQuery.total}
                  showSizeChanger={false}
                  showQuickJumper
                  onChange={(page, pageSize) => {
                    console.log(page, pageSize);
                    setListQuery({
                      ...listQuery,
                      pageNo: page,
                    });
                  }}
                />
              </div>
            )}
            {data.length > 0 &&
              data.map((item: any, index: number) => (
                <GoodRow {...item} key={index} handleDelete={handleDelete} />
              ))}
            {data.length > 0 && (
              <div className="pagination">
                <Pagination
                  current={listQuery.pageNo}
                  total={listQuery.total}
                  onChange={(page, pageSize) => {
                    console.log(page, pageSize);
                    setListQuery({
                      ...listQuery,
                      pageNo: page,
                    });
                  }}
                />
              </div>
            )}
            {data.length === 0 && (
              <Empty
                image={emptyImg}
                description="您还没有订单哦，快去购买"
                imageStyle={{
                  width: '300px',
                  height: '207px',
                  margin: '50px auto',
                  color: '#666',
                }}
              />
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(Refund);
