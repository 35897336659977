import React, { useEffect, useRef } from 'react';
import { connect, Dispatch } from 'umi';
import { useState } from 'react';
import { Button, message } from 'antd';
import moment from 'moment';
import { UpOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
interface IProps {
  title: string;
  type?: 'reveice';
  onClick?: () => void;
  onClose?: () => void;
  onInitList?: () => void;
  onReveice?: (index: number) => void;
  detail?: any;
  list?: any[];
  dispatch: Dispatch;
}
import './item.less';
function Index(props: IProps) {
  const [isShowReveice, setIsShowReveice] = useState(false);
  const [loading, setLoading] = useState(false);
  const refCom = useRef();
  const onClick = () => {
    if (props.type === 'reveice') {
      setIsShowReveice(!isShowReveice);
    }
    props.onClick && props.onClick();
  };
  const onClose = () => {
    setIsShowReveice(false);
  };

  const handleReveice = (id: number, index: number) => {
    if (loading) {
      return;
    }
    setLoading(true);
    props
      .dispatch({
        type: 'goods/couponReceive',
        payload: [id],
      })
      .then((data: any) => {
        message.success('领取成功');
        props.onReveice && props.onReveice(index);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlClickCouponContainer = (e: any) => {
    if (refCom.current && refCom.current.contains(e.target)) {
      // console.log('click inside');
      // setIsShowReveice(true);
    } else {
      // console.log('click outside');
      setIsShowReveice(false);
    }
  };

  useEffect(() => {
    if (isShowReveice) {
      props.onInitList && props.onInitList();
    }
  }, [isShowReveice]);

  useEffect(() => {
    document.addEventListener('click', handlClickCouponContainer);
    return () => {
      document.removeEventListener('click', handlClickCouponContainer);
    };
  }, []);

  return (
    <div className="__goods-coupon-item-container" ref={refCom}>
      <div className="container _coupon" onClick={onClick}>
        <span>{props.title}</span>
        {props.type === 'reveice' && (
          <div className="reveice-icon">
            {isShowReveice ? <UpOutlined /> : <DownOutlined />}
          </div>
        )}
      </div>
      {isShowReveice && (
        <div className="receive-con">
          <div className="title-con">
            <span>可领取优惠券</span>
            <CloseOutlined className="close" onClick={onClose} />
          </div>
          <div className="receive-coupon-list">
            {props.list?.map((item, index) => (
              <div className="receive-content" key={index}>
                <div className="_coupon-item _coupon">
                  满{item.min}-{item.discount}
                </div>
                <div className="desc-con">
                  <div className="desc-con-title">{item.title}</div>
                  {item.startTime && item.endTime && (
                    <div className="desc-con-tip">
                      {moment(item.startTime).format('YYYY-MM-DD')}-
                      {moment(item.endTime).format('YYYY-MM-DD')}
                    </div>
                  )}
                </div>
                <Button
                  ghost
                  type="primary"
                  className="btn"
                  onClick={() => handleReveice(item.id, index)}
                >
                  领取
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
export default connect()(Index);
