import React, { useEffect, useState } from 'react';
import './index.less';
import Item from '@/components/Home/List/Item';
import { Button, Input, Pagination, Spin, Empty, InputNumber } from 'antd';
import Icon from '@/components/Icon';
import { connect, Loading } from 'umi';
interface Iprops {
  [props: string]: any;
}
export const limitDecimals = (value: any) => {
  if (value.length > 6 && value.indexOf('.') === -1) {
    return value.substring(0, 6);
  }
  var RegStr = `^\\d{0,6}\\.?\\d{0,2}`;
  const inpValArr = value.match(new RegExp(RegStr, 'g'));

  if (inpValArr && inpValArr.length > 0) {
    return inpValArr[inpValArr.length - 1];
  } else {
    return '';
  }
};
function Result(props: Iprops) {
  const [filterIconActive, setFilterIconActive] = useState(true);
  const [goodsList, setGoodsList] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState('');
  const [startPrice, setStartPrice] = useState<any>('');
  const [endPrice, setEndPrice] = useState<any>('');
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 20,
  });
  const [searchParams, setSearchParams] = useState<any>({
    bySynthetical: true,
    keyword: '',
    categoryId: '',
    sort: undefined,
    sortName: undefined,
    section: undefined,
    total: 0,
  });
  // 综合
  const goFiltersynthesis = () => {
    const params = {
      ...searchParams,
      bySynthetical: true,
      sortName: undefined,
    };
    setSearchParams(params);
    getSearch(params);
    setFilterIconActive(true);
  };
  // 价格
  const handleChangeFilterIcon = () => {
    setFilterIconActive(!filterIconActive);
    const params = {
      ...searchParams,
      bySynthetical: false,
      sortName: 'PRICE',
      sort: !filterIconActive ? 'ASC' : 'DESC',
    };
    setSearchParams(params);
    getSearch(params);
  };
  // 价格区间
  const handleSearchPrice = () => {
    let frontSection = startPrice ? startPrice * 100 : undefined;
    let afterSection = endPrice ? endPrice * 100 : undefined;
    const params = {
      ...searchParams,
      frontSection,
      afterSection,
      keyword: '',
    };
    setSearchParams(params);
    getSearch(params);
  };

  // 分页
  const handlePagationChange = (pageNo: number, size?: number) => {
    setParams({
      ...params,
      pageNo,
      pageSize: size ? size : params.pageSize,
    });
  };

  const getSearch = (newParams?: any) => {
    const payload = {
      ...params,
      ...searchParams,
      ...newParams,
    };
    props
      .dispatch({
        type: 'goods/list',
        payload,
      })
      .then((res: any) => {
        if (res) {
          setSearchParams({
            ...searchParams,
            ...newParams,
            total: res.total,
          });
          setGoodsList(res.list || []);
        }
      });
  };

  useEffect(() => {
    const query = props.location.query;
    if (query && query.categoryId) {
      setCategoryTitle(query.categoryTitle);
      getSearch({
        ...searchParams,
        categoryId: query.categoryId * 1,
        keyword: query.keyWord ? query.keyWord : '',
      });
    } else if (query && query.keyWord) {
      const params = {
        item: {
          bySynthetical: true,
          keyword: '',
          categoryId: '',
          sort: undefined,
          sortName: undefined,
          section: undefined,
        },
        total: 0,
      };
      setSearchParams(params);
      setStartPrice('');
      setEndPrice('');
      setCategoryTitle('');
      getSearch({
        ...params,
        item: {
          ...params.item,
          keyword: query.keyWord,
        },
      });
    } else {
      getSearch({
        item: {
          bySynthetical: true,
          keyword: '',
          categoryId: '',
          sort: undefined,
          sortName: undefined,
          section: undefined,
        },
        total: 0,
      });
    }
  }, [params, props.location]);

  return (
    <Spin spinning={props.loading.effects['goods/list']}>
      <div className="result">
        <div className="main">
          <div className="screen-condition">
            <div className="screen-condition-body">
              {categoryTitle ? (
                <div className="category-title">{categoryTitle}</div>
              ) : (
                <></>
              )}
              <div className="screen">
                <Button
                  className="btn"
                  type={searchParams.bySynthetical ? 'primary' : 'default'}
                  onClick={() => goFiltersynthesis()}
                >
                  综合
                </Button>
                <Button
                  className="btn ml10 sc"
                  type={searchParams.bySynthetical ? 'default' : 'primary'}
                  onClick={() => handleChangeFilterIcon()}
                >
                  价格
                  <div className="icon-box">
                    {searchParams.bySynthetical ? (
                      <>
                        <Icon
                          className={`icon icon1`}
                          type="iconTriangle"
                        ></Icon>
                        <Icon
                          className={`icon icon2`}
                          type="iconTriangle"
                        ></Icon>
                      </>
                    ) : (
                      <>
                        <Icon
                          className={`icon icon1 ${
                            filterIconActive ? 'active' : ''
                          }`}
                          type="iconTriangle"
                        ></Icon>
                        <Icon
                          className={`icon icon2 ${
                            !filterIconActive ? 'active' : ''
                          }`}
                          type="iconTriangle"
                        ></Icon>
                      </>
                    )}
                  </div>
                </Button>
                <Input
                  prefix="￥"
                  className="c_price ml30"
                  value={startPrice}
                  onChange={(e) => {
                    const val = limitDecimals(e.target.value);
                    setStartPrice(val);
                  }}
                />
                &nbsp;-&nbsp;
                <Input
                  className="c_price"
                  prefix="￥"
                  value={endPrice}
                  onChange={(e) => {
                    const val = limitDecimals(e.target.value);
                    setEndPrice(val);
                  }}
                />
                <Button className="btn ml10" onClick={handleSearchPrice}>
                  确定
                </Button>
              </div>
            </div>
          </div>
          <div className={`list ${goodsList.length === 0 ? 'none-data' : ''}`}>
            {goodsList.length > 0 ? (
              goodsList.map((iv, index) => (
                <Item key={index} className="r-item" {...iv} />
              ))
            ) : (
              <>
                <Empty
                  image={require('@/assets/goods/2.png')}
                  imageStyle={{
                    width: '300px',
                    height: '183px',
                    display: 'inline-block',
                  }}
                  description="没有找到相关商品"
                />
              </>
            )}
          </div>
        </div>
        <div className="pagin-container">
          {goodsList.length > 0 ? (
            <Pagination
              total={searchParams.total}
              current={params.pageNo}
              showSizeChanger={false}
              showQuickJumper
              pageSize={params.pageSize}
              onChange={(page, pageSize) =>
                handlePagationChange(page, pageSize)
              }
              className="pagination"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </Spin>
  );
}

export default connect(({ loading }: { loading: Loading }) => ({ loading }))(
  Result,
);
