import React, { useState } from 'react';
import './index.less';
import { CaretDownOutlined } from '@ant-design/icons';
import { history } from 'umi';

interface itemObj {
  title: string;
  pathname: string;
  query?: object;
}
interface dataObj {
  title?: string;
  // pathname: string;
  // query?: object;
  item: itemObj[];
}
interface MSelectProps {
  label?: string;
  menus: dataObj[];
}

function MSelect(props: MSelectProps) {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => {
    setIsHover(true);
  };
  const onMouseLeave = () => {
    setIsHover(false);
  };
  const goto = (pathname: string, query?: object) => {
    history.push(pathname, query);
  };
  // onClick={() => goto(props.menus[0].pathname)}
  return (
    <div
      className={`MSelect ${isHover ? 'hover' : ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className="title">
        {props.label} <CaretDownOutlined className="icon" />
      </span>
      {isHover ? (
        <div className="content">
          {props.menus.length > 0 ? (
            props.menus.map((iv: dataObj, index: number) => (
              <div className="item" key={index}>
                <div>{iv.title}</div>
                <div className="list">
                  {iv.item.map((ls: itemObj, index: number) => (
                    <div
                      key={index}
                      className="list-l"
                      onClick={() => goto(ls.pathname, ls.query)}
                    >
                      {ls.title}
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default MSelect;
