import { Effect, request, Reducer, Subscription, Router, history } from 'umi';
import { message } from 'antd';
// state ts 的声明
export interface OrderModelState {}
// model ts 类型声明
export interface OrderModelType {
  state: OrderModelState;
  effects: {
    getList: Effect;
    getOrderDetail: Effect;
    orderConfrim: Effect;
    cancelOrder: Effect;
    delOrder: Effect;
    getLogistics: Effect;
    applyRefund: Effect;
    confirmRefund: Effect;
    orderApplyRefund: Effect;
    orderAddLogistics: Effect;
    getRefundList: Effect;
    getRefundDetail: Effect;
    cancelRefund: Effect;
    wareHousing: Effect;
    getCouponList: Effect;
  };
  reducers: {};
  // subscriptions: { setup: Subscription };
}

const Role: OrderModelType = {
  // state
  state: {},
  // 异步请求
  effects: {
    // 获取订单列表
    *getList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/list',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      return res.data;
    },
    // 获取采购/代发单详情
    *getOrderDetail({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/detail',
        {
          method: 'get',
          params: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 确认收货
    *orderConfrim({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/confirmReceipt',
        {
          method: 'get',
          params: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 取消订单
    *cancelOrder({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/cancel',
        {
          method: 'get',
          params: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 删除订单
    *delOrder({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/cancel',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 查看物流详情
    *getLogistics({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-order-center/prm/freight/${payload.id}`,
        {
          method: 'get',
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 申请退款
    *applyRefund({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/cancel',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 提交退款
    *confirmRefund({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/refund/order/apply',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 提交退款2
    *orderApplyRefund({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/refund/order/applyRefund',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 提交退款3
    *orderAddLogistics({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/refund/order/addLogistics',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 获取退款订单列表
    *getRefundList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/refund/order/list',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      }
    },
    // 获取退款详情
    *getRefundDetail({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-order-center/purchase/refund/order/detail/${payload.refundOrderNo}`,
        {
          method: 'get',
        },
        { loading: true },
      );
      if (res.data) {
        return res.data;
      } else {
        message.warning(res.errmsg);
      }
    },
    // 取消退款
    *cancelRefund({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-order-center/purchase/refund/order/cancel/${payload.refundOrderNo}`,
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res.data) {
        message.success('提交成功');
        return res.data;
      } else {
        message.warning(res.errmsg);
      }
    },
    // 一键入库
    *wareHousing({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/purchase/spu/warehousing`,
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      if (res) {
        return res;
      }
    },
    // 获取优惠券列表
    *getCouponList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/user/coupon/list',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      return res.data;
    },
  },
  // 同步修改state
  reducers: {},
  // 订阅
  // subscriptions: {
  //   setup({ dispatch, history }) {
  //     return history.listen(async ({ pathname }) => {
  //       console.log('路由地址', pathname);
  //       if (pathname === '') {
  //         dispatch({ type: 'getList', data: { pageNo: 1, pageSize: 10 } });
  //       }
  //     });
  //   },
  // },
};

export default Role;
