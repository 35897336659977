import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { connect, UserModelState, Loading, Dispatch, history } from 'umi';
import ErrorFallback from '@/components/ErrorFallback';
import { Spin, Button } from 'antd';
import MainHeader from '@/components/MainHeader';
import SearchBanner from '@/components/Home/SearchBanner';
import MainFooter from '@/components/MainFooter';
import MainQuickSider from '@/components/MainQuickSider';
import { waitTime } from '@/utils';
import './BasicLayout.less';
interface IProps {
  user: UserModelState;
  loading: Loading;
  dispatch: Dispatch;
  children: React.ElementType;
}
function BasicLayout(props: IProps) {
  const [loading, setLoading] = useState(true);
  const init = async () => {
    console.log(window.location.search);
    if (window.location.search) {
      const token = window.location.search.substring(1).split('=')[1];
      await waitTime(0);
      await props.dispatch({
        type: 'user/save',
        payload: {
          token: token,
        },
      });
      // 清除Url中的token
      window.history.replaceState(null, '', window.location.pathname);
      console.log(token);
    }
    await waitTime(0);
    console.log(1);
    await props.dispatch({
      type: 'user/userInfo',
    });
    setLoading(false);
  };
  const handleBack = () => {
    history.goBack();
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          user={props.user.user}
          token={props.user.token}
        />
      )}
    >
      <div className="basic-layout-container">
        {loading ? (
          <Spin
            spinning={loading}
            size="large"
            className="basic-layout-loading"
          />
        ) : (
          <>
            <MainHeader />
            <SearchBanner />
            {props.children}
            {/* <MainQuickSider /> */}
            <MainFooter />
          </>
        )}
      </div>
    </ErrorBoundary>
  );
}
export default connect(
  ({ user, loading }: { user: UserModelState; loading: Loading }) => ({
    user,
    loading: loading.models.user,
  }),
)(BasicLayout);
