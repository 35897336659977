import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Radio, InputNumber, message, Select } from 'antd';
import { history, connect, OrderModelState, Loading } from 'umi';
import Icon from '@/components/Icon';
import MyUpload from '@/components/Upload/Upload';
import LogisticsDebounceSelect from '@/components/LogisticsDebounceSelect';
import './index.less';
import detail from '@/pages/Notice/detail';
const { TextArea } = Input;
interface IProps {
  handleSubmit?: (formData: any) => void;
  detailData: any;
  [props: string]: any;
}
function FillAddress(props: IProps) {
  const { detailData } = props;
  // state
  const [form] = Form.useForm();
  const [initKeyWord, setInitKeyWord] = useState('');
  const [logisticsList, setLogisticsList] = useState([]);
  // 生命周期
  useEffect(() => {
    console.log('采购代发订单详情', history.location.query);
    const data = history.location.query;
    getDetail(data);
  }, []);
  // methods
  // 获取详情
  const getDetail = (data: any) => {
    // props
    //   .dispatch({
    //     type: 'userOrder/getOrderDetail',
    //     payload: { ...data },
    //   })
    //   .then((res: any) => {
    //     console.log(res);
    //     setDetailData({ ...res });
    //   });
  };
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    if (!values.remark) {
      message.warning('请输入退款原因');
      return;
    }
    props.handleSubmit && props.handleSubmit(values);
  };
  // 物流搜索
  async function fetchList({
    value,
    pageNo,
  }: {
    value: string | '';
    pageNo: number;
  }) {
    if (value === '') {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return props
      .dispatch({
        type: 'user/logisticsQuery',
        payload: {
          item: {
            name: value,
          },
          pageNo,
          pageSize: 20,
        },
      })
      .then((res: any) => {
        const table = res.data.items || [];
        const newTable = table.map((item: any) => ({
          label: item.name,
          value: item.code,
        }));
        setLogisticsList(logisticsList.concat(newTable));
        return newTable;
      });
  }
  /**
   * 搜索物流
   */
  const handleSearchChange = (value: any) => {
    const item: any = logisticsList.find((item: any) => item.value === value);
    if (!item) return;
    form.setFieldsValue({
      ...form.getFieldsValue(),
      logisticsCompany: item.label,
    });
  };
  /**
   * 清除物流
   */
  const handleSearchClear = () => {
    form.resetFields(['logisticsCompany', 'logisticsCode']);
  };

  return (
    <div className="fill-address">
      <div className="receivin-goods">
        <div className="apply-top">
          <div className="public-top">
            <Icon
              className="icon"
              style={{ fontSize: '22px', marginRight: '10px' }}
              type="icondingdanxinxi"
            />
            <span className="public-tit">快递信息</span>
          </div>
        </div>
        <div className="apply-form">
          <div className="apply-form-item">
            <div className="left-tit">
              <div className="line"></div>
              <span>收货信息</span>
            </div>
            <div className="apply-form-right">
              <div className="info-row-box">
                <p className="info-row">
                  <span className="label">收货人</span>
                  <span className="value">
                    {(detailData?.warehouseOrderDetailVOList?.length > 0 &&
                      detailData?.warehouseOrderDetailVOList[0]
                        .warehouseManager) ||
                      '-'}
                  </span>
                </p>
                <p className="info-row phone">
                  <span className="label">联系电话</span>
                  <span className="value">
                    {(detailData?.warehouseOrderDetailVOList?.length > 0 &&
                      detailData?.warehouseOrderDetailVOList[0]
                        .warehousePhone) ||
                      '-'}
                  </span>
                </p>
              </div>
              <div className="addr">
                <span className="label">收货地址</span>
                <span className="value">
                  {(detailData?.warehouseOrderDetailVOList?.length > 0 &&
                    detailData?.warehouseOrderDetailVOList[0]
                      .warehouseAddress) ||
                    '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="apply-refund-address">
        <div className="apply-top">
          <div className="public-top">
            <Icon
              className="icon"
              style={{ fontSize: '22px', marginRight: '10px' }}
              type="icondingdanxinxi"
            />
            <span className="public-tit">快递信息</span>
          </div>
        </div>
        <div className="apply-form">
          <Form
            form={form}
            initialValues={{ refundType: 1, logisticsCode: undefined }}
          >
            <div className="apply-form-item">
              <div className="apply-form-item-column">
                <div className="left-tit">
                  <div className="line"></div>
                  <span>物流公司</span>
                </div>
                <div className="apply-form-right">
                  <div className="required">*</div>
                  <Form.Item
                    name="logisticsCode"
                    rules={[{ required: false, message: '请选择物流公司' }]}
                  >
                    <LogisticsDebounceSelect
                      showSearch
                      placeholder="请选择物流公司"
                      fetchOptions={fetchList}
                      onChange={handleSearchChange}
                      onClear={handleSearchClear}
                      allowClear={true}
                      className="logistics"
                      initkeyword={initKeyWord}
                      style={{ width: '208px' }}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                label="物流公司编码"
                name="logisticsCompany"
                style={{ display: 'none' }}
              >
                <Input maxLength={50} style={{ width: '208px' }} />
              </Form.Item>
              <div className="apply-form-item-column">
                <div className="left-tit">
                  <div className="line"></div>
                  <span>物流单号</span>
                </div>
                <div className="apply-form-right">
                  <div className="required">*</div>
                  <Form.Item name="logisticsOrderNo" noStyle>
                    <Input
                      style={{ width: 208 }}
                      placeholder="请输入物流单号"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="apply-form-item-column">
                <div className="left-tit">
                  <div className="line"></div>
                  <span>电话号码</span>
                </div>
                <div className="apply-form-right">
                  <div className="required">*</div>
                  <Form.Item name="phone" noStyle>
                    <Input
                      style={{ width: 208 }}
                      placeholder="请输入电话号码"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="apply-form-item">
              <div className="left-tit">
                <div className="line"></div>
                <span>补充说明</span>
              </div>
              <div className="apply-form-right">
                <div className="required">*</div>
                <Form.Item name="remark" noStyle>
                  <TextArea
                    showCount
                    maxLength={200}
                    rows={4}
                    placeholder="请填写补充说明，最多200字"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="apply-form-item">
              <div className="left-tit">
                <div className="line"></div>
                <span>上传凭证</span>
              </div>
              <div className="apply-form-right">
                {/* <div className="required">*</div> */}
                <div className="required"> </div>
                <Form.Item name="imgList" noStyle>
                  <MyUpload
                    uploadTitle="凭证上传"
                    size={24}
                    limitNumber={10}
                    maxSize={5}
                  ></MyUpload>
                </Form.Item>
                <p className="apply-form-priceInfo">
                  可上传10张图片，只支持jpg、jpeg、png和bmp格式，单张最大5M
                </p>
              </div>
            </div>
            <div className="apply-form-item">
              <Button
                className="form-btn"
                type="primary"
                loading={props.submitLoading}
                onClick={handleSubmit}
              >
                提交
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(FillAddress);
