import { Reducer, Subscription } from 'umi';

interface IBrand {
  value: number;
  label: string;
}
export interface SearchModelState {
  selectedBrandList: IBrand[];
  category: {
    id: number | null;
    title: string;
  };
  keyWord: '';
}

export interface SearchModelType {
  state: SearchModelState;
  effects: {};
  reducers: {
    save: Reducer<SearchModelState>;
    pushSelectedBrandList: Reducer<SearchModelState>;
    sliceSelectedBrandList: Reducer<SearchModelState>;
    setSelectedBrandList: Reducer<SearchModelState>;
    // 启用 immer 之后
    // save: ImmerReducer<CommonModelState>;
  };
  subscriptions?: { setup: Subscription };
}

const SearchModel: SearchModelType = {
  state: {
    selectedBrandList: [],
    category: {
      id: null,
      title: '',
    },
    keyWord: '',
  },

  effects: {},
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clear(state: any) {
      return {
        selectedBrandList: [],
        category: {
          id: null,
          title: '',
        },
        keyWord: '',
      };
    },
    saveCategoryItem(state: any, action: any) {
      console.log('action.payload', action.payload);
      return {
        ...state,
        category: {
          ...state.category,
          ...action.payload,
        },
      };
    },
    setSelectedBrandList(state, action) {
      const { value, label } = action.payload;
      let selectedBrandList: IBrand[] = [{ value, label }];
      return {
        ...state,
        selectedBrandList,
      };
    },
    pushSelectedBrandList(state, action) {
      const { value, label } = action.payload;
      let selectedBrandList: IBrand[] = [];
      if (state) {
        selectedBrandList = [...state.selectedBrandList, { value, label }];
      }
      return {
        ...state,
        selectedBrandList,
      };
    },
    sliceSelectedBrandList(state, action) {
      const { index } = action.payload;
      let selectedBrandList: IBrand[] = state ? state.selectedBrandList : [];
      if (selectedBrandList && index > -1) {
        selectedBrandList = [
          ...selectedBrandList.slice(0, index),
          ...selectedBrandList.slice(index + 1, selectedBrandList.length),
        ];
      }
      return {
        ...state,
        selectedBrandList,
      };
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(async ({ pathname }) => {
        // if (pathname !== '/search/more') {
        //   dispatch({
        //     type: 'clear',
        //   });
        // }
      });
    },
  },
};

export default SearchModel;
