import React, { useEffect } from 'react';
import Breadcrumb from './components/Breadcrumb';
import Brand from './components/Brand';
import Filter from './components/Filter';
import MenuList from '@/components/Home/MenuList';
import HotGoodsList from '@/pages/Goods/components/HotGoodsList';
import GoodsItem from '@/components/Goods/Item';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Pagination, Spin, Empty } from 'antd';
import { connect, Loading, Dispatch, SearchModelState, history } from 'umi';
import './index.less';
import { useState } from 'react';
interface IProps {
  loading: Loading;
  dispatch: Dispatch;
  search: SearchModelState;
}
let timer: any;
function Index(props: IProps) {
  const [pagin, setPagin] = useState({
    pageNo: 1,
    pageSize: 20,
  });
  const [categoryVisible, setCategoryVisible] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [brandNameList, setBrandNameList] = useState<string[]>([]);
  const [params, setParams] = useState<any>({
    categoryId: '',
    brandIds: [],
    sort: 'DESC',
    queryStr: '',
    sortAsc: null,
    tenantId: null,
    sortName: 'SYNTHETICAL',
    minPrice: null,
    maxPrice: null,
  });
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const handleTagDelete = (id: number, type: string) => {
    if (type === 'brand') {
      const index = props.search.selectedBrandList.findIndex(
        (item) => item.value === id,
      );
      props.dispatch({
        type: 'search/sliceSelectedBrandList',
        payload: {
          index,
        },
      });
    } else if (type === 'category') {
      props.dispatch({
        type: 'search/save',
        payload: {
          category: {
            id: null,
            title: '',
          },
        },
      });
    }
  };
  const onPaginChange = (pageNo: number) => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setPagin({
      ...pagin,
      pageNo,
    });
  };
  const onFilterParamsChange = (filterParams: any) => {
    const sortNameArray = ['SYNTHETICAL', 'SALES', 'PRICE'];
    const sortArray = ['DESC', 'ASC'];
    const sort = sortArray[filterParams.sortType];
    const sortName = sortNameArray[filterParams.sort];
    const sortAsc = filterParams.sortType === 1;
    setParams({
      ...params,
      sort,
      sortAsc,
      sortName,
      minPrice: filterParams.lowPrice ? filterParams.lowPrice : null,
      maxPrice: filterParams.highPrice ? filterParams.highPrice : null,
    });
    setPagin({
      ...pagin,
      pageNo: 1,
    });
  };
  const getList = () => {
    props
      .dispatch({
        type: 'goods/list',
        payload: {
          ...pagin,
          ...params,
          sort: undefined,
          brandIds: params.brandIds.join(','),
        },
      })
      .then((data: any) => {
        setList(data.list);
        setTotal(data.size);
      });
  };
  const handleCategoryClick = (categoryItem: any) => {
    setCategoryVisible(false);
    props.dispatch({
      type: 'search/saveCategoryItem',
      payload: {
        ...categoryItem,
      },
    });
  };
  const tags = React.useMemo(() => {
    if (!Array.isArray(brandNameList)) {
      return [];
    }
    let category = {
      label: categoryName,
      id: params.categoryId,
      type: 'category',
    };
    let brands = brandNameList.map((item, index) => ({
      label: item,
      id: params.brandIds[index],
      type: 'brand',
    }));
    if (category.id) {
      return [category, ...brands];
    }
    return [...brands];
  }, [params, categoryName, brandNameList]);
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(getList, 300);
  }, [params, pagin]);
  useEffect(() => {
    setBrandNameList(props.search.selectedBrandList.map((item) => item.label));
    setParams({
      ...params,
      categoryId: props.search.category.id,
      queryStr: history.location.query?.keyWord,
      tenantId: history.location.query?.tenantId,
      brandIds: props.search.selectedBrandList.map((item) => item.value),
    });
    setPagin({
      ...pagin,
      pageNo: 1,
    });
    setCategoryName(props.search.category.title || '');
  }, [
    props.search.category,
    history.location.query,
    props.search.selectedBrandList,
  ]);
  return (
    <div className="search-more-container">
      <div className="w1200">
        <div className="category-con">
          <div className="category-btn">
            全部货品分类 <DownOutlined className="icon" />
          </div>
          <MenuList visible={true} onClick={handleCategoryClick} />
        </div>
      </div>
      <Breadcrumb onDelete={handleTagDelete} tags={tags} />
      <div className="w1200">
        <div className="section-con" style={{ marginBottom: 14 }}>
          <div className="title-con">商品品牌</div>
          <Brand params={params} />
        </div>
        <div className="bottom-con">
          <HotGoodsList title="精选推荐" len={5} />
          <div className="result-con">
            <Filter
              total={total}
              pagin={pagin}
              onParamsChange={onFilterParamsChange}
              onPaginChange={(pageNo) => setPagin({ ...pagin, pageNo })}
            />
            <Spin spinning={props.loading.effects['goods/list']}>
              {Array.isArray(list) && list.length ? (
                <div className="goods-list">
                  {list.map((item: any) => (
                    <GoodsItem
                      key={item.spuId}
                      detail={item}
                      hasInfo={true}
                      {...props.search}
                    />
                  ))}
                </div>
              ) : props.loading.effects['goods/list'] ? null : (
                <Empty description="未找到商品" style={{ marginTop: 50 }} />
              )}
            </Spin>
            {total > pagin.pageSize && (
              <div className="pagin-con">
                <Pagination
                  total={total}
                  current={pagin.pageNo}
                  showSizeChanger={false}
                  showQuickJumper
                  pageSize={pagin.pageSize}
                  onChange={onPaginChange}
                  className="pagination"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(
  ({ loading, search }: { loading: Loading; search: SearchModelState }) => ({
    loading,
    search,
  }),
)(Index);
