import React, { useState, useEffect } from 'react';
import { Empty } from 'antd';
import CouponItem from '@/components/Goods/Coupon/Drawer/item';
import './index.less';
import Toggle from './toggle';
import { connect, Loading, Dispatch } from 'umi';
export interface ISku {
  skuId: number;
  spuId: number;
  num: number;
}
interface IProps {
  dispatch: Dispatch;
  loading: Loading;
  skuList: ISku[];
  onChange?: (coupon: number) => void;
}
function Index(props: IProps) {
  const [isShowMore, setIsShowMore] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [couponList, setCouponList] = useState([]);
  const onToggleChange = React.useCallback((isShow) => {
    setIsShowMore(isShow);
  }, []);
  const handleCouponClick = React.useCallback(
    (coupon) => {
      setSelectedItem(coupon);
      props.onChange && props.onChange(coupon);
    },
    [couponList],
  );
  useEffect(() => {
    if (props.skuList.length === 0) {
      return;
    }
    props
      .dispatch({
        type: 'goods/userCouponListCanUse',
        payload: props.skuList,
      })
      .then((data: any) => {
        if (Array.isArray(data.enabledList)) {
          setCouponList(data.enabledList);
        } else {
          setCouponList([]);
        }
      });
  }, [props.skuList]);
  return (
    <div className="coupon-con">
      <div className="section-title">优惠券</div>
      {couponList.length > 0 ? (
        <div className={`coupon-list ${isShowMore && 'more'}`}>
          {couponList.map((item: any, index) => (
            <CouponItem
              key={index}
              detail={item}
              type="select"
              onClick={() => handleCouponClick(item)}
              selected={item.id === selectedItem?.id}
            />
          ))}
        </div>
      ) : (
        <Empty description="暂无可用优惠券" style={{ margin: '20px 380px' }} />
      )}
      {couponList.length > 2 && (
        <div className="handle-con">
          <Toggle onChange={onToggleChange} />
        </div>
      )}
    </div>
  );
}
export default connect(({ loading }: { loading: Loading }) => ({
  loading,
}))(Index);
