import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { message } from 'antd';
import './item.less';
import { connect, Dispatch } from 'umi';
interface IProps {
  dispatch: Dispatch;
  detail: any;
  onSuccess?: (key: number) => void;
  onClick?: (detail: any) => void;
}
function Index(props: IProps) {
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.detail);
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    props
      .dispatch({
        type: 'goods/couponReceive',
        payload: [props.detail.id],
      })
      .then((res) => {
        message.success('领取成功');
        props.onSuccess && props.onSuccess(props.detail.key);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const date = useMemo(() => {
    if (!props.detail.startTime || !props.detail.endTime) {
      return;
    }
    const start = moment(props.detail.startTime).format('YYYY-MM-DD');
    const end = moment(props.detail.endTime).format('YYYY-MM-DD');
    return `${start}-${end}`;
  }, [props.detail]);
  return (
    <div className="goods-coupon-item-component">
      <div className="left-con">
        <div className="price-con">
          <div className="price-unit">¥</div>
          <div className="price">{props.detail.discount}</div>
        </div>
        <div className="min-con">
          {props.detail.min === 0 ? '无门槛' : `满${props.detail.min}可用`}
        </div>
      </div>
      <div className="middle-con">
        <div className="title-con">{props.detail.title}</div>
        <div className="date-con">{date}</div>
        <div className="shop-name">{props.detail.branchOfficeName}</div>
      </div>
      <div className="right-con" onClick={handleClick}>
        立即领取
      </div>
    </div>
  );
}
export default connect()(Index);
