import React, { useState } from 'react';
import { Input } from 'antd';
import { useDispatch, history } from 'umi';
import GoodsRow, { GoodItem } from './GoodsRow';
import './Item.less';
interface IProps {
  /**
   * 供应商名字
   */
  tenantName?: string;
  tenantId?: number;
  frequentListVOList: any[];
  [props: string]: any;
}
function ProductItem(props: IProps) {
  const dispatch = useDispatch();
  const {
    tenantName,
    tenantId,
    frequentListVOList = [],
    selectedRowKeys = [],
    setSelectedRowKeys,
  } = props;

  const handleNumberStep = (
    val: any,
    item: GoodItem,
    parentIndex: number,
    currentIndex: number,
  ) => {
    props.handleNumberStep &&
      props.handleNumberStep(val, item, parentIndex, currentIndex);
  };
  const handleGoto = (pathname: string, query?: any) => {
    history.push({
      pathname,
      query,
    });
  };
  return (
    <div className="product-item">
      <div className="p-i_header">
        <div className="h-info">
          <span
            className="company-title"
            onClick={() => handleGoto(`/shop/${tenantId}/category`)}
          >
            {tenantName || ''}
          </span>
        </div>
      </div>
      <div className="p-i_goods">
        {frequentListVOList &&
          frequentListVOList.map((sku: any, index: number) => (
            <GoodsRow
              handleNumberStep={handleNumberStep}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              {...sku}
              key={index}
              currentIndex={index}
              parentIndex={props.parentIndex}
            />
          ))}
      </div>
    </div>
  );
}

export default ProductItem;
