import React, { useEffect } from 'react';
import { Table, Spin, Button, Checkbox, Modal, message, Empty } from 'antd';
import './Footer.less';

interface IProps {
  checked: boolean;
  onChange?: (bool: boolean) => void;
  handleSubmit?: () => void;
  handleDeleteMult?: () => void;
  totalPrice?: number;
  selectedRowKeys?: any[];
}
function Footer(props: IProps) {
  const { selectedRowKeys = [], totalPrice = 0 } = props;
  const handleSubmit = () => {
    props.handleSubmit && props.handleSubmit();
  };
  return (
    <div className="shopping-footer">
      <div className="col">
        <Checkbox
          checked={props.checked}
          onChange={(e: any) =>
            props.onChange && props.onChange(e.target.checked)
          }
        >
          全选
        </Checkbox>
      </div>
      <div
        className="col tleft"
        style={{ cursor: 'pointer' }}
        onClick={props.handleDeleteMult}
      >
        删除
      </div>
      <div className="col tleft">
        已选商品
        <span className="goods-num">{selectedRowKeys.length}</span>件
      </div>
      <div className="col trow total-price">
        <div className="total">
          总计: <span className="price">¥{totalPrice}</span>
        </div>
        <div className="tip">不含运费和其他优惠</div>
      </div>
      <div className="col settlement-btn">
        <Button
          type="primary"
          className="settlement"
          disabled={selectedRowKeys.length === 0}
          onClick={handleSubmit}
        >
          结算
        </Button>
      </div>
    </div>
  );
}

export default Footer;
