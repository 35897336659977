/*
 * 与服务端通信的金额需要以分为单位，此方法可以应用在请求接口
 * 和响应接口时，处理请求数据和响应数据内的指定字段（dealReg）
 * 如果type为in就代表处理响应数据，反之处理请求数据中的金额
 **/
import { fixAmount } from './index';

const dealReg =
  /price|amount|Price|^freight$|Amount|^min$|^discount$|commission|totalIncome|totalExpenses|Balance|Money|money|grossIncome|goodsGrossProfit|totalStoredValue|businessDiscount|everyPrice|thresholdPrice|issuingPrice|acSubPrice/;
const blockList = ['rechargeAmountTwoDayContrast'];
export const dealAmount = (obj, type = 'in') => {
  obj = JSON.parse(JSON.stringify(obj));
  if (typeof obj === 'object') {
    for (const key in obj) {
      const element = obj[key];
      const hasKey = dealReg.test(key) && blockList.indexOf(key) === -1;
      if (hasKey && element !== null) {
        // 请求接口的数据需要除以100，转为元展示
        obj[key] =
          type === 'in'
            ? fixAmount(Number(element) / 100)
            : fixAmount(Number(element) * 100);
      } else if (
        typeof element === 'object' ||
        Array.isArray(element) ||
        /^\{.+\}$/.test(element)
      ) {
        obj[key] = dealAmount(element, type);
      }
    }
  } else if (Array.isArray(obj)) {
    obj.forEach((item) => {
      if (typeof item === 'object' || Array.isArray(item)) {
        item = dealAmount(item, type);
      }
    });
  } else if (typeof obj === 'string') {
    try {
      obj = JSON.stringify(dealAmount(JSON.parse(obj), type));
    } catch (error) {}
  }
  return obj;
};
