import React, { useState, useEffect } from 'react';
import { Tabs, Spin, Empty } from 'antd';
import Thead from './Thead';
import ProductItem from './ProductItem';
import Footer from './Footer';
const { TabPane } = Tabs;

import './index.less';

interface IProps {
  /**
   * 是否是查看操作
   */
  showDataFlag?: boolean;
  /**
   * 提交
   */
  handleSubmit?: (selectedRowKeys: number[], warehouseId?: number) => void;
  /**
   * tab 切换
   */
  handleOnTabChange?: (tabStr: number | string) => void;
  listKey?: string;
  loading?: boolean;
  isview?: boolean;
  list: any[];
}
function ChooseRefund(props: IProps) {
  const {
    showDataFlag = false,
    list = [],
    listKey = 'purchaseOrderSkuDetailVOList',
  } = props;
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [goodsList, setGoodsList] = useState([]);
  const handleOnTabChange = (key: any) => {
    setSelectedRowKeys([]);
    setIsAllChecked(false);
    props.handleOnTabChange && props.handleOnTabChange(key);
  };
  // 是否全选
  const handleOnAllChecked = (bool: boolean, list?: any) => {
    if (bool) {
      const arrIds: any = [];
      list.map((skuGood: any) => {
        arrIds.push(skuGood.orderSkuId);
      });
      setSelectedRowKeys([...selectedRowKeys, ...arrIds]);
    } else {
      const tempIds = list.map((item: any) => item.orderSkuId);
      const newTempSelectedRowKeys = selectedRowKeys.filter(
        (id: any) => !tempIds.includes(id),
      );
      setSelectedRowKeys(newTempSelectedRowKeys);
    }
  };
  const handleSubmit = (depot: any) => {
    const tempSkuDetailVOList = depot.purchaseOrderSkuDetailVOList;
    const tempObjList = tempSkuDetailVOList.filter((item: any) =>
      selectedRowKeys.includes(item.orderSkuId),
    );
    const tempIds: any = [];
    tempObjList.map((item: any) => {
      tempIds.push(item.orderSkuId);
      if (item?.gift) {
        tempIds.push(item.gift.orderSkuId);
      }
    });
    props.handleSubmit && props.handleSubmit(tempIds, depot?.depotId);
  };
  const handleEncryptionFourData = (str: string) => {
    if (!str) return;
    const len = str?.length;
    return `${str.substring(0, 1)}****${str.substring(len - 1)}`;
  };
  const handleIsAllChecked = (list: any) => {
    const checkedList = list.filter((item: any) =>
      selectedRowKeys.includes(item.orderSkuId),
    );
    return list?.length === checkedList?.length;
  };
  // 去重赠品
  const arrDuplicateRemoval = (array: any[]) => {
    let newArr = [];
    for (let i = 0; i < array.length; i++) {
      //开闭原则
      let bool = true;
      //每次都要判断新数组中是否有旧数组中的值。
      for (let j = 0; j < newArr.length; j++) {
        if (array[i]?.skuId === newArr[j]?.skuId) {
          bool = false;
          if (array[i]?.isPresent === 1) {
            newArr[j].gift = array[i];
          }
        }
      }
      if (bool) {
        newArr[newArr.length] = array[i];
      }
    }
    return newArr;
  };

  useEffect(() => {
    let newList = props.list.map((item: any) => {
      const purchaseOrderSkuDetailVOList =
        item?.purchaseOrderSkuDetailVOList || [];
      const tempPuVoList = arrDuplicateRemoval(purchaseOrderSkuDetailVOList);
      return {
        ...item,
        purchaseOrderSkuDetailVOList: [...tempPuVoList],
      };
    });
    setGoodsList(newList as []);
  }, [props.list]);
  return (
    <Spin spinning={props.loading}>
      <div className="choose-refund-container">
        {props.isview ? (
          goodsList?.length > 0 ? (
            goodsList.map((depot: any, depotIndex: number) => (
              <div className="store-row" key={depotIndex}>
                <Thead checked={isAllChecked} showDataFlag={showDataFlag} />

                {depot[listKey].map((sku: any, skuIndex: number) => (
                  <ProductItem
                    {...sku}
                    key={skuIndex}
                    setSelectedRowKeys={setSelectedRowKeys}
                    selectedRowKeys={selectedRowKeys}
                    showDataFlag={showDataFlag}
                  />
                ))}
                {!showDataFlag ? (
                  <Footer
                    checked={isAllChecked}
                    handleSubmit={() => handleSubmit(depot)}
                    selectedRowKeys={selectedRowKeys}
                    list={depot[listKey]}
                  />
                ) : (
                  <></>
                )}
              </div>
            ))
          ) : (
            <Empty
              style={{ marginTop: '70px' }}
              description="暂无商品信息"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
        ) : (
          <Tabs
            onChange={handleOnTabChange}
            type="card"
            className="refund-tab-card"
            tabBarExtraContent={{
              right:
                props.list.length > 1 ? (
                  <div className="tip">
                    *您订单中的货品在不同的库房发货，故需分开进行退款/退货退款申请，给您带来的不便敬请谅解。
                  </div>
                ) : (
                  <></>
                ),
            }}
          >
            <TabPane tab="仅退款" key="0">
              {goodsList.length > 0 ? (
                goodsList.map((depot: any, depotIndex: number) => (
                  <div className="store-row" key={depotIndex}>
                    <Thead
                      checked={handleIsAllChecked(
                        depot.purchaseOrderSkuDetailVOList,
                      )}
                      onChange={(bool) =>
                        handleOnAllChecked(
                          bool,
                          depot.purchaseOrderSkuDetailVOList,
                        )
                      }
                    />
                    {depot.purchaseOrderSkuDetailVOList.map(
                      (sku: any, skuIndex: number) => (
                        <ProductItem
                          {...sku}
                          key={skuIndex}
                          setSelectedRowKeys={setSelectedRowKeys}
                          selectedRowKeys={selectedRowKeys}
                        />
                      ),
                    )}
                    <Footer
                      checked={handleIsAllChecked(
                        depot.purchaseOrderSkuDetailVOList,
                      )}
                      handleSubmit={() => handleSubmit(depot)}
                      onChange={(bool) =>
                        handleOnAllChecked(
                          bool,
                          depot.purchaseOrderSkuDetailVOList,
                        )
                      }
                      selectedRowKeys={selectedRowKeys}
                      list={depot[listKey]}
                    />
                  </div>
                ))
              ) : (
                <Empty
                  style={{ marginTop: '70px' }}
                  description="暂无商品信息"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </TabPane>
            <TabPane tab="退货退款" key="1">
              {goodsList.length > 0 ? (
                goodsList.map((depot: any, depotIndex: number) => (
                  <div className="store-row" key={depotIndex}>
                    {/* <div className="store-title">
                      {depot.depotName &&
                        handleEncryptionFourData(depot.depotName)}
                    </div> */}
                    <Thead
                      checked={handleIsAllChecked(
                        depot.purchaseOrderSkuDetailVOList,
                      )}
                      onChange={(bool) =>
                        handleOnAllChecked(
                          bool,
                          depot.purchaseOrderSkuDetailVOList,
                        )
                      }
                    />
                    {depot.purchaseOrderSkuDetailVOList.map(
                      (sku: any, skuIndex: number) => (
                        <ProductItem
                          {...sku}
                          key={skuIndex}
                          setSelectedRowKeys={setSelectedRowKeys}
                          selectedRowKeys={selectedRowKeys}
                        />
                      ),
                    )}
                    <Footer
                      checked={handleIsAllChecked(
                        depot.purchaseOrderSkuDetailVOList,
                      )}
                      handleSubmit={() => handleSubmit(depot)}
                      onChange={(bool) =>
                        handleOnAllChecked(
                          bool,
                          depot.purchaseOrderSkuDetailVOList,
                        )
                      }
                      selectedRowKeys={selectedRowKeys}
                      list={depot[listKey]}
                    />
                  </div>
                ))
              ) : (
                <Empty
                  style={{ marginTop: '70px' }}
                  description="暂无商品信息"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </TabPane>
          </Tabs>
        )}
      </div>
    </Spin>
  );
}

export default ChooseRefund;
