import React, { useEffect, useMemo, useState } from 'react';
import { connect, OrderModelState, Loading, Dispatch, history } from 'umi';
import { Spin, Tooltip, message, Modal, Button, Empty } from 'antd';
import Thead from './components/Shopping/Thead';
import ProductItem from './components/Shopping/ProductItem';
import Footer from './components/Shopping/Footer';
import { QuestionCircleOutlined } from '@ant-design/icons';
import tishiIcon from '@/assets/common/tishi.svg';
import { fixAmount } from '@/utils';
import './Shopping.less';
const { confirm } = Modal;
interface IProps {
  order: OrderModelState;
  loading: Loading;
  dispatch: Dispatch;
}
function OrderShopping(props: IProps) {
  const { purchaseList = [], ineffectivePurchaseListVOList = [] } = props.order;
  const [isCheckboxAll, setIsCheckboxAll] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [flatPurchaseList, setFlatPurchaseList] = useState<any[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const handleAllCheckboxChange = (bool: boolean) => {
    if (bool && purchaseList) {
      const arrIds: any = [];
      purchaseList.map((store: any) => {
        store.purchaseListVOList.map((skuGood: any) => {
          (skuGood.status === 0 ||
            (skuGood.status === 1 && skuGood.stock > 0)) &&
            arrIds.push(skuGood.skuId);
        });
      });
      setSelectedRowKeys(arrIds);
    } else {
      setSelectedRowKeys([]);
    }
    setIsCheckboxAll(bool);
  };
  const failurePurchaseListLen = React.useMemo(() => {
    return (
      ineffectivePurchaseListVOList &&
      ineffectivePurchaseListVOList.reduce((prev, store: any) => {
        const length = store.purchaseListVOList.filter(
          (skuGood: any) => skuGood.status > 1,
        ).length;
        return prev + length;
      }, 0)
    );
  }, [ineffectivePurchaseListVOList]);

  const purchaseListLen = React.useMemo(() => {
    return (
      purchaseList &&
      purchaseList.reduce((prev, store: any) => {
        const length = store.purchaseListVOList.length;
        return prev + length;
      }, 0)
    );
  }, [purchaseList]);

  // 提交
  const handleSubmit = () => {
    const selectedRowKeysObj = purchaseList
      .map((store: any) => {
        const arr =
          store.purchaseListVOList.filter(
            (skuGood: any) =>
              selectedRowKeys.includes(skuGood.skuId) &&
              (skuGood.status === 0 ||
                (skuGood.status === 1 && skuGood.stock > 0)),
          ) || [];
        return arr.map((skuGood: any) => skuGood);
      })
      ?.flat(2);
    console.log('selectedRowKeysObj', selectedRowKeysObj);

    const purchaseObjList = selectedRowKeysObj.map((item: any) => ({
      skuId: item.skuId,
      skuNum: item.num,
      num: item.num,
      tenantId: item.tenantId,
      spuId: item.spuId,
      presentNum: item?.gift?.quantity || 0,
      totalNum: item.num + (item?.gift?.quantity || 0),
    }));
    console.log('purchaseObjList', purchaseObjList);

    props
      .dispatch({
        type: 'order/checkStock',
        payload: purchaseObjList,
      })
      .then(() => {
        const purchaseListId = selectedRowKeysObj.map(
          (item) => item.purchaseListId,
        );
        const orderRequestSkuROList = selectedRowKeysObj.map((item: any) => ({
          skuId: item.skuId,
          num: item.num,
          tenantId: item.tenantId,
          spuId: item.spuId,
        }));
        const params = {
          purchaseListId,
          orderRequestSkuROList,
          shippingChannel: 1,
        };

        history.push(
          '/order/confirm?goType=cars&params=' +
            encodeURIComponent(JSON.stringify(params)),
        );
      });
  };

  // 删除
  const handleGoodItemDelete = (purchaseListId: number) => {
    confirm({
      title: '确认要删除该货品吗',
      content: '删除货品将无法恢复，需重新加入进货单',
      className: 'custom-2',
      icon: <img className="icon" src={tishiIcon} />,
      onOk() {
        return new Promise((resovle) => {
          props
            .dispatch({
              type: 'order/deletePurchase',
              payload: [purchaseListId],
            })
            .then((res: any) => {
              const index = selectedRowKeys.findIndex(
                (item) => purchaseListId === item,
              );
              setSelectedRowKeys([
                ...selectedRowKeys.slice(0, index),
                ...selectedRowKeys.slice(index + 1, selectedRowKeys.length),
              ]);
              resovle(res);
            });
        });
      },
    });
  };

  const handleDeleteMult = () => {
    if (!selectedRowKeys.length) {
      message.error('请选择要删除的货品');
      return;
    }
    const list = flatPurchaseList.filter((item: any) =>
      selectedRowKeys.includes(item.skuId),
    );
    const purchaseListIds = list.map((item: any) => item.purchaseListId);
    confirm({
      title: '确认要删除选中的货品吗',
      content: '删除货品将无法恢复，需重新加入进货单',
      className: 'custom-2',
      icon: <img className="icon" src={tishiIcon} />,
      onOk() {
        return new Promise((resolve) => {
          props
            .dispatch({
              type: 'order/deletePurchase',
              payload: purchaseListIds,
            })
            .then((res: any) => {
              setSelectedRowKeys([]);
              setIsCheckboxAll(false);
              resolve(res);
            });
        });
      },
    });
  };
  const handleDeleteMultInvalid = () => {
    console.log(ineffectivePurchaseListVOList);
    const list = ineffectivePurchaseListVOList
      .map((item: any) => [...item.purchaseListVOList])
      ?.flat(2);
    const purchaseListIds = list.map((item: any) => item.purchaseListId);
    confirm({
      title: '是否确认删除',
      className: 'custom-2',
      icon: <img className="icon" src={tishiIcon} />,
      onOk() {
        return new Promise((resolve) => {
          props
            .dispatch({
              type: 'order/deletePurchase',
              payload: purchaseListIds,
            })
            .then((res: any) => {
              setSelectedRowKeys([]);
              setIsCheckboxAll(false);
              resolve(res);
            });
        });
      },
    });
  };

  const handleClickTitle = (spuId: any) => {
    history.push({
      pathname: `/goods/detail/${spuId}`,
    });
  };

  useEffect(() => {
    // 计算总价
    const count =
      flatPurchaseList.reduce((prev: number, item: any) => {
        return (item.status === 0 || (item.status === 1 && item.stock > 0)) &&
          selectedRowKeys.includes(item.skuId)
          ? prev + item.num * item.price
          : prev;
      }, 0) || 0;
    console.log(selectedRowKeys);
    setTotalPrice(fixAmount(count));
    // 是否全选
    if (flatPurchaseList.length === 0) return false;
    const newArr = flatPurchaseList.filter(
      (item: any) =>
        (item.status === 0 || (item.status === 1 && item.stock > 0)) && item,
    );
    const flag =
      newArr
        .map((item: any) => item.skuId)
        .sort()
        .toString() === selectedRowKeys.sort().toString();
    setIsCheckboxAll(flag);
  }, [selectedRowKeys, flatPurchaseList]);

  // 数据扁平化处理
  useEffect(() => {
    const tempPurchaseListVOList = purchaseList.map(
      (item: any) => item.purchaseListVOList,
    );
    const tempArr = tempPurchaseListVOList && tempPurchaseListVOList.flat(2);
    setFlatPurchaseList(tempArr);
  }, [purchaseList]);

  return (
    <div className="order-shopping-container">
      <div className="section">
        <div className="__title-con">
          <h1>全部货品</h1>
          <span>{purchaseListLen}</span>
        </div>
        <Spin spinning={props.loading.effects['order/purchaseList']}>
          <div className="shopping-table-container">
            <Thead checked={isCheckboxAll} onChange={handleAllCheckboxChange} />
            {purchaseList &&
              purchaseList.map((purchase: any, index: number) => (
                <ProductItem
                  {...purchase}
                  key={index + 'pur'}
                  ckey={index + 'pur'}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  isShowCoupon={true}
                  isShoppingCoupon={true}
                  handleGoodItemDelete={handleGoodItemDelete}
                  handleClickTitle={handleClickTitle}
                  purchaseList={purchaseList}
                  goodType="shopping"
                />
              ))}
            {/* 失效 */}
            {failurePurchaseListLen > 0 ? (
              <div className="invalid">
                <div className="__title-con fixed">
                  <div>
                    <h1>失效货品</h1>
                    <Tooltip title="加入进货单商品下架或被删除时，将会显示在失效货品处">
                      <QuestionCircleOutlined />
                    </Tooltip>
                    <span>{failurePurchaseListLen}</span>
                  </div>
                  <Button
                    type="link"
                    className="del"
                    onClick={handleDeleteMultInvalid}
                  >
                    删除所有失效货品
                  </Button>
                </div>
                {ineffectivePurchaseListVOList &&
                  ineffectivePurchaseListVOList.map(
                    (purchase: any, index: number) => (
                      <ProductItem
                        {...purchase}
                        key={index + '-ineff'}
                        ckey={index + '-ineff'}
                        isShowTitleSubtotal={false}
                        isShowCoupon={true}
                        handleGoodItemDelete={handleGoodItemDelete}
                        handleClickTitle={handleClickTitle}
                        goodType="shopping"
                      />
                    ),
                  )}
              </div>
            ) : (
              <></>
            )}
            {purchaseList &&
            purchaseList.length === 0 &&
            failurePurchaseListLen === 0 ? (
              <Empty
                className="custom"
                imageStyle={{ marginTop: 100, height: 195 }}
                style={{ marginBottom: 121 }}
                image={require('@/assets/common/empty-cart.png')}
                description="进货单空空如也，快去挑好货吧～"
              />
            ) : (
              <></>
            )}
            <Footer
              totalPrice={totalPrice}
              checked={isCheckboxAll}
              selectedRowKeys={selectedRowKeys}
              onChange={handleAllCheckboxChange}
              handleSubmit={handleSubmit}
              handleDeleteMult={handleDeleteMult}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
}
export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(OrderShopping);
