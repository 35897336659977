import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { connect, Loading, Dispatch } from 'umi';
import CouponItem from './item';
import './index.less';
const Icon = {
  yhq: require('@/assets/home/coupon/yhq.gif'),
  tcBt: require('@/assets/home/coupon/tc_icon_bt.png'),
};
interface IProps {
  loading: Loading;
  dispatch: Dispatch;
}
let _id = 0;
function Index(props: IProps) {
  const [visible, setVisible] = useState(false);
  const [isShowGif, setIsShowGif] = useState(false);
  const [couponList, setCouponList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const onCouponReceiveSuccess = (key: number) => {
    const temp = couponList.filter((item) => item.key !== key);
    setCouponList(temp);
  };
  const handleCouponAllClick = () => {
    const ids = couponList.map((item: any) => item.id);
    if (loading || isDisabled) {
      return;
    }
    setLoading(true);
    props
      .dispatch({
        type: 'goods/couponReceive',
        payload: ids,
      })
      .then((data: any) => {
        message.success('领取成功');
        setVisible(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCouponList = () => {
    props
      .dispatch({
        type: 'goods/couponListCanReceive',
        payload: {
          pageNo: 1,
          pageSize: 999,
        },
      })
      .then((data: any) => {
        if (Array.isArray(data.items) && data.items.length) {
          setCouponList(
            data.items.map((item: any) => ({
              key: _id++,
              ...item,
            })),
          );
          setIsShowGif(true);
        } else {
          setCouponList([]);
          setIsShowGif(false);
        }
      });
  };
  const isDisabled = React.useMemo(
    () => !couponList.some((item) => item.status < 2),
    [couponList],
  );
  useEffect(() => {
    fetchCouponList();
  }, [visible]);
  return (
    <div className="home-coupon-container">
      {!visible && isShowGif && (
        <img
          src={Icon.yhq}
          alt=""
          className="coupon-icon"
          onClick={() => setVisible(true)}
        />
      )}
      <Modal
        visible={visible}
        width={565}
        closable={false}
        className="home-coupon-modal-container"
        footer={null}
      >
        <CloseCircleOutlined
          className="icon-close"
          onClick={() => setVisible(false)}
        />
        <div className="title-con">
          <img src={Icon.tcBt} alt="" />
          <h1>你的专属幸运券</h1>
          <img src={Icon.tcBt} alt="" />
        </div>
        <div className="coupon-list">
          {couponList.map((item: any) => (
            <CouponItem
              key={item.key}
              detail={item}
              onSuccess={onCouponReceiveSuccess}
            />
          ))}
          <p className="no-data">没有更多了</p>
        </div>
        <div className="handle-con">
          <div
            className={`btn ${isDisabled ? 'disabled' : ''}`}
            onClick={handleCouponAllClick}
          >
            {isDisabled ? '已领取' : '领取全部'}
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default connect(({ loading }: { loading: Loading }) => ({ loading }))(
  Index,
);
