import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb } from 'antd';
import { Link, history } from 'umi';
import routes from '@/routes';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import './index.less';
export default withBreadcrumbs(routes)(({ breadcrumbs }) => {
  const [from, setFrom] = useState('');
  const handleBack = () => {
    history.goBack();
  };
  const memoBreadcrumbs = useMemo(() => {
    let res = breadcrumbs.filter((breadcrumb: any) => breadcrumb.title);
    if (from !== '') {
      res.splice(res.length - 1, 0, { key: 'back', title: from });
    }
    return res;
  }, [breadcrumbs]);
  useEffect(() => {
    setFrom('');
    if (history.location.query && history.location.query.from) {
      setFrom(history.location.query.from.toString());
    }
  }, [history.location.query]);
  return (
    <div className="breadcrumb-container">
      <div className="breadcrumb-content">
        <span className="label">您的位置：</span>
        <Breadcrumb separator=">">
          {memoBreadcrumbs.map((breadcrumb: any, index) => (
            <Breadcrumb.Item key={breadcrumb.key}>
              {index === memoBreadcrumbs.length - 1 ? (
                breadcrumb.title
              ) : breadcrumb.key === 'back' ? (
                <a
                  className={index === 1 ? 'home' : ''}
                  href="javascript:;"
                  onClick={handleBack}
                >
                  {breadcrumb.title}
                </a>
              ) : (
                <Link
                  to={breadcrumb.redirectPath || breadcrumb.key}
                  className={index === 0 || index === 1 ? 'home' : ''}
                >
                  {breadcrumb.title}
                </Link>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </div>
  );
});
