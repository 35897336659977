import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Radio, InputNumber, message, Steps } from 'antd';
import { history, connect, OrderModelState, Loading } from 'umi';
import Breadcrumb from '@/components/Breadcrumb';
import ChooseRefund from './components/ApplyRefund/ChooseRefund';
import FillRefund from './components/ApplyRefund/FillRefund';
import FillAddress from './components/ApplyRefund/FillAddress';
import ResultRefund from './components/ApplyRefund/ResultRefund';
import './ApplyRefund.less';

// 未发货
const stepsWaitDelivery = [
  {
    title: '选择退款/退货退款货品',
  },
  {
    title: '填写退款/退货退款原因、金额',
  },
  {
    title: '商家退款/退货退款处理',
  },
];
// 已发货
const stepsDeliveryed = [
  {
    title: '选择退款/退货退款货品',
  },
  {
    title: '填写退款/退货退款原因、金额',
  },
  {
    title: '填写寄件信息',
  },
  {
    title: '商家退款/退货退款处理',
  },
];

function ApplyRefund(props: any) {
  // state
  const [detailData, setDetailData] = useState<any>({});
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [tabVal, setTabVal] = useState<number | string>('0');
  const [orderSkuIdList, setOrderSkuIdList] = useState<number[]>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [warehouseId, setWarehouseId] = useState<number | undefined>(undefined);
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();

  // 生命周期
  useEffect(() => {
    console.log('采购代发订单详情', history.location.query);
    const data = history.location.query;
    getDetail({ ...data, refundType: tabVal });
  }, []);
  // methods
  // 获取详情
  const getDetail = (data: any) => {
    props
      .dispatch({
        type: 'userOrder/getOrderDetail',
        payload: { ...data },
      })
      .then((res: any) => {
        console.log(res);
        setDetailData({ ...res });
      });
  };
  // 选择退款商品
  const handleOnTabChange = (tabVal: number | string) => {
    console.log(tabVal);
    setTabVal(tabVal);
    getDetail({ ...history.location.query, refundType: tabVal });
  };
  // 提交 选择退款商品
  const handleChooseRefunSubmit = (
    orderSkuIds: number[],
    warehouseId?: number | undefined,
  ) => {
    setCurrentStepIndex(1);
    setOrderSkuIdList(orderSkuIds);
    setWarehouseId(warehouseId);
    getDetail({ ...history.location.query, refundType: tabVal, orderSkuIds });
  };
  // 提交 填写退款信息
  const handleFillRefundSubmit = (values: any) => {
    console.log(values);
    // if (!values.imgList || values.imgList.length === 0) {
    //   message.warning('请上传退款凭证');
    //   return;
    // }
    setFormData({});
    if (tabVal === '0') {
      props
        .dispatch({
          type: 'userOrder/orderApplyRefund',
          payload: {
            ...values,
            refundType: tabVal * 1,
            orderNo: detailData.orderNo,
            orderSkuIdList,
            warehouseId,
          },
        })
        .then(() => {
          setCurrentStepIndex(2);
        });
    } else {
      setFormData(values);
      setCurrentStepIndex(2);
    }
  };
  // 提交 填写快递信息
  const handleFillAddressSubmit = (values: any) => {
    // if (!values.imgList || values.imgList.length === 0) {
    //   message.warning('请上传退款凭证');
    //   return;
    // }
    if (tabVal === '1') {
      // 退款
      // 退货退款
      props
        .dispatch({
          type: 'userOrder/orderApplyRefund',
          payload: {
            ...formData,
            refundType: tabVal * 1,
            orderNo: detailData.orderNo,
            orderSkuIdList,
            warehouseId,
            refundOrderLogisticsRO: {
              ...values,
            },
          },
        })
        .then(() => {
          setCurrentStepIndex(3);
        });
    }
  };
  const newData = React.useCallback(() => {
    return tabVal === '0' ? stepsWaitDelivery : stepsDeliveryed;
  }, [tabVal]);
  return (
    <>
      <Breadcrumb />
      <div className="apply-refund-progress-container">
        <Steps current={currentStepIndex}>
          {newData().map((item: any) => (
            <Steps.Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <div className="app-refund-content">
        {currentStepIndex === 0 ? (
          <ChooseRefund
            handleSubmit={handleChooseRefunSubmit}
            handleOnTabChange={handleOnTabChange}
            list={detailData.purchaseOrderSkuDetailGroupByDepotVOSList || []}
            loading={props.loading.effects['userOrder/getOrderDetail']}
          />
        ) : currentStepIndex === 1 ? (
          <FillRefund
            handleSubmit={handleFillRefundSubmit}
            detailData={detailData}
            orderSkuIds={orderSkuIdList}
            refundType={tabVal}
            warehouseId={warehouseId}
            submitLoading={
              (tabVal === '0' &&
                props.loading.effects['userOrder/orderApplyRefund']) ||
              false
            }
          />
        ) : currentStepIndex === 2 ? (
          tabVal === '1' ? (
            <FillAddress
              handleSubmit={handleFillAddressSubmit}
              detailData={detailData}
              submitLoading={
                (tabVal === '1' &&
                  props.loading.effects['userOrder/orderApplyRefund']) ||
                false
              }
            />
          ) : (
            <ResultRefund />
          )
        ) : currentStepIndex === 3 ? (
          <ResultRefund />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(ApplyRefund);
