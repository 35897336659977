import React from 'react';
import { Table, Spin, Button, Checkbox, Modal, message, Empty } from 'antd';
import './Thead.less';
interface IProps {
  checked?: boolean;
  onChange?: (bool: boolean) => void;
  showDataFlag?: boolean;
  columns?: any[];
}
function Thead(props: IProps) {
  const {
    showDataFlag,
    columns = ['货品信息', '单价（元）', '数量', '小计（元）', '操作'],
  } = props;
  return (
    <div className="shopping-thead">
      {!showDataFlag ? (
        <div className="col">
          <Checkbox
            checked={props.checked}
            onChange={(e: any) =>
              props.onChange && props.onChange(e.target.checked)
            }
          >
            全选
          </Checkbox>
        </div>
      ) : (
        <></>
      )}
      {columns &&
        columns.map((column: any, index: number) => (
          <div className={`col ${index === 0 ? 'tleft' : ''}`} key={index}>
            {column}
          </div>
        ))}
    </div>
  );
}

export default Thead;
