import React from 'react';
import { Modal, Form, Input, Radio } from 'antd';
import NumericInput from '@/components/NumericInput';
import { GoodsModelState, Dispatch, connect, Loading } from 'umi';
import CategoryCascader from '@/components/Goods/CategoryCascader';
interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  goods: GoodsModelState;
  loading: Loading;
  spuId: number;
  dispatch: Dispatch;
}
function GoodsDistributionModal(props: IProps) {
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.validateFields().then((validate) => {
      if (validate) {
        const formData = form.getFieldsValue();
        formData.categoryId =
          formData.categoryIds[formData.categoryIds.length - 1];
        formData.parentCategoryId =
          formData.categoryIds[formData.categoryIds.length - 2];
        formData.categoryIds = undefined;
        props
          .dispatch({
            type: 'goods/distribution',
            payload: {
              ...formData,
              spuId: props.spuId,
            },
          })
          .then(() => {
            props.onSuccess();
          });
      }
    });
  };
  return (
    <Modal
      wrapClassName="goods-distribution-modal custom"
      title="一键铺货"
      visible={props.visible}
      width="640px"
      onCancel={props.onCancel}
      onOk={handleSubmit}
      confirmLoading={props.loading.effects['goods/distribution']}
    >
      <Form
        form={form}
        wrapperCol={{ span: 10 }}
        labelCol={{ span: 8 }}
        initialValues={{ upStatus: 1 }}
      >
        <Form.Item
          label="铺货门店商品类目"
          name="categoryIds"
          rules={[
            {
              required: true,
              message: '请选择商品类目',
            },
          ]}
        >
          <CategoryCascader
            expandTrigger="hover"
            placeholder="请选择商品类目"
          />
        </Form.Item>
        <Form.Item label="一键上架到小程序" name="upStatus">
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default connect(
  ({ goods, loading }: { goods: GoodsModelState; loading: Loading }) => ({
    goods,
    loading,
  }),
)(GoodsDistributionModal);
