import './index.less';
import { useState, useEffect } from 'react';
import { Button, Input, Form, message, Checkbox } from 'antd';
import { Loading, UserModelState, connect, useHistory } from 'umi';
import CryptoJS from 'crypto-js';

const handleEncrypt = (text: string) => {
  const key = CryptoJS.enc.Utf8.parse('1234123412ABCDEF');
  //十六位十六进制数作为密钥

  const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412');
  let srcs = CryptoJS.enc.Utf8.parse(text);

  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.ciphertext.toString().toUpperCase();
};

const handleDecrypt = (encryptedText: string) => {
  const key = CryptoJS.enc.Utf8.parse('1234123412ABCDEF');
  //十六位十六进制数作为密钥

  const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412');

  let encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedText);

  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);

  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

  return decryptedStr.toString();
};
function PassportLoginPage(props: any) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);
  const [password, setPassword] = useState(false);
  const [smsCodeText, setSmsCodeText] = useState('获取验证码');
  const [form] = Form.useForm();
  const [formIndex, setformIndex] = useState(1);
  const handleSubmit = (values: any) => {
    setLoading(true);
    console.log(values);

    let type = formIndex == 2 ? 'user/loginPwd' : 'user/login';
    let loginMode = formIndex == 2 ? 'PASSWORD' : 'PHONE';
    if (!password) {
      props
        .dispatch({
          type: type,
          payload: {
            ...values,
            loginMode: loginMode,
          },
        })
        .then(async (data) => {
          window.localStorage.setItem('phone', values.phone);
          console.log(handleEncrypt(values.password));

          if (values.remember) {
            window.localStorage.setItem(
              'password',
              handleEncrypt(values.password),
            );
          } else {
            localStorage.removeItem('password');
          }
          const token = data.TOKEN;
          await props.dispatch({
            type: 'user/save',
            payload: {
              token: token,
            },
          });
          history.replace('/');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      props
        .dispatch({
          type: 'user/loginReset',
          payload: {
            ...values,
          },
        })
        .then((data) => {
          setPassword(false);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleGetSmsCode = () => {
    if (smsCodeText !== '获取验证码') {
      return;
    }
    const phone = form.getFieldValue('phone');
    if (!phone) {
      message.error('请输入手机号');
      return;
    }
    setSmsLoading(true);
    props
      .dispatch({
        type: 'user/sendCode',
        payload: {
          phone,
          loginMode: 'PHONE',
        },
      })
      .then(async (data) => {
        console.log('data', data);

        // 清除Url中的token
        startTimedown();
      })
      .finally(() => {
        setSmsLoading(false);
      });
  };

  const startTimedown = () => {
    let s = 60;
    setSmsCodeText(s + 's后重新获取');
    const timer = setInterval(() => {
      s -= 1;
      setSmsCodeText(s + 's后重新获取');
      if (s <= 0) {
        clearInterval(timer);
        setSmsCodeText('获取验证码');
      }
    }, 1000);
  };
  useEffect(() => {
    const phone = window.localStorage.getItem('phone') || '';
    const encryptedPassword = window.localStorage.getItem('password') || '';

    try {
      const decryptedPassword = handleDecrypt(encryptedPassword);
      form.setFieldsValue({
        phone,
        password: decryptedPassword,
        remember: decryptedPassword ? true : false,
      });
    } catch (error) {
      console.error('Error decrypting password:', error);
    }
  }, []);
  return (
    <div className="passport-login">
      <div className="contenBox">
        <div className="imgtop">
          <img src={require('@/assets/passport/zc_icon_logo.png')} alt="" />
          <img src={require('@/assets/passport/cht.png')} alt="" />
          {/* <span className="title">批发服务平台</span> */}
        </div>
        <div className="imgBox">
          <div className="img_text">
            <img src={require('@/assets/passport/zzfw.png')} alt="" />
            <div className="span_box">
              <span>正品货源</span>
              <span>价格专享</span>
              <span>服务保障</span>
            </div>
          </div>
        </div>
        {password == false ? (
          <div className="login-box">
            <div className="title">
              <img src={require('@/assets/passport/zc_icon_bt.png')} alt="" />
              {/* <h1>欢迎登录 ../../assets/passport/zc_icon_bt.png，</h1>
          <h1>宠货通批发系统</h1> */}
              <div className="tab">
                <span
                  className={formIndex == 1 ? 'seleSapn' : ''}
                  onClick={() => {
                    setformIndex(1);
                  }}
                >
                  短信验证码登录
                </span>
                <span
                  className={formIndex == 2 ? 'seleSapn cipher' : 'cipher'}
                  onClick={() => {
                    setformIndex(2);
                    form.setFieldsValue({
                      phone: '',
                    });
                  }}
                >
                  账号密码登录
                </span>
              </div>
            </div>

            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                name="phone"
                rules={[{ required: true, message: '请输入手机号' }]}
              >
                <Input
                  className="input"
                  placeholder={formIndex == 2 ? '请输入账号' : '请输入手机号'}
                  autoComplete="off"
                  maxLength={11}
                />
              </Form.Item>
              <Form.Item
                name={formIndex == 2 ? 'password' : 'code'}
                rules={[
                  {
                    required: true,
                    message: formIndex == 2 ? '请输入密码' : '请输入验证码',
                  },
                ]}
              >
                {formIndex == 2 ? (
                  <Input.Password
                    className="input"
                    placeholder="请输入密码"
                    autoComplete="off"
                  />
                ) : (
                  <Input
                    className="input"
                    placeholder="请输入验证码"
                    autoComplete="off"
                    maxLength={6}
                    suffix={
                      formIndex == 1 ? (
                        <Button
                          type="link"
                          onClick={handleGetSmsCode}
                          loading={smsLoading}
                        >
                          {smsCodeText}
                        </Button>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
              </Form.Item>
              {formIndex == 2 ? (
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox className="remember">记住密码</Checkbox>
                </Form.Item>
              ) : (
                ''
              )}

              <Form.Item className="btnBox">
                <Button
                  loading={loading}
                  type="primary"
                  className="btn"
                  htmlType="submit"
                >
                  登录
                </Button>
              </Form.Item>
              <Form.Item></Form.Item>
            </Form>
            {formIndex == 2 ? (
              <div className="memory" onClick={() => setPassword(true)}>
                忘记密码
              </div>
            ) : (
              ''
            )}

            <p style={{ fontSize: 14, color: '#999', textAlign: 'center' }}>
              注：若无账号，请联系客服注册
              {/* <a
            style={{ fontSize: 12, color: '#0277FF' }}
            target="_black"
            className="item i3"
            href="https://shop.petfafa.com/pdfxy.pdf"
          >
            《平台协议》
          </a> */}
            </p>
            {/* <p style={{ fontSize: 12, color: '#999' }}>
          注：若还未完成宠货通商城资质认证，请先去宠货通小程序完成注册及认证。
          <a
            style={{ fontSize: 12, color: '#0277FF' }}
            target="_black"
            className="item i3"
            href="https://shop.petfafa.com/pdfxy.pdf"
          >
            《平台协议》
          </a>
        </p> */}
          </div>
        ) : (
          <div className="login-box">
            <div className="title" onClick={() => setPassword(false)}>
              <img src={require('@/assets/passport/back.png')} alt="" />
              <img
                style={{ marginLeft: '14px' }}
                src={require('@/assets/passport/backlogo.png')}
                alt=""
              />
              {/* <h1>欢迎登录 ../../assets/passport/zc_icon_bt.png，</h1>
          <h1>宠货通批发系统</h1> */}
              <div className="tab"></div>
            </div>

            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                name="phone"
                rules={[{ required: true, message: '请输入手机号' }]}
              >
                <Input
                  className="input"
                  placeholder="请输入手机号"
                  autoComplete="off"
                  maxLength={11}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password
                  className="input"
                  placeholder="请输入密码"
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name="passwords"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('两次密码输入不一致');
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="input"
                  placeholder="请再次输入新密码"
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name="code"
                rules={[{ required: true, message: '请输入验证码' }]}
              >
                <Input
                  className="input"
                  placeholder="请输入验证码"
                  autoComplete="off"
                  maxLength={6}
                  suffix={
                    <Button
                      type="link"
                      onClick={handleGetSmsCode}
                      loading={smsLoading}
                    >
                      {smsCodeText}
                    </Button>
                  }
                />
              </Form.Item>

              <Form.Item className="btnBox">
                <Button
                  loading={loading}
                  type="primary"
                  className="btn"
                  htmlType="submit"
                >
                  确定
                </Button>
              </Form.Item>
              <Form.Item></Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(
  ({ user, loading }: { user: UserModelState; loading: Loading }) => ({
    user,
    loading: loading.models.user,
  }),
)(PassportLoginPage);
