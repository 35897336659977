import './index.less';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuList from '@/components/Home/MenuList';
import GoodsList from '@/components/Home/GoodsList';
import NewGoodsList from '@/components/Home/NewGoodsList';
import Recommend from '@/components/Home/Recommend';
import Notice from './components/Notice';
import HSwiper from '@/components/Home/Swiper';
import TimeDown from './components/TimeDown';
import GoodsItem from './components/GoodsItem';
import BigCustomerGoodItem from '@/components/Goods/BigCustomerGoodItem';
import CouponCon from './components/coupon';
import BrandComponents from './components/BrandComponents';
import Icon from '@/components/Icon';
import LeftNav from './components/LeftNav';
import { Image, Avatar, Modal } from 'antd';
import { connect, UserModelState, history } from 'umi';
import head from '@/assets/common/head.png';
import notieIcon from '@/assets/home/notice_icon.png';
import notieTitleIcon from '@/assets/home/notice_title_icon.png';
import notieMoreIcon from '@/assets/home/notice_more_icon.png';
import listEmptyImg from '@/assets/common/empty.png';
interface IProps {
  user: UserModelState;
  [props: string]: any;
}

function Home(props: IProps) {
  const [dogCategory, setDogCategory] = useState({
    regionId: 0,
    regionName: '',
    categoryList: [],
  });
  const [catCategory, setCatCategory] = useState({
    regionId: 0,
    regionName: '',
    categoryList: [],
  });
  const [firstFloorData, setFirstFloorData] = useState({
    hotSpuList: [],
    newSpuList: [],
  });
  const [secondFloorData, setSecondFloorData] = useState([]);
  const [fourthFloorData, setFourthFloorData] = useState({
    activityLength: 0,
    activityVO: [],
  });
  const [banners, setBanner] = useState([]);
  const [homeOrderNum, setHomeOrderNum] = useState({
    purchaseDeliver: '--',
    purchaseReceiving: '--',
    purchaseWait: '--',
    representativeDeliver: '--',
    representativeReceiving: '--',
    representativeWait: '--',
  });
  const [couponNum, setCouponNum] = useState({
    availableSum: '--',
    expireSum: '--',
  });

  const [goodsData, setGoodsData] = useState<any>([]);
  const [bigCustomerGoodsList, setBigCustomerGoodsList] = useState<any>([]);

  const getBigCustomerGoodList = () => {
    props
      .dispatch({
        type: 'goods/list',
        payload: {
          pageNo: 1,
          pageSize: 10,
        },
      })
      .then((res: any) => {
        setBigCustomerGoodsList(res.list);
      });
  };

  const handleDetail = ({ id }: { id: number }) => {
    history.push(`/goods/detail/${id}`);
  };

  const handleMore = () => {
    history.push('/search/more');
  };

  // 优惠券按钮开发中
  const warning = () => {
    Modal.warning({
      title: '正在开发中',
      content: '抱歉，请您耐心等待...',
    });
  };

  // 轮播
  const getBanner = () => {
    props
      .dispatch({
        type: 'goods/getBanner',
      })
      .then((res: any) => {
        if (res) {
          let table = res || [];
          if (table.length === 0) {
            setBanner([
              {
                url: null,
              },
            ]);
          } else {
            setBanner(table);
          }
        } else {
          setBanner([
            {
              url: null,
            },
          ]);
        }
      })
      .catch(() => {
        setBanner([
          {
            url: null,
          },
        ]);
      });
  };
  const onCategoryClick = (categoryParentId: number, regionId: number) => {
    if (!categoryParentId || !regionId) {
      return;
    }
    props
      .dispatch({
        type: 'goods/homeThirdFloor',
        payload: {
          regionId,
          categoryParentId,
          hotOrNewSwitch: true,
          pageNo: 1,
          pageSize: 10,
        },
      })
      .then((res) => {});
  };
  // 订单概况统计
  const getHomeOrderNum = () => {
    props
      .dispatch({
        type: 'order/getHomeOrderNum',
      })
      .then((res: any) => {
        if (res) {
          setHomeOrderNum(res);
        }
      });
  };
  const getCouponNum = () => {
    props
      .dispatch({
        type: 'goods/couponSum',
      })
      .then((data: any) => {
        setCouponNum(data);
      });
  };
  const gotoOrder = (pathname?: string, query?: any) => {
    history.push({
      pathname,
      query,
    });
  };
  interface IAtivityItem {
    detail: any;
  }
  const AtivityItem = (props: IAtivityItem) => {
    return (
      <div className="ativity-item" onClick={() => handleDetail(props.detail)}>
        <img src={props.detail.img} height={147} width={136} />
        <div className="a-i-c">
          <p className="a-i-t text-overflow">{props.detail.title}</p>
          <p className="a-i-p">&yen;{props.detail.price}</p>
          {props.detail.activityName ? (
            <span className="a-i-b">{props.detail.activityName}</span>
          ) : null}
        </div>
      </div>
    );
  };

  // 火爆专区
  const getFirstFloor = () => {
    props
      .dispatch({
        type: 'goods/homeFirstFloor',
      })
      .then((data: any) => {
        setFirstFloorData(data);
      });
  };
  // 火爆专区
  const getSecondFloor = () => {
    props
      .dispatch({
        type: 'goods/homeSecondFloor',
      })
      .then((data: any) => {
        setSecondFloorData(data);
      });
  };
  // 活动专区
  const getFourthFloor = () => {
    props
      .dispatch({
        type: 'goods/homeFourthFloor',
      })
      .then((data: any) => {
        if (data.activityLength >= 2) {
          data.activityVO = data.activityVO.map((item: any) => ({
            ...item,
            spuList: item.spuList.slice(0, 4),
          }));
        }
        setFourthFloorData(data);
      });
  };
  const onTimeDownEnd = () => {
    getFourthFloor();
  };
  const getCategoryRegionList = () => {
    props
      .dispatch({
        type: 'goods/categoryRegionList',
      })
      .then((data: any) => {
        if (data) {
          const dogList = data.find((item: any) => item.regionName === '狗狗');
          const catList = data.find((item: any) => item.regionName === '猫猫');
          if (dogList) setDogCategory(dogList);
          if (catList) setCatCategory(catList);
        }
      });
  };
  const handleCategoryClick = async (categoryItem: any) => {
    await props.dispatch({
      type: 'search/clear',
    });
    props.dispatch({
      type: 'search/saveCategoryItem',
      payload: {
        ...categoryItem,
      },
    });
    history.push('/search/more');
  };

  const getGoodsList = () => {
    props
      .dispatch({
        type: 'goods/getHomeActivitys',
      })
      .then((data: any) => {
        setGoodsData(data);
      });
  };

  const getUrlParams = (url: string) => {
    // 通过 ? 分割获取后面的参数字符串
    let urlStr = url.split('?')[1];
    // 创建空对象存储参数
    let obj: any = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&');
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=');
      obj[arr[0]] = arr[1];
    }
    return obj;
  };

  const getPageUrlId = (url: string) => {
    if (!url) return '';
    return getUrlParams(url).id;
  };

  useEffect(() => {
    getBanner();
    // getAllCategory();
    // getCategoryRegionList();
    // getFirstFloor();
    // getSecondFloor();
    // getFourthFloor();
    getHomeOrderNum();
    // getCouponNum();
    // getGoodsList();
    getBigCustomerGoodList();
  }, []);
  return (
    <div className="home">
      <div className="banner-container">
        <div className="banner-body">
          <MenuList visible={true} onClick={handleCategoryClick} />
          <div className="banner-swiper">
            <HSwiper banners={banners} />
          </div>

          <div className="banner-right-info-box">
            <div className="banner-userinfo">
              <div className="info">
                <Avatar
                  src={
                    <Image
                      src={
                        (props.user.user && props.user.user.avatarUrl) || head
                      }
                      preview={false}
                      fallback={head}
                    />
                  }
                />
                <div>
                  <div className="username">
                    {' '}
                    {/* {props.user.user && props.user.user.username} */}
                    {props.user.user.legalPerson}
                  </div>
                  {/* <span className="level-tag">
                    {props.user.user &&
                      props.user.user.roles &&
                      props.user.user.roles[0]}
                  </span> */}
                </div>
              </div>
            </div>
            <div className="banner-right-info">
              <div className="b-r-i-head">
                <div className="r-i-title">
                  <img
                    className="icon"
                    src={require('@/assets/home/purchase.png')}
                    alt=""
                  />

                  <span className="t">采购概况</span>
                </div>
              </div>
              <ul className="b-r-i-ul">
                <li
                  className="b-r-i-li"
                  onClick={() => gotoOrder('/user/purchase', { status: 10 })}
                >
                  <p className="st">待付款</p>
                  <p className="t">{homeOrderNum.purchaseWait}</p>
                </li>
                <li
                  className="b-r-i-li"
                  onClick={() => gotoOrder('/user/purchase', { status: 20 })}
                >
                  <p className="st">待发货</p>
                  <p className="t">{homeOrderNum.purchaseDeliver}</p>
                </li>
                <li
                  className="b-r-i-li"
                  onClick={() => gotoOrder('/user/purchase', { status: 30 })}
                >
                  <p className="st">待收货</p>
                  <p className="t">{homeOrderNum.purchaseReceiving}</p>
                </li>
              </ul>
            </div>
            {/* <div className="banner-right-info">
              <div className="b-r-i-head">
                <div className="r-i-title">
                  <img
                    className="icon"
                    src={require('@/assets/home/favorable.png')}
                    alt=""
                  />
                  <span className="t">优惠券</span>
                </div>
              </div>
              <ul className="b-r-i-ul">
                <li
                  className="b-r-i-li"
                  // onClick={() => gotoOrder('/user/coupon', { status: 0 })}
                  onClick={warning}
                >
                  <p className="st"> 可用 </p>
                  <p className="t">{couponNum.availableSum}</p>
                </li>
                <li
                  className="b-r-i-li"
                  // onClick={() => gotoOrder('/user/coupon', { status: 3 })}
                  onClick={warning}
                >
                  <p className="st">即将过期</p>
                  <p className="t">{couponNum.expireSum}</p>
                </li>
                <li className="b-r-i-li">
                  <p className="st"></p>
                  <p className="t"></p>
                </li>
              </ul>
            </div> */}
            <div className="banner-right-info">
              <div className="b-r-i-head">
                <div className="r-i-title">
                  <img src={notieIcon} alt="" className="notice_icon" />
                  <img
                    src={notieTitleIcon}
                    alt=""
                    className="notice_title_icon"
                  />
                </div>
                {/* <div className="m">
                  更多公告
                  <img
                    src={notieMoreIcon}
                    alt=""
                    className="notice_more_icon"
                  />
                </div> */}
              </div>
              <Notice />
            </div>
            {/* <img
              className="zy-icon"
              src={require('@/assets/home/zy.png')}
              alt=""
            />*/}
            {/* <img
              className="zy-icon-bg"
              src={require('@/assets/home/zy_bg.png')}
              alt=""
            /> */}
          </div>
        </div>
      </div>

      <div className="good_list">
        <Link to={'/search/more'} className="good_list_title">
          <h5>
            品牌选购
            <span className="sub_title">品牌精选 好货直供</span>
          </h5>
          <img src={notieMoreIcon} alt="" className="more_icon" />
        </Link>
        {bigCustomerGoodsList?.length ? (
          <div className="list">
            {bigCustomerGoodsList?.map((item: any) => {
              return <BigCustomerGoodItem goodInfo={item} />;
            })}
          </div>
        ) : (
          <div className="empty">
            <img src={listEmptyImg} alt="" />
            <p>商品正在上架中～</p>
          </div>
        )}
      </div>

      {/* <div className="components-activity components-box">
        {fourthFloorData.activityVO &&
          fourthFloorData.activityVO.map((item: any) => {
            return Array.isArray(item.spuList) && item.spuList.length ? (
              <div className="c-a-d">
                <h2 className="content-title" id="components-activity">
                  <div>
                    活动专区
                    <span style={{ marginRight: 16 }}>
                      {item.type === 0 ? '限时满赠' : '限时满减'}
                    </span>
                    <TimeDown endTime={item.endTime} onEnd={onTimeDownEnd} />
                  </div>
                  <Link to={`/home/activity?type=${item.type}`}>
                    <Icon className="icon" type="icongengduo1" />
                  </Link>
                </h2>
                <div className="item-list">
                  {item.spuList &&
                    item.spuList.map((spu) => <AtivityItem detail={spu} />)}
                </div>
              </div>
            ) : null;
          })}
      </div> */}

      {/* <div className="components-hot components-box " id="components-hot">
        <div className="c-a-d">
          <h2 className="content-title">
            <span>火爆专区</span>
            <Link to="/home/recommend?type=hot">
              <Icon className="icon" type="icongengduo1" />
            </Link>
          </h2>
          <div className="item-list">
            <div className="components-hot-img">
              <img src={require('@/assets/home/hot.png')} alt="" />
            </div>
            <div className="show-box">
              {firstFloorData.hotSpuList &&
                firstFloorData.hotSpuList.map((item) => (
                  <GoodsItem detail={item} onClick={handleDetail} />
                ))}
            </div>
          </div>
        </div>
        <div className="c-a-d">
          <h2 className="content-title">
            <span>新品专区</span>
            <Link to="/home/recommend?type=new">
              <Icon className="icon" type="icongengduo1" />
            </Link>
          </h2>
          <div className="item-list">
            <div className="components-hot-img">
              <img src={require('@/assets/home/new.png')} alt="" />
            </div>
            <div className="show-box">
              {firstFloorData.newSpuList &&
                firstFloorData.newSpuList.map((item) => (
                  <GoodsItem detail={item} onClick={handleDetail} />
                ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* {secondFloorData && secondFloorData.length ? (
        <div className="components-brand components-box " id="components-brand">
          <div className="c-a-d">
            <h2 className="content-title">
              <span>品牌专区</span>
            </h2>
            <BrandComponents list={secondFloorData} />
          </div>
        </div>
      ) : null} */}

      {/* <GoodsList
        categoryList={dogCategory.categoryList}
        title="狗狗专区"
        idName="components-dog"
        img={require('@/assets/home/dog_t.png')}
        onCategoryClick={onCategoryClick}
        regionId={dogCategory.regionId}
      />

      <GoodsList
        categoryList={catCategory.categoryList}
        regionId={catCategory.regionId}
        onCategoryClick={onCategoryClick}
        title="猫猫专区"
        idName="components-cat"
        img={require('@/assets/home/cat_t.png')}
      /> */}

      {/*
        goodsData.map((item: any) => {
          return (
            <NewGoodsList
              key={item.id}
              goodsList={item.skus.map((ele: any) => {
                return {
                  ...ele,
                  price: ele.skuPrice,
                };
              })}
              regionId={getPageUrlId(item.pageUrl)}
              title={item.name}
            />
          );
        })
      */}

      {/* <Recommend /> */}

      <div className="goods-line">
        <div className="l-line"></div>
        <span className="t">到底了</span>
        <div className="r-line"></div>
      </div>
      <CouponCon />
      {/* <LeftNav /> */}
    </div>
  );
}
export default connect(({ user }: { user: UserModelState }) => ({ user }))(
  Home,
);
