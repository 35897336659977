import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import Icon from '@/components/Icon';
import { OrderModelState, Dispatch, connect, Loading, history } from 'umi';
import './index.less';
interface IProps {
  visible: boolean;
  id: number;
  orderNo: string;
  type: number; //1.立即付款  2.取消订单  3.申请退款
  changeVisible: (flag: boolean, refresh: boolean) => void;
  order: OrderModelState;
  loading: Loading;
  dispatch: Dispatch;
}
function CouponBig(props: IProps) {
  // state
  const [data, setData] = useState<any>({});

  useEffect(() => {
    // getOrderData();
  }, [props]);

  // methods
  // 获取关联订单数据
  const getOrderData = () => {
    props
      .dispatch({
        type: 'userOrder/getList',
        payload: {},
      })
      .then((res: any) => {
        console.log('关联订单数据', res);
        setData(res);
      });
  };
  // 弹窗取消事件
  const handleCancel = () => {
    props.changeVisible(false, false);
  };
  // 弹窗确认
  const handleOk = () => {
    if (props.type === 1) {
      history.push(`/order/pay/${props.orderNo}`);
    }
    if (props.type === 2) {
      props
        .dispatch({
          type: 'userOrder/cancelOrder',
          payload: {
            orderId: props.id,
          },
        })
        .then((res: boolean) => {
          props.changeVisible(false, true);
        });
      return;
    }
    if (props.type === 3) {
      history.push(`/user/order/applyRefund?orderId=${props.id}`);
    }
  };

  return (
    <Modal
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="order-modal-style"
      title={
        <div className="modal-tit">
          <Icon
            className="icon"
            style={{ fontSize: '24px', marginRight: '13px' }}
            type="icontixing"
          />
          <p>操作提醒</p>
        </div>
      }
      width="640px"
    >
      <div className="modal-body">
        {props.type === 1 && (
          <p className="modal-p-child1">
            以下订单为关联订单，立即付款时需一并进行付款
          </p>
        )}
        {props.type === 2 && (
          <p className="modal-p-child1">
            以下订单为关联订单，取消订单时需一并进行取消，是否确认进行取消操作
          </p>
        )}
        {props.type === 3 && (
          <p className="modal-p-child1">
            以下订单为关联订单，申请退款时需一并进行申请退款，是否确认进行退款操作
          </p>
        )}
        <div className="modal-item">
          <div className="modal-item-label">订单号：</div>
          <div className="modal-item-orderlist">
            <div className="modal-item-order">G202202108050624171014</div>
            <div className="modal-item-order">G202202108050624171011</div>
            <div className="modal-item-order">G202202108050624171010</div>
            <div className="modal-item-order">G202202108050624171010</div>
          </div>
        </div>
        <div className="modal-item">
          <div className="modal-item-label">订单金额合计：</div>
          <div className="modal-item-value-price">¥62635</div>
        </div>
      </div>
    </Modal>
  );
}
export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(CouponBig);
