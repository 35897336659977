import React, { useState, useRef, useEffect } from 'react';
import { Input, Button, Select, Col, Row, Empty } from 'antd';
import './index.less';
import SearchItem from './SearchItem';
import { connect, Loading, history, Dispatch } from 'umi';
import { useScroll } from 'ahooks';
const { Search } = Input;
const { Option } = Select;
export interface HSearchProps {
  className?: string;
  loading: Loading;
  dispatch: Dispatch;
}
let timer: any;
const HSearch: React.FC<HSearchProps> = (props) => {
  const currentRef = useRef<any>(null);
  const searchRef = useRef<any>(null);
  const [keyWord, setKeyword] = useState<string | undefined>();
  const [searchList, setSearchList] = useState('' || []);
  const [isShowResult, setIsShowResult] = useState(false);
  const [keyType, setKeyType] = useState(0);
  const scroll = useScroll(document);
  const onSearchChange = (e: any) => {
    setKeyword(e.target.value);
    onBlur();
  };

  const onBlur = () => {
    setIsShowResult(false);
  };
  const handleSerachResultJump = (val: string) => {
    let kw = val.trim();
    if (kw === '') {
      return;
    }
    setKeyword(kw);
    const funKey =
      history.location.pathname.indexOf('/search/') > -1 ? 'replace' : 'push';
    const pathname = keyType ? '/search/shop' : '/search/more';
    props.dispatch({
      type: 'search/clear',
    });
    history[funKey]({
      pathname,
      query: {
        keyWord: kw,
      },
    });

    setIsShowResult(false);
  };

  const fetchSearchGoods = () => {
    props
      .dispatch({
        type: 'goods/getGoodsVague',
        payload: {
          keyWord,
        },
      })
      .then((data: any) => {
        setSearchList(data || []);
        // setIsShowResult(true);
      });
  };
  const onKeyTypeChange = (key: number) => {
    setKeyType(Number(key));
    if (key === 1) {
      onBlur();
    }
  };

  useEffect(() => {
    const query = history.location.query;
    if (query) {
      setKeyword(query.keyWord?.toString());
    }
    document.addEventListener('mousedown', handlClose);

    return () => {
      document.removeEventListener('mousedown', handlClose);
    };
  }, []);
  useEffect(() => {
    // 根据keyword查询list
    setSearchList([]);
    if (keyWord && keyType === 0) {
      clearTimeout(timer);
      timer = setTimeout(fetchSearchGoods, 500);
    }
  }, [keyWord, keyType]);

  useEffect(() => {
    const query = history.location.query;
    if (query) {
      setKeyword(query.keyWord?.toString());
    }
  }, [history.location.query]);

  const handlClose = (e: Event) => {
    if (!currentRef.current || !currentRef.current.contains(e.target)) {
      onBlur();
    }
  };
  const handleSearchOnClick = (e) => {
    console.log(keyWord);

    if (!isShowResult) {
      // setIsShowResult(true);
    }
  };

  return (
    <div className={`home-search ${props.className}`} ref={currentRef}>
      {scroll && scroll.top < 100 ? (
        <>
          {/* <div className="type-button-tool">
            <Button
              className={`type-button ${
                keyType === 0 ? 'type-button-selected' : ''
              }`}
              onClick={() => onKeyTypeChange(0)}
            >
              货品
            </Button>
            <Button
              className={`type-button ${
                keyType === 1 ? 'type-button-selected' : ''
              }`}
              onClick={() => onKeyTypeChange(1)}
            >
              商家
            </Button>
          </div> */}
          <Search
            defaultValue={keyWord}
            className="search-button"
            // placeholder={keyType ? '请输入商家名称' : '请输入货品名称'}
            placeholder={'搜索商品/供应商'}
            enterButton="搜索"
            // onChange={onSearchChange}
            onSearch={handleSerachResultJump}
            onClick={handleSearchOnClick}
          />
        </>
      ) : (
        <Row>
          {/* <Col span={3}>
            <Select
              value={keyType}
              className="search-select"
              onChange={onKeyTypeChange}
            >
              <Option value={0}>货品</Option>
              <Option value={1}>商家</Option>
            </Select>
          </Col> */}
          <Col span={21}>
            <Search
              defaultValue={keyWord}
              className="search-button search-button-min"
              // placeholder={keyType ? '请输入商家名称' : '请输入货品名称'}
              placeholder={'搜索商品/供应商'}
              enterButton="搜索"
              ref={searchRef}
              // onChange={onSearchChange}
              onSearch={handleSerachResultJump}
              onClick={handleSearchOnClick}
            />
          </Col>
        </Row>
      )}

      {isShowResult &&
      keyWord &&
      !props.loading.effects['goods/getGoodsVague'] &&
      keyType === 0 ? (
        <div
          className={`search-result-box ${
            searchList.length === 0 ? 'none-data' : ''
          }`}
        >
          {searchList.length > 0 ? (
            searchList.map((item: any, index: number) => (
              <SearchItem
                title={item.title}
                num={item.num}
                key={index}
                handleSerachResultJump={handleSerachResultJump}
              />
            ))
          ) : (
            <Empty
              image={require('@/assets/goods/2.png')}
              imageStyle={{
                width: '130px',
                height: '70px',
                display: 'inline-block',
                marginTop: '30px',
              }}
              description={keyType ? '未找到相关商家' : '未找到相关商品'}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default connect(({ loading }: { loading: Loading }) => ({ loading }))(
  HSearch,
);
