import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { OrderModelState, Dispatch, connect, Loading, history } from 'umi';
import moment from 'moment';
import './index.less';
import coupon1 from '@/assets/coupon/coupon1.png';
import coupon2 from '@/assets/coupon/coupon2.png';
import coupon3 from '@/assets/coupon/coupon3.png';
interface IProps {
  couponType: number; //1:可用优惠券 2:已用优惠券 3:过期优惠券
  couponData: any;
  order: OrderModelState;
  loading: Loading;
  dispatch: Dispatch;
}
function CouponBig(props: IProps) {
  // state
  const couponGoodType: any = { 0: '全部商品可用', 1: '部分商品可用' };
  const [data, setData] = useState<any>({});

  useEffect(() => {
    setData(props.couponData);
  }, [props.couponData]);

  // methods
  // 根据优惠券类型返回相应图片
  const getCouponImg = () => {
    const couponType = props.couponType;
    if (couponType === 0 || couponType === 3) {
      return coupon1;
    }
    if (couponType === 1) {
      return coupon2;
    }
    if (couponType === 2) {
      return coupon3;
    }
  };
  // 优惠券去使用跳转 满减活动页
  const handleToUse = (data: any) => {
    history.push('/home/activity?type=1');
  };

  return (
    <div className="coupon-big">
      <img className="coupon-big-img" src={getCouponImg()} alt="" />
      <div className="coupon-big-info">
        <p className="coupon-price">
          ¥<span>{data.discount}</span>
        </p>
        <p className="coupon-condition">满{data.min}可用</p>
        <p className="coupon-title">{data.couponTitle}</p>
        <p className="coupon-txt">优惠券类型：店铺优惠券</p>
        <p className="coupon-txt">适用对象：{data.tenantName}</p>
        <p className="coupon-txt">券编号：{data.couponNo}</p>
        <p className="coupon-txt" style={{ letterSpacing: 0 }}>
          {moment(data.startTime).format('YYYY.MM.DD HH:mm')} -{' '}
          {moment(data.endTime).format('YYYY.MM.DD HH:mm')}
        </p>
        {(props.couponType === 0 || props.couponType === 3) && (
          <Button className="coupon-btn" onClick={handleToUse}>
            立即使用
          </Button>
        )}
      </div>
    </div>
  );
}
export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(CouponBig);
