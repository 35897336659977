import React, { useState, useEffect, useMemo } from 'react';
import { Button, Radio, Modal } from 'antd';
import AddressCreaterModal from '@/components/Modal/AddressCreater';
import { connect, UserModelState, Loading, Dispatch, IAddressItem } from 'umi';
import tishiIcon from '@/assets/common/tishi.svg';
const { confirm } = Modal;
import './index.less';
interface IProps {
  user: UserModelState;
  loading: Loading;
  dispatch: Dispatch;
  onChange: (addressId: number | null) => void;
}
function ShippingAddress(props: IProps) {
  const [value, setValue] = useState<number | null>(null);
  const [isShowMoreAddress, setIsShowMoreAddress] = useState(false);
  const [isShowAddressCreater, setIsShowAddressCreater] = useState(false);
  const [
    currentAddresItem,
    setCurrentAddresItem,
  ] = useState<IAddressItem | null>(null);
  const onChange = (e: any) => {
    setValue(e.target.value);
  };
  const handleUpdate = (detail: IAddressItem) => {
    setCurrentAddresItem(detail);
    setIsShowAddressCreater(true);
  };

  const handleDelete = (id: number) => {
    confirm({
      content: '确认删除该地址？',
      className: 'custom-2',
      icon: <img className="icon" src={tishiIcon} />,
      onOk() {
        return props.dispatch({
          type: 'user/addressDelete',
          payload: {
            id,
          },
        });
      },
    });
  };

  const handleDefaultAddress = (detail: IAddressItem) => {
    props.dispatch({
      type: 'user/addressCreate',
      payload: {
        ...detail,
        defaultAddress: true,
      },
    });
  };
  const addressList = useMemo(
    () =>
      isShowMoreAddress
        ? props.user.addressList
        : props.user.addressList.slice(0, 3),
    [props.user.addressList, isShowMoreAddress],
  );
  useEffect(() => {
    props
      .dispatch({
        type: 'user/addressList',
      })
      .then((data) => {
        if (data.items && data.items.length) {
          let index = data.items.findIndex((item) => item.defaultAddress);
          if (index === -1) {
            index = 0;
          }
          setValue(data.items[index].id);
        }
      });
  }, []);
  useEffect(() => {
    props.onChange(value);
  }, [value]);
  return (
    <div className="shipping-address-container">
      <Radio.Group
        className="radio-group-container"
        onChange={onChange}
        value={value}
      >
        {addressList.map((item) => (
          <Radio className="radio-container" value={item.id} key={item.id}>
            <div className="info-item">
              <div className="text-item">
                <span>收货人：</span>
                <p style={{ width: 100 }}>{item.consignee}</p>
              </div>
              <div className="text-item">
                <span>联系电话：</span>
                <p>{item.phone}</p>
              </div>
              <div className="text-item">
                <span>收货地址：</span>
                <p style={{ maxWidth: '450px' }}>
                  {item.province}
                  {item.city}
                  {item.county}
                  {item.address}
                </p>
              </div>
            </div>
            <div className="handle-btn">
              {item.defaultAddress ? (
                <p className="tag">默认地址</p>
              ) : (
                <Button
                  type="text"
                  className="custom"
                  onClick={() => handleDefaultAddress(item)}
                >
                  设为默认
                </Button>
              )}
              <Button
                type="text"
                className="custom"
                onClick={() => handleUpdate(item)}
              >
                修改
              </Button>
              <Button
                type="text"
                className="custom brinit"
                onClick={() => handleDelete(item.id)}
              >
                删除
              </Button>
            </div>
          </Radio>
        ))}
      </Radio.Group>
      {addressList.length === 0 && <p className="nodata">暂无收货地址</p>}
      {props.user.addressList.length > 3 && !isShowMoreAddress && (
        <div className="more-con">
          <Button
            type="text"
            className="custom"
            onClick={() => setIsShowMoreAddress(true)}
          >
            查看更多地址
          </Button>
        </div>
      )}
      <AddressCreaterModal
        visible={isShowAddressCreater}
        initialValues={currentAddresItem}
        onCancel={() => setIsShowAddressCreater(false)}
      />
    </div>
  );
}
export default connect(
  ({ user, loading }: { user: UserModelState; loading: Loading }) => ({
    user,
    loading: loading.models.user,
  }),
)(ShippingAddress);
