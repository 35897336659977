import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Space, Modal, Image } from 'antd';
import { history, connect, OrderModelState, Loading } from 'umi';
import Icon from '@/components/Icon';
import tishiIcon from '@/assets/common/tishi.svg';
import { refundStatusMap } from '@/utils/enum';
import moment from 'moment';
import ChooseRefund from '../Order/components/ApplyRefund/ChooseRefund';
import { RightOutlined } from '@ant-design/icons';
import './RefundDetail.less';
const { confirm } = Modal;
function RefundDetail(props: any) {
  // state
  const [detailData, setDetailData] = useState<any>({});
  // 生命周期
  useEffect(() => {
    const data = history.location.query;
    getDetail(data);
  }, []);
  // methods
  // 获取退款详情
  const getDetail = (data: any) => {
    props
      .dispatch({
        type: 'userOrder/getRefundDetail',
        payload: { ...data },
      })
      .then((res: any) => {
        setDetailData({ ...res });
      });
  };
  // 撤销申请
  const handleDelete = () => {
    confirm({
      title: '确认要取消退款申请吗',
      content:
        '取消退款申请后，本次退款将关闭，您还可以再次发起退款，确定继续吗',
      className: 'custom-2',
      icon: <img className="icon" src={tishiIcon} />,
      onOk() {
        props
          .dispatch({
            type: 'userOrder/cancelRefund',
            payload: { refundOrderNo: detailData.refundOrderNo },
          })
          .then((res: boolean) => {
            getDetail(history.location.query);
          });
      },
    });
  };
  return (
    <div className="refund-detail-con">
      <div className="refund-detail-container">
        <div className="refund-detail-left">
          <div className="refund-detail-history">
            <div className="public-top">
              <Icon
                className="icon"
                style={{ fontSize: '22px', marginRight: '10px' }}
                type="iconxieshanglishi"
              />
              <span className="public-tit">退款单信息</span>
            </div>

            <div className="history-item">
              <div className="history-top">
                <div className="h-t-row">
                  <p className="h-t-col">
                    <span className="label">退款单号：</span>
                    <span className="value">
                      {detailData.refundOrderNo || ''}
                    </span>
                  </p>
                  <p className="h-t-col">
                    <span className="label">
                      <span style={{ marginRight: 28 }}>状</span>态：
                    </span>
                    <span className="value">
                      {refundStatusMap[detailData.status]}
                    </span>
                  </p>
                </div>
                <div className="h-t-row">
                  <p className="h-t-col">
                    <span className="label">申请时间：</span>
                    <span className="value">{detailData.gmtCreate || ''}</span>
                  </p>
                  <p className="h-t-col">
                    {detailData.status === 1000 && (
                      <Button
                        type="link"
                        className="h-t-btn"
                        onClick={handleDelete}
                      >
                        撤销退款/退货退款申请
                      </Button>
                    )}
                  </p>
                </div>
                {detailData.adminRemark ? (
                  <div className="h-t-row">
                    <p className="h-t-col">
                      <span className="label">商家备注：</span>
                      <span className="value">{detailData.adminRemark}</span>
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="history-info">
                <div className="history-info-item">
                  <div className="info-label">
                    <span style={{ marginRight: 28 }}>原</span> 因
                  </div>
                  <div className="info-value">{detailData.refundReason}</div>
                </div>
                <div className="history-info-item">
                  <div className="info-label">运费金额</div>
                  <div className="info-value">
                    ¥{detailData.refundOrderFreightPrice || 0}
                  </div>
                </div>
                <div className="history-info-item">
                  <div className="info-label">货品金额</div>
                  <div className="info-value">
                    ¥{detailData.skuRefundAmount || 0}
                  </div>
                </div>
                <div className="history-info-item long-item">
                  <div
                    className="info-label"
                    style={{ width: '100px', marginRight: '3px' }}
                  >
                    退款说明
                  </div>
                  <div className="info-value" style={{ flex: '1' }}>
                    {detailData.refundDesc || '-'}
                  </div>
                </div>
                <div className="history-info-item long-item">
                  <div className="info-label" style={{ width: '64px' }}>
                    退款凭证
                  </div>
                  <div className="info-value" style={{ flex: 1 }}>
                    {detailData.imgUrl &&
                      detailData.imgUrl.map((ele: string) => {
                        return <Image className="img" src={ele} />;
                      })}
                  </div>
                </div>
              </div>
            </div>
            {detailData.refundOrderLogisticsVO && (
              <div className="history-item">
                <div className="history-top">
                  <div className="h-t-row">
                    <p className="h-t-col">
                      <span className="label">寄件物流：</span>
                      <span className="value">
                        {detailData.refundOrderLogisticsVO?.logisticsCompany ||
                          ''}
                      </span>
                    </p>
                    <p className="h-t-col">
                      <span className="label">联系电话：</span>
                      <span className="value">
                        {detailData.refundOrderLogisticsVO?.phone}
                      </span>
                    </p>
                  </div>
                  <div className="h-t-row">
                    <p className="h-t-col">
                      <span className="label">物流单号：</span>
                      <span className="value">
                        {detailData.refundOrderLogisticsVO?.logisticsOrderNo ||
                          ''}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="history-info">
                  <div className="history-info-item long-item">
                    <div
                      className="info-label"
                      style={{ width: '100px', marginRight: '3px' }}
                    >
                      补充说明
                    </div>
                    <div className="info-value" style={{ flex: '1' }}>
                      {detailData.refundOrderLogisticsVO?.remark || '-'}
                    </div>
                  </div>
                  <div className="history-info-item long-item">
                    <div className="info-label" style={{ width: '64px' }}>
                      退款凭证
                    </div>
                    <div className="info-value" style={{ flex: 1 }}>
                      {detailData.refundOrderLogisticsVO?.imgList &&
                        detailData.refundOrderLogisticsVO?.imgList.map(
                          (ele: string) => {
                            return <Image className="img" src={ele} />;
                          },
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="refund-detail-right">
          <div className="public-top">
            <Icon
              className="icon"
              style={{ fontSize: '22px', marginRight: '10px' }}
              type="icondingdanxinxi"
            />
            <span className="public-tit">订单信息</span>
          </div>
          <div className="refund-good-card">
            <div className="refund-good">
              <p className="r-g-row">
                <span className="label">商家名称</span>
                <span className="value">{detailData.tenantName || ''}</span>
              </p>
              <p className="r-g-row">
                <span className="label">客服电话</span>
                <span className="value">{detailData.serviceTel || ''}</span>
              </p>
            </div>
            <div className="refund-good-shadow"></div>
          </div>
          <div className="refund-good-detail">
            <div className="refund-good-top">
              <div className="refund-good-item">
                <div className="good-label">订单编号</div>
                <div className="good-value">{detailData.orderNo}</div>
              </div>
              <div className="refund-good-item">
                <div className="good-label">下单时间</div>
                <div className="good-value">
                  {moment(detailData.gmtOrderCreate).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </div>
              </div>
              <div className="refund-good-item">
                <div className="good-label">付款金额</div>
                <div className="good-value" style={{ fontWeight: 500 }}>
                  ¥{detailData.actualAmount || 0}
                  <span className="freightPrice">
                    （含运费{detailData.freightPrice}元）
                  </span>
                </div>
              </div>
              <div className="refund-good-item">
                <div className="good-label">订单状态</div>
                <div className="good-value">
                  {detailData.status ? refundStatusMap[detailData.status] : ''}
                </div>
              </div>
            </div>
            <div className="refund-good-btn">
              <Button
                type="link"
                className="r-d-btn"
                onClick={() =>
                  history.push(
                    '/user/order/detail?orderNo=' + detailData.orderNo,
                  )
                }
              >
                查看订单详情
                <RightOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="refund-detail-sku-list">
        <ChooseRefund
          listKey={'refundOrderSkuVOList'}
          isview={true}
          list={(detailData.orderNo && [detailData]) || []}
          showDataFlag={true}
          loading={false}
        />
      </div>
    </div>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(RefundDetail);
