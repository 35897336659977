import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  InputNumber,
  message,
  Select,
  Modal,
} from 'antd';
import { history, connect, OrderModelState, Loading } from 'umi';
import Icon from '@/components/Icon';
import MyUpload from '@/components/Upload/Upload';
import ChooseRefund from '../ChooseRefund';
import { RightOutlined } from '@ant-design/icons';
import { REFUND_STATUS_MAP, orderStatusMap } from '@/utils/enum';
import tishiIcon from '@/assets/common/tishi.svg';
import moment from 'moment';
import './index.less';
const { TextArea } = Input;
const { confirm } = Modal;
interface IProps {
  handleSubmit?: (formVal: any) => void;
  // 退款订单
  detailData: any;
  submitLoading: boolean;
  [props: string]: any;
}
function ApplyRefund(props: IProps) {
  const { detailData, submitLoading } = props;
  const [form] = Form.useForm();
  const [webImageList, setWebImageList] = useState<any[]>([]);
  const [formDetail, setFormDetail] = useState<any>({});
  // 生命周期
  useEffect(() => {}, []);

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    console.log('values', values);
    if (!values.refundReason) {
      message.warning('请输入退款原因');
      return;
    }
    if (!values.refundDesc) {
      message.warning('请输入退款说明');
      return;
    }
    // if (!values.imgList || values.imgList.length === 0) {
    //   message.warning('请上传退款凭证');
    //   return;
    // }
    props.handleSubmit && props.handleSubmit(values);
  };
  const openDetail = () => {
    confirm({
      title: '提示',
      content: '是否确认离开当前页面，离开后当前页面信息将会丢失',
      className: 'custom-2',
      icon: <img className="icon" src={tishiIcon} />,
      onOk() {
        history.push(
          `/user/order/detail?orderNo=${detailData.orderNo}&confirm=1`,
        );
      },
    });
  };
  const confirmCalcApplyRefund = () => {
    const payload = {
      orderNo: detailData.orderNo,
      orderSkuIdList: props.orderSkuIds,
      refundType: props.refundType * 1,
      warehouseId: props.warehouseId,
    };
    props
      .dispatch({
        type: 'order/confirmCalcApplyRefund',
        payload,
      })
      .then((res: any) => {
        setFormDetail(res);
      });
  };
  useEffect(() => {
    confirmCalcApplyRefund();
  }, []);
  return (
    <div className="fill-refund">
      <ChooseRefund
        isview={true}
        list={detailData.purchaseOrderSkuDetailGroupByDepotVOSList || []}
        showDataFlag={true}
        loading={false}
      />
      <div className="apply-refund-container">
        <div className="apply-refund-lf">
          <div className="apply-top">
            <div className="public-top">
              <Icon
                className="icon"
                style={{ fontSize: '22px', marginRight: '10px' }}
                type="icondingdanxinxi"
              />
              <span className="public-tit">订单信息</span>
            </div>
          </div>

          <div className="apply-form">
            <Form form={form} initialValues={{ refundReason: undefined }}>
              <div className="apply-form-item">
                <div className="left-tit">
                  <div className="line"></div>
                  <span>退款原因</span>
                </div>
                <div className="apply-form-right">
                  <div className="required">*</div>
                  <Form.Item name="refundReason" noStyle>
                    <Select style={{ width: 208 }} placeholder="请选择原因">
                      {Object.keys(REFUND_STATUS_MAP).map(
                        (status: any, index: number) => (
                          <Select.Option
                            value={REFUND_STATUS_MAP[status]}
                            key={index}
                          >
                            {REFUND_STATUS_MAP[status]}
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="apply-form-item column">
                <div className="price-box">
                  <div className="left-tit">
                    <div className="line"></div>
                    <span>货品金额</span>
                  </div>
                  <div className="apply-form-right">
                    <div className="apply-form-price">
                      <span>¥{formDetail?.skuRefundAmount || 0}</span>
                    </div>
                  </div>
                </div>
                <div className="price-box">
                  <div className="left-tit">
                    <div className="line" style={{ opacity: 0 }}></div>
                    <span>运费金额</span>
                  </div>
                  <div className="apply-form-right">
                    <div className="apply-form-price">
                      <span>¥{formDetail?.refundOrderFreightPrice || 0}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="apply-form-item">
                <div className="left-tit">
                  <div className="line"></div>
                  <span>退款说明</span>
                </div>
                <div className="apply-form-right">
                  <div className="required">*</div>
                  <Form.Item name="refundDesc" noStyle>
                    <TextArea
                      showCount
                      maxLength={200}
                      rows={4}
                      placeholder="请填写补充说明，最多200字"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="apply-form-item">
                <div className="left-tit">
                  <div className="line"></div>
                  <span>退款凭证</span>
                </div>
                <div className="apply-form-right">
                  {/* <div className="required">*</div> */}
                  <Form.Item name="imgList" noStyle>
                    <MyUpload
                      uploadTitle="凭证上传"
                      size={24}
                      limitNumber={10}
                      maxSize={5}
                    ></MyUpload>
                  </Form.Item>
                  <p className="apply-form-priceInfo">
                    可上传10张图片，只支持jpg、jpeg、png和bmp格式，单张最大5M
                  </p>
                </div>
              </div>
              <div className="apply-form-item">
                <Button
                  className="form-btn"
                  type="primary"
                  loading={submitLoading}
                  onClick={handleSubmit}
                >
                  提交
                </Button>
              </div>
            </Form>
          </div>
        </div>
        {/* 订单 */}
        <div className="apply-refund-rg">
          <div className="apply-top">
            <div className="public-top">
              <Icon
                className="icon"
                style={{ fontSize: '22px', marginRight: '10px' }}
                type="icondingdanxinxi"
              />
              <span className="public-tit">订单信息</span>
            </div>
          </div>
          <div className="apply-company-info">
            <div className="content">
              <p className="company">
                <span className="label">商家名称</span>
                <span className="value">{detailData.tenantName || ''}</span>
              </p>
              <p className="phone">
                <span className="label">客服电话</span>
                <span className="value">{detailData.serviceTel || ''}</span>
              </p>
            </div>
            <div className="mask"></div>
          </div>
          <p className="order-row">
            <span className="label">订单编号</span>
            <span className="value">{detailData.orderNo || ''}</span>
          </p>
          <p className="order-row">
            <span className="label">下单时间</span>
            <span className="value">
              {detailData.gmtPay
                ? moment(detailData.gmtPay).format('YYYY-MM-DD HH:mm')
                : ''}
            </span>
          </p>
          <p className="order-row">
            <span className="label">付款金额</span>
            <span className="value">
              {formDetail?.actualAmount ? `¥${formDetail?.actualAmount}` : ''}
            </span>
            <span className="freight">
              （含运费{formDetail.freightPrice || 0}元）
            </span>
          </p>
          <p className="order-row">
            <span className="label">订单状态</span>
            <span className="value">
              {detailData.status ? orderStatusMap[detailData.status] : ''}
            </span>
          </p>
          <div className="order-footer">
            <Button type="link" className="btn" onClick={openDetail}>
              查看订单详情
              <RightOutlined />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(ApplyRefund);
