import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2530903_s6th5thwn3.js',
});
function Icon(props: any) {
  return <IconFont {...props} type={props.type ? props.type : ' '} />;
}

export default Icon;
