import React, { useEffect, useMemo, useState } from 'react';
import CompanyInfo from '@/components/Goods/CompanyInfo';
import StoreCategoryList from '@/pages/Goods/components/StoreCategoryList';
import HotGoodsList from '@/pages/Goods/components/HotGoodsList';
import Filter from '@/pages/Search/More/components/Filter';
import GoodsItem from '@/components/Goods/Item';
import './Category.less';
import { Pagination } from 'antd';
import { connect } from 'umi';
let timer: any;
function Index(props: any) {
  const [total, setTotal] = useState(0);
  const [pagin, setPagin] = useState({
    pageNo: 1,
    pageSize: 20,
  });
  const [params, setParams] = useState<any>({
    maxPrice: null,
    bySynthetical: true,
    minPrice: null,
    sort: undefined,
    categoryId: null,
  });
  const [goodsList, setGoodsList] = useState([]);

  const tenantId = useMemo(() => {
    return props.match.params.tenantId;
  }, [props.match]);

  const onFilterParamsChange = (filterParams: any) => {
    const sortNameArray = [undefined, 'SALES', 'PRICE'];
    const sortArray = ['DESC', 'ASC'];
    const sort = sortArray[filterParams.sortType];
    const sortName = sortNameArray[filterParams.sort];
    const bySynthetical = !sortName;
    const sortAsc = filterParams.sortType === 1;
    setParams({
      ...params,
      sort,
      sortName,
      sortAsc,
      bySynthetical,
      minPrice: filterParams.lowPrice ? filterParams.lowPrice : null,
      maxPrice: filterParams.highPrice ? filterParams.highPrice : null,
    });
    setPagin({
      ...pagin,
      pageNo: 1,
    });
  };
  const handleChildClick = (child: any) => {
    setPagin({
      ...pagin,
      pageNo: 1,
    });
    setParams({
      ...params,
      categoryId: child?.id,
    });
  };
  const getList = () => {
    props
      .dispatch({
        type: 'goods/list',
        payload: {
          ...pagin,
          tenantId,
          ...params,
          tenantCategoryId: params.categoryId,
          categoryId: undefined,
        },
      })
      .then((data: any) => {
        setTotal(data.size);
        setGoodsList(data.list);
      });
  };
  const onPaginChange = (pageNo: number) => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setPagin({
      ...pagin,
      pageNo,
    });
  };
  useEffect(() => {
    if (!tenantId) {
      return;
    }
    clearTimeout(timer);
    timer = setTimeout(getList, 300);
  }, [params, pagin, tenantId]);
  useEffect(() => {
    if (props.location.query && props.location.query.categoryId) {
      setParams({
        ...params,
        categoryId: props.location.query.categoryId,
      });
    }
  }, [props.location.query]);
  return (
    <div className="shop-category-container">
      <CompanyInfo tenantId={tenantId} />
      <div className="shop-category-body">
        <div className="w1200">
          <div className="left-con">
            <StoreCategoryList
              isShowAll={true}
              companyName=""
              tenantId={tenantId}
              handleChildClick={handleChildClick}
            />
            <HotGoodsList tenantId={tenantId} len={5} />
          </div>
          <div className="right-con">
            <Filter
              total={total}
              pagin={pagin}
              onParamsChange={onFilterParamsChange}
              onPaginChange={(pageNo) => setPagin({ ...pagin, pageNo })}
            />
            <div className="list">
              {goodsList &&
                goodsList.map((item: any) => (
                  <GoodsItem key={item.id} detail={item} />
                ))}
            </div>
            {total > pagin.pageSize && (
              <div className="pagin-con">
                <Pagination
                  total={total}
                  current={pagin.pageNo}
                  showSizeChanger={false}
                  showQuickJumper
                  pageSize={pagin.pageSize}
                  onChange={onPaginChange}
                  className="pagination"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect()(Index);
