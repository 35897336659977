import React, { useState } from 'react';
import { Image, Button } from 'antd';
import Icon from '@/components/Icon';
import { history } from 'umi';
import { orderStatusMap } from '@/utils/enum';
import './index.less';
interface IItemProps {
  id: number;
  /**
   * 公司名字
   */
  tenantName: number;
  /**
   * 实付款
   */
  actualPrice: number;
  /**
   * 下单时间
   */
  createTime: string;
  /**
   * 订单号
   */
  orderNo: string;
  /**
   * 运费
   */
  freightPrice: string;
  /**
   * 订单sku
   */
  orderSkuVOList: any[];
  /**
   * 订单状态
   */
  status: string;
  /**
   * 是否入库
   */
  isStorage: string;
  /**
   * 是否售后 转退款(0-非退款状态，1-退款状态)
   */
  goRefund: number;
  /**
   * 是否赠品
   */
  isPresent: number;
  [props: string]: any;
  /**
   * 取消订单
   */
  onOpenModal: (val: any) => void;
  /**
   * 确认收货
   */
  onOpenConfirmModal: (val: any) => void;
  /**
   * 一键入库
   */
  onOpenWarehousingModal: (val: any) => void;
}

type IProps = IItemProps;
function GoodRow(props: IProps) {
  const {
    id,
    orderNo,
    createTime,
    freightPrice,
    actualPrice,
    tenantName,
    tenantId,
    orderSkuVOList = [],
    status,
    goRefund,
    isStorage,
    isPresent,
    payPrice,
    receivingButton,
  } = props;
  const [showMoreGood, setShowMoreGood] = useState(false);

  const ButtonsCom = (buttonProps: {
    status: any;
    isStorage: any;
    goRefund: any;
  }) => {
    const { status, isStorage, goRefund } = buttonProps;

    if (status === 10 || status === 15) {
      return (
        <>
          <Button
            className="btn"
            type="primary"
            onClick={() => history.push(`/order/pay/${JSON.stringify([id])}`)}
          >
            立即付款
          </Button>
          <Button
            className="handle-usual"
            type="link"
            onClick={() => props.onOpenModal(props)}
          >
            取消订单
          </Button>
        </>
      );
    } else if (status <= 30 && goRefund === 0 && status != 14) {
      return (
        <>
          {status === 30 && receivingButton && (
            <Button
              className="btn"
              type="primary"
              onClick={() => props.onOpenConfirmModal(props)}
            >
              确认收货
            </Button>
          )}
          <Button
            className="handle-usual"
            type="link"
            onClick={() =>
              history.push(`/user/order/applyRefund?orderNo=${orderNo}`)
            }
          >
            申请退款
          </Button>
        </>
      );
    } else if (status === 30 && receivingButton) {
      return (
        <Button
          className="btn"
          type="primary"
          onClick={() => props.onOpenConfirmModal(props)}
        >
          确认收货
        </Button>
      );
    } else if (status === 50 && !isStorage) {
      // return (
      //   <Button
      //     className="btn"
      //     type="primary"
      //     onClick={() => props.onOpenWarehousingModal(props)}
      //   >
      //     一键入库
      //   </Button>
      // );
    }
    return null;
  };
  const handleGotoReturn = (item: any) => {
    history.push({
      pathname: '/user/order/refund/detail',
      query: {
        refundOrderNo: item.refundOrderNo,
      },
    });
  };
  const handleGoto = (pathname: string, query?: any) => {
    history.push({
      pathname,
      query,
    });
  };
  return (
    <div className="good-row">
      <div className="good-title">
        <p className="t-l">
          <span className="t-label">订单号：</span>
          <span className="t-value">{orderNo}</span>
          <span
            className="t-company"
            onClick={() => handleGoto(`/shop/${tenantId}/category`)}
          >
            {tenantName}
          </span>
        </p>
        <p className="t-r">
          <Icon className="icon" type="iconshijian1" />
          <span>{createTime}</span>
        </p>
      </div>
      <div className="good-content">
        <div className={`g-l ${showMoreGood ? 'more' : ''}`}>
          {orderSkuVOList.length > 0 &&
            orderSkuVOList.map((item: any, index: number) => (
              <div className="good-item-info" key={index}>
                <Image src={item.skuImg} className="g-img" />
                <div className="good-info">
                  <div className="g-info-left">
                    <div className="g-title">{item.spuTitle || ''}</div>
                    <div className="g-skuTitle">
                      {item.attributeVOList &&
                        item.attributeVOList.map(
                          (attr: any, attIndex: number) => (
                            <span
                              className={`sku-tag ${
                                item.attributeVOList.length - 1 === attIndex
                                  ? 'last'
                                  : ''
                              }`}
                              key={attIndex}
                            >
                              {attr.name}：{attr.value}
                            </span>
                          ),
                        )}
                      {item.refundOrderStatus && (
                        <span
                          className={`sku-status-action ${
                            item.refundOrderStatus === 1000
                              ? 'error'
                              : item.refundOrderStatus === 1100
                              ? 'progress'
                              : item.refundOrderStatus === 1200
                              ? 'success'
                              : ''
                          }`}
                          onClick={() => handleGotoReturn(item)}
                        >
                          {item.refundOrderStatus === 1000
                            ? '售后处理中'
                            : item.refundOrderStatus === 1100
                            ? '退款中'
                            : item.refundOrderStatus === 1200
                            ? '退款成功'
                            : ''}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="g-price">
                    {item.isPresent === 1 ? (
                      <span className="gift-tag">赠品</span>
                    ) : (
                      // 大客户价格
                      <p className="price">¥{item.priceSubtotal || 0}</p>
                    )}
                    {/* <p className="handle">申请退款</p> */}
                  </div>
                  <div className="g-num">x{item.num || 1}</div>
                </div>
              </div>
            ))}
          {!showMoreGood && orderSkuVOList.length > 3 && (
            <div className="g-more" onClick={() => setShowMoreGood(true)}>
              查看更多货品
            </div>
          )}
        </div>
        <div className="g-r border">
          <div className="info-item">
            <p className="price">¥{actualPrice || 0}</p>
            <p className="freight">(含运费：¥{freightPrice || 0})</p>
          </div>
          <div className="info-item">
            <p className="status">
              {goRefund === 1 && status < 50
                ? '(售后处理中)'
                : orderStatusMap[status]}
            </p>
            <p
              className="detail"
              onClick={() =>
                history.push(`/user/order/detail?orderNo=${orderNo}&confirm=1`)
              }
            >
              订单详情
            </p>
          </div>
          <div className="info-item">
            <ButtonsCom
              status={status}
              isStorage={isStorage}
              goRefund={goRefund}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoodRow;
