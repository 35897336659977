import React, { useState } from 'react';
import { Image } from 'antd';
import NumberStep from '@/components/Goods/NumberStep';
import { fixAmount } from '@/utils';
import { history } from 'umi';

export interface GoodItem {
  showDataFlag?: boolean;
  /**
   * spu名称
   */
  spuTitle?: string;
  /**
   * skuId
   */
  skuId?: string;
  selectedRowKeys?: any[];
  setSelectedRowKeys?: () => void;
  [props: string]: any;
}
/**
 *
 * @param goods 商品信息
 * status状态（0-可买，1-缺货，2-下架，3-已删除）
 * @returns
 */
const GoodsRow = (propsGoods: GoodItem) => {
  const {
    spuTitle,
    skuImg,
    price,
    nowPrice,
    num,
    miniNum,
    spuId,
    attributeVOList = [],
    selectedRowKeys = [],
    setSelectedRowKeys,
  } = propsGoods;
  const handleClickTitle = (spuId: any) => {
    history.push({
      pathname: `/goods/detail/${spuId}`,
    });
  };
  return (
    <div className={`good-row`}>
      <div className="good-item">
        <Image className="good-img" src={skuImg} preview={false} />
        <div className="good-info">
          <div className="title" onClick={() => handleClickTitle(spuId)}>
            {spuTitle}
          </div>
          <div className="info-row">
            {attributeVOList &&
              attributeVOList.map((unit: any) => (
                <span className="sku-title">
                  {unit.name}：{unit.value}
                </span>
              ))}
          </div>
        </div>
      </div>
      <div className="unit-price">¥{price || 0}</div>
      <div className="unit-price">¥{nowPrice || 0}</div>
      <div className="unit-price">
        <NumberStep
          value={num}
          min={0}
          onChange={(val: any) => {
            let newVal =
              typeof val === 'string' && val.indexOf('-') > -1 ? 0 : val;
            newVal = newVal > 99999 ? 99999 : newVal;
            return (
              propsGoods.handleNumberStep &&
              propsGoods.handleNumberStep(
                newVal,
                propsGoods,
                propsGoods.parentIndex,
                propsGoods.currentIndex,
              )
            );
          }}
        />
        {/* <div className="good-status">已下架</div> */}
      </div>
      <div className="total-price">{fixAmount(num * nowPrice)}</div>
    </div>
  );
};

export default GoodsRow;
