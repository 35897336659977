import { Effect, request, Reducer, Subscription, history } from 'umi';
import { message } from 'antd';

export interface ILocaltionPosition {
  country: string;
  province: string;
  city: string;
  district: string;
  formattedAddress: string;
  lat: number;
  lng: number;
}

export interface CommonModelState {
  localtionPosition: ILocaltionPosition;
}

export interface CommonModelType {
  state: CommonModelState;
  effects: {};
  reducers: {
    save: Reducer<CommonModelState>;
    // 启用 immer 之后
    // save: ImmerReducer<CommonModelState>;
  };
  subscriptions?: { setup: Subscription };
}

const CommonModel: CommonModelType = {
  state: {
    localtionPosition: {
      country: '',
      city: '',
      district: '',
      province: '',
      formattedAddress: '',
      lng: 0,
      lat: 0,
    },
  },
  effects: {},
  reducers: {
    save(state, action) {
      console.log('save', state, action);

      return {
        ...state,
        ...action.payload,
      };
    },
    // 启用 immer 之后
    // save(state, action) {
    //   state.name = action.payload;
    // },
  },
};

export default CommonModel;
