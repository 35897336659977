import { RequestConfig, getDvaApp, history, useHistory } from 'umi';
import { dealAmount } from '@/utils/amount';
import { BASE_URL, BASE_URL_V2 } from '@/config/api';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { message } from 'antd';
import 'normalize.css';
console.log('env', process.env.CURRENT_ENV);
let app = getDvaApp();
import { isMac, getBroswer } from '@/utils';
const broswer = getBroswer();
document.documentElement.className += ` ${isMac ? ' macos' : ''} ${
  broswer.broswer
} ${broswer.broswer + broswer.version}`;
export const request: RequestConfig = {
  timeout: 60 * 1000,
  errorHandler: (error) => {
    throw error;
  },
  middlewares: [
    async function middlewareA(ctx, next) {
      if (!app) app = getDvaApp();
      if (app._store.getState().user.token) {
        const tokenKey = ctx.req.options.store
          ? 'ADMINTOKEN'
          : 'SUP-STORE-TOKEN';
        const token = app._store.getState().user.token;
        ctx.req.options.headers = {
          ...ctx.req.options.headers,
          [tokenKey]: token,
        };
      }
      await next();
    },
  ],
  requestInterceptors: [
    (url, options) => {
      if (options.params) {
        options.params = dealAmount(options.params, 'out');
      }
      if (options.data) {
        options.data = dealAmount(options.data, 'out');
      }
      // 直接输入全部url
      if (/^https?:\/\//.test(url)) {
        options.headers = {};
        return {
          url,
          options,
        };
      }
      if (options.store) {
        return {
          url: BASE_URL + '/api' + url,
          options,
        };
      } else {
        return {
          url: BASE_URL_V2 + '/api' + url,
          options,
        };
      }
    },
  ],
  responseInterceptors: [
    (response, options) => {
      return new Promise((resolve, reject) => {
        response
          .clone()
          .json()
          .then((data) => {
            if (data.errno === 200 || data.code === 200) {
              resolve(dealAmount(data, 'in'));
            } else if (data.errno === 10008) {
              if (process.env.PRM_URL) {
                window.location.href = process.env.PRM_URL;
              }
            } else if (data.errno === 401) {
              history.push('/passport/login');
            } else {
              reject(data);
            }
          });
      });
    },
  ],
};

// export const layout = (initialState) => {
//   return {
//     menuRender: false,
//     headerRender: () => (
//       <>
//         <MainHeader />
//         <SearchBanner />
//       </>
//     ),
//     footerRender: () => <MainFooter />,
//     onPageChange: () => {
//       console.log('initialState', initialState);
//       console.log('history.location.query', history.location.query);
//     }
//   };
// };

const persistConfig = {
  timeout: 1000,
  key: 'root',
  storage,
  whitelist: ['user', 'search'],
};
const persistEnhancer =
  () => (createStore) => (reducer, initialState, enhancer) => {
    const store = createStore(
      persistReducer(persistConfig, reducer),
      initialState,
      enhancer,
    );
    const persist = persistStore(store, null);
    return {
      persist,
      ...store,
    };
  };

interface IError {
  data: any;
  errmsg: string;
  errno: number;
  request: any;
  response: any;
  timestamp: number;
  type: any;
  status: 503 | 400 | 404 | 200 | 500;
}
export const dva = {
  config: {
    extraEnhancers: [persistEnhancer()],
    onError(e: IError) {
      if (e.status === 503 || e.status === 500) {
        message.error('服务器繁忙');
        return;
      }
      if (e.type === 'Timeout') {
        message.error('网络繁忙');
        return;
      }
      // 隐藏 "jwt不能为空" 提示
      if (e.errno === 1013 && process.env.CURRENT_ENV === 'prod') return;
      message.error(e.errmsg);
    },
  },
};
