import React, { useEffect, useState } from 'react';
import './index.less';

interface IAddressSelectProps {
  onChange?: Function;
  onCancel?: Function;
  parentClassName?: string;
  defaultValue: AddressTreeItem[];
  level: number;
  addressTree: AddressTreeItem[];
}
export interface IAddressResult {
  address: string;
  list: AddressTreeItem[];
}
interface AddressTreeItem {
  parentCode: number;
  code: number | string;
  name: string;
  children: AddressTreeItem[];
}

// const addressLib = [province, city, area];
export default function AddressSelect(props: IAddressSelectProps) {
  const [isUserClick, setIsUserClick] = useState(false);
  // 选择的地址信息 [{value: '1', label: '浙江省'}, {value: 2, label: '温州市'}, {value: 3, label: '苍南县'}]
  const [selectedAddressData, setSelectedAddressData] = useState<any>([]);
  // 备选的地址 [['浙江省'], ['杭州市', '宁波市', '温州市'], ['苍南县']]
  const [addressDataList, setAddressDataList] = useState<any>([
    props.addressTree,
    [],
    [],
  ]);
  // 历史选择的索引值 [0, 0, 0]
  const [selectedIndexArray, setSelectedIndexArray] = useState(
    new Array(props.level).fill(-1),
  );
  const [current, setCurrent] = useState(0);
  const handleItemClick = (addressItem: never, index: number) => {
    // 记录用户操作
    setIsUserClick(true);
    if (current === selectedAddressData.length) {
      setSelectedAddressData([...selectedAddressData, addressItem]);
    } else {
      setSelectedAddressData([
        ...selectedAddressData.slice(0, current),
        addressItem,
      ]);
    }
    // 更新当前选择的索引值，例如选择省的索引[1, 0, 0]
    setSelectedIndexArray(
      selectedIndexArray.map((oldValue, i) =>
        i === current ? index : i > current ? -1 : oldValue,
      ),
    );
    let newAddressData = [...addressDataList];
    newAddressData.splice(
      current + 1,
      1,
      addressDataList[current][index].children,
    );
    setAddressDataList(newAddressData);
    // current不应大于level
    if (current + 1 < props.level) {
      setCurrent(current + 1);
    }
  };
  const handleDocumentClick = (e: any) => {
    if (!e || !e.path) {
      return;
    }
    if (
      e.path.some(
        (el) =>
          el.className === 'address-select-container' ||
          el.className === props.parentClassName,
      )
    ) {
      return;
    }

    props.onCancel && props.onCancel();
  };
  /**
   * 通过code获取当前省/市/区信息
   * @param code
   */
  const getCurrentAddreByCode = (
    code: string,
    addressList?: AddressTreeItem[] | any,
  ) => {
    if (!addressList?.length) {
      addressList = props.addressTree;
    }
    const index = addressList.findIndex(
      (item: AddressTreeItem) => item.code == code,
    );
    if (index >= 0) return addressList[index];
    return addressList[0];
  };

  useEffect(() => {
    if (selectedAddressData.length === props.level && isUserClick) {
      props.onChange &&
        props.onChange({
          list: selectedAddressData,
          address: selectedAddressData
            .map((item: AddressTreeItem) => item.name)
            .join(''),
        });
    }
  }, [selectedAddressData]);

  useEffect(() => {
    console.log('props.defaultValue', props.defaultValue);
    if (Array.isArray(props.defaultValue) && props.defaultValue.length) {
      // 缓存初始值
      let selectedIndexArrayTemp = selectedIndexArray.slice();
      let addressDataListTemp = [...addressDataList];
      props.defaultValue.forEach((item: any, x) => {
        if (x < 2) {
          addressDataListTemp[x + 1] = item.children?.length
            ? item.children
            : getCurrentAddreByCode(item.code, addressDataListTemp[x]).children; // 当前选中的省市区列表 编辑时不带children
        }
        const index = addressDataListTemp[x].findIndex(
          (addressItem: any) => item.code == addressItem.code,
        );
        if (index === -1) {
          return;
        }
        selectedIndexArrayTemp[x] = index; // 当前选中的索引值 例如【5，7，2】
      });
      setSelectedIndexArray(selectedIndexArrayTemp);
      setSelectedAddressData(props.defaultValue);
      setCurrent(props.defaultValue.length - 1);
      setAddressDataList(addressDataListTemp);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  return (
    <div className="address-select-container">
      <div className="select-item-container">
        {selectedAddressData.map((item: any, index: number) => (
          <div
            className={`select-item ${current === index && 'selected'}`}
            key={index}
            onClick={() => setCurrent(index)}
          >
            {item.name}
          </div>
        ))}
        {selectedAddressData.length < props.level && (
          <div
            className={`select-item ${
              current === selectedAddressData.length && 'selected'
            }`}
            onClick={() => setCurrent(selectedAddressData.length)}
          >
            请选择
          </div>
        )}
      </div>

      <div className="address-list-container">
        <div className="address-list">
          {addressDataList[current].map((p, index) => (
            <div
              className={`address-item ${
                selectedIndexArray[current] === index && 'selected'
              }`}
              key={p.code}
              onClick={() => handleItemClick(p, index)}
            >
              <p>{p.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
