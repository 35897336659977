import React, { useState, useEffect } from 'react';
import { Image, Rate, Tooltip } from 'antd';
import Icon from '@/components/Icon';
import { useDispatch, history } from 'umi';
import './index.less';
interface IProps {
  tenantId: any;
  getTenantInfo?: (val: ITenantInfo) => void;
}
export interface ITenantInfo {
  /**
   * 经营时间
   */
  time: number;
  /**
   * 公司名字
   */
  companyName: string;
  /**
   * 联系电话
   */
  phone: string;
  /**
   * 经营范围
   */
  businessScope: number;
  /**
   * 企业logo
   */
  enterpriseLogo: string;
  [props: string]: any;
}
function CompanyInfo(props: IProps) {
  const { tenantId = undefined } = props;
  const dispatch = useDispatch();
  const [tenantInfo, setTenantInfo] = useState<ITenantInfo>({} as ITenantInfo);
  // 获取商家信息
  const getTenantInfo = () => {
    dispatch({
      type: 'user/getTenantInfo',
      payload: {
        tenantId,
      },
    }).then((res: any) => {
      const data: ITenantInfo = res.data;
      setTenantInfo(data);
      props.getTenantInfo && props.getTenantInfo(data);
    });
  };
  const handleGoto = (pathname: string, query?: any) => {
    history.push({
      pathname,
      query,
    });
  };
  useEffect(() => {
    if (tenantId) {
      getTenantInfo();
    }
  }, [tenantId]);
  return (
    <div className="company-info">
      <div className="company-container">
        <div className="company">
          <Image
            preview={false}
            src={tenantInfo.enterpriseLogo}
            className="comapny-image"
          />
          <div className="c-info">
            <div
              className="title"
              onClick={() => handleGoto(`/shop/${tenantId}/category`)}
            >
              {tenantInfo.companyName || ''}
            </div>
            <span className="star">2个工作日内发货</span>
            {/* <div className="operating-time">
              <span className="label">经营时间：</span>
              {tenantInfo.time !== null || tenantInfo.time !== undefined ? (
                <span className="value">
                  {Math.floor(tenantInfo.time / 365)}年
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="service-star">
              <span className="star">服务星级：</span>
              <Rate className="rate" disabled allowHalf value={5} />
            </div>
            <div className="info">
              <div className="business">
                <span className="t">主营：</span>
                <span className="f">
                  {tenantInfo.businessScope &&
                  tenantInfo.businessScope.length > 120 ? (
                    <Tooltip
                      title={tenantInfo.businessScope}
                      overlayInnerStyle={{
                        width: 700,
                      }}
                    >
                      <span className="f">{tenantInfo.businessScope}</span>
                    </Tooltip>
                  ) : (
                    tenantInfo.businessScope
                  )}
                </span>
              </div>
              <div className="phone">
                <Icon type="icondianhua" className="phone-icon" />
                <span className="text">{tenantInfo.phone}</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyInfo;
