// 订单-代发单-采购单 订单状态
export const orderStatusMap: any = {
  10: '待付款',
  20: '待发货',
  14: '待付款-转账信息审核中',
  15: '待付款-转账信息失败',
  30: '待收货',
  50: '交易完成',
  60: '退款中',
  70: '交易关闭(已退款)',
  80: '交易关闭(已取消)',
  90: '交易关闭(超时)',
};
// 退款状态
export const refundStatusMap: any = {
  1000: '售后处理中',
  1100: '退款中',
  1150: '退款中-等待平台审核',

  1200: '退款成功',
  1300: '退款失败',
  1400: '商家已拒绝',
  1500: '退款关闭',
};

// 退款类型
export const refundTypeMap: any = {
  0: '仅退款',
  1: '退货退款',
};
// 订单类型
export const orderTypeMap = {
  0: '采购订单',
  1: '代发订单',
};
// 订单来源
export const orderSourceMap = {
  0: '自有小程序',
  1: '门店小程序',
  2: '宠货通批发系统',
};
// 支付方式
export const payChannelMap = {
  WX: '微信',
  WX_NATIVE: '微信',
  ALI: '支付宝',
  ALI_NATIVE: '支付宝',
  POS: 'POS机',
  BALANCE: '余额',
  CASH: '现金',
  COMMISSION: '积分全额抵扣',
  COMBINE: '组合支付',
  UNKNOWN: '其他方式',
  BANKCARD: '银行卡',
  OFFLINE_BANK: '对公转账',
};
export const PURCHASE_ITEM_STATUS = {
  0: '可买',
  1: '缺货',
  2: '下架',
  3: '已删除',
};
export const EFFECT_SCOPE_MAP = [
  {
    label: '宠货通',
    value: 1,
  },
  {
    label: '小程序',
    value: 2,
  },
];
export const EFFECT_GOODS_MAP = [
  {
    label: '全部商品可用',
    value: 0,
  },
  {
    label: '指定商品可用',
    value: 1,
  },
];
export const COUPON_STATUS_MAP = [
  {
    label: '未使用',
    value: 0,
  },
  {
    label: '已使用',
    value: 1,
  },
  {
    label: '已过期',
    value: 2,
  },
];

export const REFUND_STATUS_MAP: any = {
  1: '不想买了',
  2: '拍错',
  3: '卖家缺货',
  4: '卖家未按约定时间发货',
  5: '卖家调价',
  6: '退运费',
  7: '其他',
};
