import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Spin, Empty, Select } from 'antd';
import ShippingAddress from '@/components/Goods/ShippingAddress';
import {
  connect,
  GoodsModelState,
  GoodsSkuDetail,
  Loading,
  Dispatch,
  history,
} from 'umi';
import HotGoodsList from './components/HotGoodsList';
import StoreCategoryList from './components/StoreCategoryList';
import ActivityCon from '@/components/Goods/Activity';
import CouponCon from '@/components/Goods/Coupon';
import RecommendGoods from './components/RecommendGoods';
import Breadcrumb from '@/components/Breadcrumb';
import GoodsDetailSwiper from '@/components/Goods/DetailSwiper';
import GoodsRadio from '@/components/Goods/Radio';
import DistributionModal from '@/components/Goods/Distribution';
import SKUSelector from '@/components/Goods/SKUSelector';
import SKUSelectorNew from '@/components/Goods/SKUSelectorNew';
import CompanyInfo from '@/components/Goods/CompanyInfo';
import Icon from '@/components/Icon';
import chenggongIcon from '@/assets/common/chenggong.svg';
import { ITenantInfo } from '@/components/Goods/CompanyInfo';
import jhdtbIcon from '@/assets/goods/jhdtb.svg';
import './Detail.less';
interface IProps {
  goods: GoodsModelState;
  loading: Loading;
  dispatch: Dispatch;
}

const suitObjectMapEnum: any = {
  CAT: '宠物猫',
  DOG: '宠物狗',
  OTHER: '其它',
};

function Detail(props: IProps) {
  const [isShowAppToListSuccess, setIsShowAppToListSuccess] = useState(false);
  const [saleTypeIndex, setSaleTypeIndex] = useState(0);
  const [isShowCartAnimate, setIsShowCartAnimate] = useState(false);
  const [isShowDistSuccess, setIsShowDistSuccess] = useState(false);
  const [isShowDistr, setIsShowDistr] = useState(false);
  const [freightDetail, setFreightDetail] = useState({});
  const [num, setNum] = useState<number>(1);
  const [currentSku, setCurrentSku] = useState<GoodsSkuDetail>({
    minPrice: '--',
    cooperationPrice: '--',
    spotPrice: '--',
    costPrice: '--',
    retailPrice: '--',
    issuingPrice: '--',
    suggestedPrice: '--',
    stock: '--',
    list: [],
    img: '',
    miniNum: '--',
    purchaseGiftVO: {},
  });
  const canBuy = useMemo(() => currentSku.stock > 0, [currentSku]);
  const [tenantInfo, setTenantInfo] = useState<ITenantInfo>({} as ITenantInfo);
  const [couponList, setCouponList] = useState<any[]>([]);
  const [saleTypeList, setSaleTypeList] = useState([
    {
      label: '直采',
      value: 0,
    },
  ]);

  const onSkuChange = (sku: GoodsSkuDetail | undefined, num: number) => {
    if (sku) {
      setCurrentSku({
        ...sku,
        miniNum: props.goods.detail.miniNum,
      });
    }
    setNum(num);
  };
  const onSaleTypeChange = (index: number) => {
    setSaleTypeIndex(index);
  };
  const onDistSuccess = () => {
    setIsShowDistSuccess(true);
    setIsShowDistr(false);
  };
  /**
   * 加入进货单
   */
  const handleAddPurchaseList = () => {
    props
      .dispatch({
        type: 'order/addPurchaseList',
        payload: {
          num,
          skuId: currentSku.id,
        },
      })
      .then((data: any) => {
        if (data) {
          setIsShowAppToListSuccess(true);
          setIsShowCartAnimate(true);
          setTimeout(() => {
            setIsShowCartAnimate(false);
          }, 1000);
        }
      });
  };

  const handleCart = () => {
    history.push('/order/shopping');
  };
  /**
   * 铺货
   */
  const handleDistr = () => {
    setIsShowDistr(true);
  };
  const handleBuy = () => {
    history.push(`/order/confirm?skuId=${currentSku.id}&num=${num}`);
  };
  const mainImageList = useMemo(() => {
    let res = [];
    if (props.goods.detail.video) {
      res.push({
        type: 'video',
        src: props.goods.detail.video,
        img:
          props.goods.detail.video +
          '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast',
      });
    }
    if (props.goods.detail.img) {
      res.push({
        type: 'image',
        img: props.goods.detail.img,
      });
    }
    if (Array.isArray(props.goods.detail.imgs)) {
      res = res.concat(
        props.goods.detail.imgs.map((item) => {
          return {
            type: 'image',
            img: item,
          };
        }),
      );
    }
    return res;
  }, [props.goods.detail.img]);
  // 获取优惠券
  const getDetailCoupon = () => {
    if (!props.goods.detail.id) return;
    props
      .dispatch({
        type: 'goods/couponListHasBySpuId',
        payload: props.goods.detail.id,
      })
      .then((data: any) => {
        setCouponList(data || []);
      });
  };
  // 获取优惠券
  const goodsDetailCouponList = () => {
    if (!props.goods.detail.id) return;
    props
      .dispatch({
        type: 'goods/goodsDetailCouponList',
        payload: props.goods.detail.id,
      })
      .then((data: any) => {
        setCouponList(data || []);
      });
  };
  useEffect(() => {
    if (props.goods.detail.isConsignment) {
      if (saleTypeList.length === 1) {
        setSaleTypeList(
          saleTypeList.concat([
            {
              label: '代发',
              value: 1,
            },
          ]),
        );
      }
    } else if (saleTypeList.length === 2) {
      setSaleTypeList(saleTypeList.slice(0, 1));
    }
    goodsDetailCouponList();
  }, [props.goods.detail]);
  const getTenantInfo = (tenantInfo: ITenantInfo) => {
    setTenantInfo(tenantInfo);
  };
  const getFreightDetail = (val: any) => {
    setFreightDetail({ ...val });
  };

  const openCouponModal = () => {
    props.dispatch({
      type: 'goods/changeSideModal',
      payload: {
        showSiderModal: true,
        selectSiderIndex: 2,
      },
    });
  };
  return (
    <>
      <CompanyInfo
        tenantId={props.goods.detail.tenantId}
        getTenantInfo={getTenantInfo}
      />
      <Breadcrumb />
      {/* <CouponCon spuId={props.goods.detail.id} /> */}
      <Spin spinning={props.loading.effects['goods/detail']}>
        <div className="goods-detail-container">
          <div className="section goods-info-section">
            {/* <div className="recommend-container">
              <RecommendGoods
                categoryId={props.goods.detail && props.goods.detail.categoryId}
              />
            </div> */}
            <div className="l">
              <GoodsDetailSwiper list={mainImageList} />
            </div>
            <div className="r">
              <h1 className="title" title={props.goods.detail.title}>
                {props.goods.detail.title}
              </h1>
              {/* <div className="radio-con">
                <GoodsRadio list={saleTypeList} onChange={onSaleTypeChange} />
              </div> */}
              <div className="price-con">
                <div className="items">
                  <div className="item">
                    <div className="title">价格</div>
                    <div className="price">
                      <span className="rmb">¥ </span>
                      {currentSku.minPrice || currentSku.cooperationPrice}
                    </div>
                    {/* <div className="interval">~</div>
                    <div className="price">
                      <span className="rmb">¥ </span>
                      {currentSku.retailPrice}
                    </div> */}
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <div className="title">起批量</div>
                    <div className="title">
                      {props.goods.detail.miniNum}
                      {props.goods.detail.unit}起批
                    </div>
                  </div>
                </div>
                {props.goods.detail?.purchaseGiftVO?.activityName && (
                  <div className="items">
                    <div className="item">
                      <div className="title" style={{ marginRight: 24 }}>
                        优惠
                      </div>
                    </div>
                    <div className="coupon-list">
                      <div className="conpon-item">满赠</div>
                      <div className="conpon-more" style={{ marginLeft: 0 }}>
                        满{props.goods.detail?.purchaseGiftVO?.fullNum}赠
                        {props.goods.detail?.purchaseGiftVO?.presentNum}
                        ，赠完即止
                      </div>
                    </div>
                  </div>
                )}

                {couponList.length > 0 && (
                  <div className="items">
                    <div className="item">
                      <div className="title" style={{ marginRight: 24 }}>
                        优惠券
                      </div>
                    </div>
                    <div className="coupon-list">
                      {couponList.map(
                        (couponItem: any, index: number) =>
                          index < 3 && (
                            <div
                              className="conpon-item"
                              onClick={openCouponModal}
                            >
                              满{couponItem.min}-{couponItem.discount}
                            </div>
                          ),
                      )}
                      {couponList.length > 3 && (
                        <div className="conpon-more" onClick={openCouponModal}>
                          更多
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="items address-con">
                  <ShippingAddress
                    skuId={currentSku.id}
                    getFreightDetail={getFreightDetail}
                  />
                </div>
              </div>

              {Array.isArray(props.goods.detail.salesPromotion) &&
                props.goods.detail.salesPromotion.length > 0 && (
                  <div className="coupon-box">
                    <div className="title">优惠：</div>
                    <div className="left-tags">
                      {props.goods.detail.salesPromotion.map(
                        (item: any, idx: number) => {
                          return (
                            <div key={item} className="tag-wrap">
                              <div key={item} className="tag-style">
                                {item}
                              </div>
                              {idx ===
                                props.goods.detail.salesPromotion.length - 1 &&
                                Array.isArray(props.goods.detail.giveInfos) &&
                                props.goods.detail.giveInfos.length > 0 &&
                                props.goods.detail.giveInfos.map(
                                  (item: any, index: number) => {
                                    return (
                                      <div
                                        key={`${item.skuId}${index}`}
                                        className="gift-box"
                                      >
                                        <div className="gift-tit">
                                          {item.title}
                                        </div>
                                        {/* <div>X{item.presentNum}</div> */}
                                      </div>
                                    );
                                  },
                                )}
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                )}

              <Spin spinning={props.loading.effects['goods/skuDetail']}>
                <div className="sku-con-new">
                  {/* <SKUSelectorNew skuDetail={props.goods.skuDetail} /> */}
                  <SKUSelector
                    titles={props.goods.skuDetail.skuSplicings}
                    skus={props.goods.skuDetail.skuDetails}
                    stock={currentSku.stock}
                    miniNum={currentSku.miniNum}
                    onChange={onSkuChange}
                  />
                </div>
              </Spin>
              <div className="handle-con">
                {props.goods.detail.status === 0 ? (
                  <Button ghost type="primary" className="btn" disabled={true}>
                    已下架
                  </Button>
                ) : saleTypeIndex === 0 ? (
                  <>
                    <Button
                      type="primary"
                      className="btn"
                      disabled={!canBuy || !freightDetail.sendToFlag}
                      onClick={handleBuy}
                    >
                      立即购买
                    </Button>
                    <Button
                      ghost
                      type="primary"
                      className="btn ghost"
                      disabled={!canBuy || !freightDetail.sendToFlag}
                      loading={props.loading.effects['order/addPurchaseList']}
                      onClick={handleAddPurchaseList}
                    >
                      加入进货单
                    </Button>
                  </>
                ) : (
                  <Button type="primary" className="btn" onClick={handleDistr}>
                    一键铺货
                  </Button>
                )}
                <div
                  className={`cart-icon-con ${isShowCartAnimate && 'animate'}`}
                >
                  <img className="cart-icon" src={jhdtbIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="section section-bottom">
            {/* <div className="l">
              <StoreCategoryList
                isShowAll={false}
                companyName={tenantInfo.companyName}
                tenantId={props.goods.detail.tenantId}
                handleChildClick={(childItem: any) => {
                  history.push({
                    pathname: `/shop/${props.goods.detail.tenantId}/category`,
                    query: {
                      categoryId: childItem.id,
                    },
                  });
                }}
              />
        
            </div> */}
            <div className="r">
              <div className="title">
                <Icon type="iconshangpinxiangqing" className="t-icon" />
                <span>详细信息</span>
              </div>
              <div className="infos">
                {/* <div className="item">
                  <p className="item-title">
                    <span>货</span>
                    <span>号</span>
                  </p>
                  <p className="item-value">{props.goods.detail.brandName}</p>
                </div> */}
                <div className="item">
                  <p className="item-title">
                    <span>单</span>
                    <span>位</span>
                  </p>
                  <p className="item-value">{props.goods.detail.unit}</p>
                </div>
                <div className="item center">
                  <p className="item-title">
                    <span>品</span>
                    <span>牌</span>
                  </p>
                  <p className="item-value">{props.goods.detail.brandName}</p>
                </div>
                <div className="item right">
                  <p className="item-title">适用对象</p>
                  <p className="item-value">
                    {suitObjectMapEnum[props.goods.detail.suitObject]}
                  </p>
                </div>
              </div>
              <div className="detail-images">
                {props.goods.detail.descriptions ? (
                  props.goods.detail.descriptions.map((item, index) => (
                    <img
                      className="detail-image"
                      src={item}
                      key={index}
                      alt=""
                    />
                  ))
                ) : (
                  <Empty
                    className="custom"
                    imageStyle={{ marginTop: 137, height: 172 }}
                    image={require('@/assets/common/empty.png')}
                    description="暂无详情图片"
                  />
                )}
              </div>
            </div>
          </div>
          <Modal
            title={null}
            footer={null}
            visible={isShowAppToListSuccess}
            onCancel={() => setIsShowAppToListSuccess(false)}
            className="add-success-modal"
            centered
          >
            <div className="add-success-body">
              <img src={chenggongIcon} alt="" className="success-icon" />
              <div className="content">
                <h1>已将货品添加到进货单！</h1>
                <p>多个货品添加完毕后您可以在右上方进货单进行结算</p>
              </div>
              <div className="checkout-icon" onClick={handleCart}>
                去进货单结算
              </div>
            </div>
          </Modal>
          <Modal
            title={null}
            footer={null}
            visible={isShowDistSuccess}
            onCancel={() => setIsShowDistSuccess(false)}
            className="add-success-modal"
            centered
          >
            <div className="add-success-body">
              <img src={chenggongIcon} alt="" className="success-icon" />
              <div className="content">
                <h1>已将货品添加到门店！</h1>
                <p>
                  商品将推送至您的门店管理系统-
                  <span className="primary-color">商品管理</span>中
                </p>
              </div>
              <div
                className="checkout-icon"
                onClick={() => setIsShowDistSuccess(false)}
              >
                确定
              </div>
            </div>
          </Modal>
          <DistributionModal
            visible={isShowDistr}
            spuId={props.goods.goodsId}
            onSuccess={onDistSuccess}
            onCancel={() => setIsShowDistr(false)}
          />
        </div>
      </Spin>
    </>
  );
}

export default connect(
  ({ goods, loading }: { goods: GoodsModelState; loading: Loading }) => ({
    goods,
    loading,
  }),
)(Detail);
