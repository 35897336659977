import React, { useEffect, useState } from 'react';
import { Table, Spin, Button, Checkbox, Modal, message, Empty } from 'antd';
import './Footer.less';
import { fixAmount } from '@/utils';

interface IProps {
  onChange?: (bool: boolean) => void;
  handleSubmit?: () => void;
  handleAddPurchaseList?: () => void;
  selectedRowKeys?: any[];
  [props: string]: any;
}
function Footer(props: IProps) {
  const { list = [] } = props;
  const [allNum, setAllNum] = useState<number>(0);
  const [allPrice, setAllPrice] = useState<number>(0);
  const handleSubmit = () => {
    props.handleSubmit && props.handleSubmit();
  };

  useEffect(() => {
    console.log('list', list);
    const skuTemp = list.map((row: any) => row.frequentListVOList).flat(2);
    let count = 0;
    skuTemp.map((item: any) => {
      if (item.num > 0) {
        count++;
      }
    });
    const allPrice = skuTemp.reduce((prev: number, item: any) => {
      return fixAmount(prev + item.num * item.nowPrice);
    }, 0);

    setAllPrice(allPrice);
    setAllNum(count);
  }, [list]);
  return (
    <div className="shopping-footer">
      <div className="col"></div>
      <div className="col">
        已选商品 <span className="goods-num">{allNum}</span> 件，货品总金额：
        <span className="goods-total">¥{allPrice}</span>
      </div>
      <div className="col space">
        {/* <Button type="primary" className="btn" onClick={handleSubmit}>
          立即购买
        </Button> */}
        <span></span>
        <Button
          type="primary"
          className="btn"
          disabled={allNum === 0}
          loading={props.purchaseLoading || false}
          onClick={props.handleAddPurchaseList}
          ghost
        >
          加入进货单
        </Button>
      </div>
    </div>
  );
}

export default Footer;
