import React, { useEffect } from 'react';
import './index.less';
interface IProps {
  id: number;
  /**
   * 商品图片
   */
  imgSrc: string;
  /**
   * 商品名称
   */
  title: string;
  /**
   * 商品销售量
   */
  sales: string;
  /**
   * 商品价格
   */
  price: string;
  onClick: (val: any) => void;
  [props: string]: any;
}
function GoodItem(props: IProps) {
  const { sales, price, title, imgSrc } = props;
  return (
    <div className="host-good-item" onClick={() => props.onClick(props)}>
      <img src={imgSrc} className="g-img" />
      <div className="g-title">{title}</div>
      <div className="g-pirce-sales">
        <p className="price">
          <span className="rmb">¥ </span>
          <span>{price}</span>
        </p>
        <span className="sales">已售 {sales}</span>
      </div>
    </div>
  );
}

export default GoodItem;
