import React from 'react';
import Icon from '@/components/Icon';

import './index.less';
interface IProps {
  type?: number;
}
export default function Index(props: IProps) {
  return (
    <div className={`sort-arrow-component type-${props.type}`}>
      <Icon className="icon icon1" type="iconTriangle"></Icon>
      <Icon className="icon icon2" type="iconTriangle"></Icon>
    </div>
  );
}
