// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/home/data/jenkins/workspace/petfafa-supplier-mall-web/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/passport/login",
    "title": "登录",
    "component": require('@/pages/Passport/Login').default,
    "exact": true
  },
  {
    "path": "/",
    "title": "首页",
    "component": require('/home/data/jenkins/workspace/petfafa-supplier-mall-web/src/layouts/BasicLayout').default,
    "routes": [
      {
        "path": "/",
        "component": require('@/pages/Home/index').default,
        "title": "首页",
        "breadcrumb": "首页",
        "exact": true
      },
      {
        "path": "/home/activity",
        "component": require('@/pages/Home/Activity').default,
        "title": "满减活动页",
        "breadcrumb": "满减活动页",
        "exact": true
      },
      {
        "path": "/home/recommend",
        "component": require('@/pages/Home/Recommend').default,
        "title": "爆款页",
        "breadcrumb": "爆款页",
        "exact": true
      },
      {
        "path": "/notice/:id",
        "component": require('@/pages/Notice/detail').default,
        "title": "公告详情",
        "breadcrumb": "公告详情",
        "exact": true
      },
      {
        "path": "/shop/:tenantId/category",
        "component": require('@/pages/Shop/Category').default,
        "title": "店铺分类",
        "breadcrumb": "店铺分类",
        "exact": true
      },
      {
        "path": "/goods/detail/:id",
        "component": require('@/pages/Goods/Detail').default,
        "title": "商品详情",
        "breadcrumb": "商品详情",
        "exact": true
      },
      {
        "path": "/order/shopping",
        "component": require('@/pages/Order/Shopping').default,
        "title": "进货单",
        "exact": true
      },
      {
        "path": "/order/confirm",
        "component": require('@/pages/Order/Confirm').default,
        "title": "提交订单",
        "exact": true
      },
      {
        "path": "/order/pay/:id",
        "component": require('@/pages/Order/Pay').default,
        "title": "订单支付",
        "exact": true
      },
      {
        "path": "/order/pay/result/:id",
        "component": require('@/pages/Order/PayResult').default,
        "title": "订单支付",
        "exact": true
      },
      {
        "path": "/search/shop",
        "component": require('@/pages/Search/Shop').default,
        "title": "搜索结果",
        "exact": true
      },
      {
        "path": "/search/more",
        "component": require('@/pages/Search/More').default,
        "title": "更多好货",
        "exact": true
      },
      {
        "path": "/user/order/refund/detail",
        "component": require('@/pages/User/Refund/RefundDetail').default,
        "title": "退款详情",
        "exact": true
      },
      {
        "path": "/user",
        "redirectPath": "/user/purchase",
        "component": require('@/pages/User').default,
        "title": "订单管理",
        "routes": [
          {
            "path": "/user/purchase",
            "component": require('@/pages/User/Order/Purchase').default,
            "exact": true
          },
          {
            "path": "/user/address",
            "component": require('@/pages/User/Address').default,
            "exact": true
          },
          {
            "path": "/user/refund",
            "component": require('@/pages/User/Refund').default,
            "exact": true
          },
          {
            "path": "/user/coupon",
            "component": require('@/pages/User/Coupon').default,
            "exact": true
          },
          {
            "path": "/user/fastReplenishment",
            "component": require('@/pages/User/Order/FastReplenis').default,
            "title": "快速补货",
            "exact": true
          },
          {
            "path": "/user/order/applyRefund",
            "exact": true,
            "component": require('@/pages/User/Order/ApplyRefund').default,
            "title": "申请退款"
          },
          {
            "path": "/user/order/detail",
            "exact": true,
            "component": require('@/pages/User/Order/Detail').default,
            "title": "订单详情"
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
