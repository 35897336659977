import { Effect, request, Reducer, Subscription, history } from 'umi';
import { message } from 'antd';
import appConfig from '@/config';

export interface IAddressItem {
  address: string;
  areaCode: number;
  city: string;
  cityCode: number;
  consignee: string;
  county: string;
  countyCode: number;
  defaultAddress: boolean;
  id: number;
  phone: string;
  postCode: string;
  province: string;
  provinceCode: number;
}
export interface IUser {
  appointBonus?: number;
  avatarUrl?: string;
  boardBonus?: number;
  expired?: boolean;
  gmtCreate?: number;
  gmtLastLogin?: number;
  gmtUpdate?: number;
  goodsBonus?: number;
  id?: number;
  isSuperAdmin?: 0 | 1;
  lastLoginIp?: string;
  loginPermissions?: string[];
  password?: string | null;
  perms?: string[];
  phone?: string;
  purchase?: boolean;
  realname?: string;
  tenantIds?: number[];
  rolesStr?: string | null;
  status?: 0 | 1;
  tenantId?: number;
  tenantName?: string | null;
  trialVersion?: boolean;
  username?: string;
  legalPerson?: string;
}
export interface UserModelState {
  token: string;
  tokenName: string;
  storeId: number;
  platformId: number;
  hasAuth: boolean;
  user: IUser;
  addressList: IAddressItem[];
}

export interface IndexModelType {
  state: UserModelState;
  effects: {
    userInfo: Effect;
    sendCode: Effect;
    login: Effect;
    addressList: Effect;
    addressMap: Effect;
    addressCreate: Effect;
    addressDelete: Effect;
    getSupplierInfo: Effect;
    prmStoreLogin: Effect;
    getTenantInfo: Effect;
    tenantList: Effect;
    loginPwd: Effect;
    loginReset: Effect;
    logisticsQuery: Effect;
    addressTreeApi: Effect;
  };
  reducers: {
    save: Reducer<UserModelState>;
    // 启用 immer 之后
    // save: ImmerReducer<UserModelState>;
  };
  subscriptions: { setup: Subscription };
}

const UserModel: IndexModelType = {
  state: {
    token: '',
    tokenName: '',
    storeId: -1,
    platformId: -1,
    user: {},
    addressList: [],
  },

  effects: {
    *userInfo({ payload }, { call, put, select }) {
      const token = yield select((state) => state.user.token);
      if (!token) {
        return;
      }
      const res = yield call(
        request,
        '/supplier-user-center/purchase/user/info',
        {
          method: 'get',
          // params: payload,
          store: true,
        },
        { loading: true },
      );
      if (res.data) {
        yield put({
          type: 'save',
          payload: {
            user: res.data,
          },
        });
      }
      // yield put({
      //   type: 'save',
      //   payload: {
      //     user: {
      //       username: 'abc',
      //     },
      //   },
      // });
    },
    *sendCode({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/sendCode',
        {
          method: 'get',
          params: {
            ...payload,
          },
        },
        { loading: true },
      );
      return res.data;
    },
    *login({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/login',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      return res.data;
    },
    *loginPwd({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/pwd/login',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      return res.data;
    },
    *loginReset({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/pwd/reset',
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      return res.data;
    },
    *addressList({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/address/list',
        {
          method: 'get',
          params: {
            item: payload,
            pageNo: 1,
            pageSize: 999,
          },
        },
        { loading: true },
      );
      if (res.data) {
        yield put({ type: 'save', payload: { addressList: res.data.items } });
      }
      return res.data;
    },
    *addressCreate({ payload }, { call, put, select }) {
      const isEdit = typeof payload.id === 'number';
      const postName = isEdit ? 'update' : 'create';
      const res = yield call(
        request,
        '/supplier-user-center/purchase/address/' + postName,
        {
          method: 'post',
          data: { ...payload },
        },
        { loading: true },
      );
      if (res.data) {
        message.success(
          payload?.id || payload?.id === 0 ? '修改成功' : '添加成功',
        );
        yield put({ type: 'addressList' });
      }
      return res.data;
    },
    *addressMap({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/system/city',
        {
          method: 'get',
          params: { ...payload },
        },
        { loading: true },
      );
      return res.data;
    },
    *getSupplierInfo({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/address/getSupplierInfo',
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *addressDelete({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/address/delete/' + payload.id,
        {
          method: 'DELETE',
        },
        { loading: true },
      );
      if (res.data) {
        message.success('删除成功');
        yield put({ type: 'addressList' });
      }
      return res.data;
    },
    /**
     * 门店token转集采token登录
     * @param param0
     * @param param1
     * @returns
     */
    *prmStoreLogin({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/prm/store/login',
        {
          method: 'post',
          headers: {
            'SUP-STORE-TOKEN': payload.token,
          },
        },
        { loading: true },
      );
      if (res.data) {
        const data = res.data;
        yield put({
          type: 'save',
          payload: {
            token: data.TOKEN,
            tokenName: data.tokenName,
            platformId: data.platformId,
            storeId: data.storeId,
          },
        });
      }
      return res.data;
    },
    // 商家信息
    *getTenantInfo({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/tenant/info',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res;
    },
    // 商家信息
    *tenantList({ payload }, { call, put, select }) {
      const res = yield call(
        request,
        '/supplier-user-center/purchase/tenant/list',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res;
    },
    // 物流公司
    *logisticsQuery({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-user-center/prm/logistics/list`,
        {
          method: 'post',
          data: {
            ...payload,
          },
        },
        { loading: true },
      );
      return res;
    },
    // 省市区列表 地址树
    *addressTreeApi({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-user-center/prm/city/list`,
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res;
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    // 启用 immer 之后
    // save(state, action) {
    //   state.name = action.payload;
    // },
  },
};

export default UserModel;
