import React from 'react';
import { Table, Spin, Button, Checkbox, Modal, message, Empty } from 'antd';
import './Thead.less';
interface IProps {
  checked?: boolean;
  onChange?: (bool: boolean) => void;
  showDataFlag?: boolean;
  columns?: any[];
}
function Thead(props: IProps) {
  const {
    columns = [
      '货品信息',
      '上次购买单价（元）',
      '当前金额（元）',
      '数量',
      '小计（元）',
    ],
  } = props;
  return (
    <div className="shopping-thead">
      {columns &&
        columns.map((column: any, index: number) => (
          <div className={`col ${index === 0 ? 'tleft' : ''}`} key={index}>
            {column}
          </div>
        ))}
    </div>
  );
}

export default Thead;
