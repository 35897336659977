import { history } from 'umi';
import './index.less';

interface GoodsItemProps {
  data: any;
}

function GoodsItem(props: GoodsItemProps) {
  const { spuId, title, imgSrc, price, salesPromotion } = props.data;

  const handleDetail = () => {
    history.push(`/goods/detail/${spuId}`);
  };

  return (
    <div className="goods-box" onClick={handleDetail}>
      <img className="goods-image" src={imgSrc} alt="" />
      <div className="goods-title">{title}</div>
      <div className="goods-price">￥{price}</div>
      {Array.isArray(salesPromotion) &&
        salesPromotion.length > 0 &&
        salesPromotion.map((item: any) => {
          return (
            <div key={item} className="goods-color margin tag">
              {item}
            </div>
          );
        })}
    </div>
  );
}

export default GoodsItem;
