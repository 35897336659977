import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Space, Empty } from 'antd';
import AddressCreaterModal from '@/components/Modal/AddressCreater';
import { PlusOutlined } from '@ant-design/icons';
import { connect, Loading, UserModelState, Dispatch, IAddressItem } from 'umi';
import tishiIcon from '@/assets/common/tishi.svg';
import './index.less';
const { Column } = Table;
const { confirm } = Modal;
interface IUserAddressProps {
  loading: Loading;
  user: UserModelState;
  dispatch: Dispatch;
}
function UserAddress(props: IUserAddressProps) {
  const [initialValues, setInitialValues] = useState({});
  const [isShowAddressCreater, setIsShowAddressCreater] = useState(false);
  const handleCreate = () => {
    setInitialValues({});
    setIsShowAddressCreater(true);
  };
  const handleUpdate = (record: any) => {
    setInitialValues(record);
    setIsShowAddressCreater(true);
  };
  const handleDelete = (id: number) => {
    confirm({
      content: '确认删除该地址？',
      className: 'custom-2',
      icon: <img className="icon" src={tishiIcon} />,
      onOk() {
        return props.dispatch({
          type: 'user/addressDelete',
          payload: {
            id,
          },
        });
      },
    });
  };
  const handleDefaultAddress = (detail: IAddressItem) => {
    props.dispatch({
      type: 'user/addressCreate',
      payload: {
        ...detail,
        defaultAddress: true,
      },
    });
  };
  useEffect(() => {
    props.dispatch({
      type: 'user/addressList',
    });
  }, []);
  return (
    <div className="user-address-container">
      <div className="title-con">收货地址</div>
      {props.user.addressList.length > 0 &&
        props.user.addressList.length <= 25 && (
          <div className="handle-con">
            <Button
              className="btn"
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreate}
            >
              添加新地址
            </Button>
            <span className="tip">
              您已创建
              <span className="tip-len">
                {props.user.addressList.length || 0}
              </span>
              个收货地址，最多可创建
              <span className="tip-len">25</span>个
            </span>
          </div>
        )}
      <div className="table-con">
        {(props.user.addressList && props.user.addressList.length > 0) ||
        props.loading.effects['user/addressList'] ? (
          <Table
            rowKey="id"
            bordered
            loading={
              props.loading.effects['user/addressList'] ||
              props.loading.effects['user/addressDelete']
            }
            dataSource={props.user.addressList}
          >
            <Column title="收货人" dataIndex="consignee" width={100}></Column>
            <Column title="手机号" dataIndex="phone" width={110}></Column>
            <Column
              title="所在地区"
              width={160}
              dataIndex="region"
              render={(text, record: IAddressItem) =>
                record.province + record.city + record.county
              }
            ></Column>
            <Column title="详细地址" dataIndex="address"></Column>

            <Column
              title="操作"
              width={220}
              dataIndex="id"
              render={(id, record: IAddressItem) => (
                <Space>
                  {record.defaultAddress ? (
                    <p className="default-tip">
                      <span className="dot"></span>默认地址
                    </p>
                  ) : (
                    <Button onClick={() => handleDefaultAddress(record)}>
                      设为默认
                    </Button>
                  )}
                  <Button onClick={() => handleUpdate(record)}>修改</Button>
                  <Button onClick={() => handleDelete(id)}>删除</Button>
                </Space>
              )}
            ></Column>
          </Table>
        ) : (
          <>
            <Empty
              className="custom"
              imageStyle={{ marginTop: 81, height: 207 }}
              style={{ marginBottom: 121 }}
              image={require('@/assets/common/empty.png')}
              description={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleCreate}
                  style={{
                    marginTop: 10,
                    width: 176,
                    height: 42,
                    borderRadius: 21,
                  }}
                >
                  添加地址
                </Button>
              }
            />
          </>
        )}
      </div>
      <AddressCreaterModal
        visible={isShowAddressCreater}
        initialValues={initialValues}
        onCancel={() => setIsShowAddressCreater(false)}
      />
    </div>
  );
}

export default connect(
  ({ user, loading }: { user: UserModelState; loading: Loading }) => ({
    user,
    loading,
  }),
)(UserAddress);
