import React, { useEffect, useState } from 'react';
import { connect, GoodsModelState, Loading, Dispatch } from 'umi';
import { Cascader, CascaderProps, message } from 'antd';

interface IProps extends CascaderProps {
  goods: GoodsModelState;
  loading: Loading;
  dispatch: Dispatch;
}
function CategoryCascader(props: IProps) {
  const [options, setOptions] = useState([]);
  const onChange = (values: number[]) => {
    if (values.length === 2) {
      props.onChange && props.onChange(values);
    } else {
      message.warning('请选择二级类目');
    }
  };
  useEffect(() => {
    props
      .dispatch({
        type: 'goods/categorySecondLevelTree',
      })
      .then((data: any) => {
        setOptions(data);
      });
  }, []);
  return (
    <Cascader
      value={props.value}
      disabled={props.disabled}
      onChange={onChange}
      placeholder={props.placeholder}
      expandTrigger={props.expandTrigger}
      options={options}
    ></Cascader>
  );
}
export default connect(
  ({ goods, loading }: { goods: GoodsModelState; loading: Loading }) => ({
    goods,
    loading,
  }),
)(CategoryCascader);
