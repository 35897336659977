import React, { useEffect, useState } from 'react';
import './index.less';
import { Image, Popover, Avatar } from 'antd';
import { Link, connect, UserModelState, history } from 'umi';
import Icon from '@/components/Icon';
import MSelect from './MSelect';
import head from '@/assets/common/head.png';
const menus = [
  {
    title: '采购订单',
    pathname: '/user/purchase',
    query: {},
  },
  {
    title: '代发订单',
    pathname: '/user/replace',
    query: {},
  },
  {
    title: '收货地址',
    pathname: '/user/address',
    query: {},
  },
  {
    title: '退款管理',
    pathname: '/user/refund',
    query: {},
  },
  {
    title: '优惠券管理',
    pathname: '/user/coupon',
    query: {},
  },
];
// const menus2 = [
//   {
//     title: '退款管理',
//     pathname: '/user/refund',
//     query: {},
//   },
// ];
const men = [
  {
    title: '订单管理 ',
    item: [
      {
        title: '直采订单',
        pathname: '/user/purchase',
        query: {},
      },
      // {
      //   title: '快速补货',
      //   pathname: '/user/fastReplenishment',
      //   query: {},
      // },
    ],
  },
  {
    title: '售后管理 ',
    item: [
      {
        title: '退货/退货退款',
        pathname: '/user/refund',
        query: {},
      },
    ],
  },
  // {
  //   title: '权益管理',
  //   item: [
  //     {
  //       title: '优惠券',
  //       pathname: '/user/coupon',
  //       query: {},
  //     },
  //   ],
  // },
  {
    title: '更多管理',
    item: [
      {
        title: '收货地址',
        pathname: '/user/address',
        query: {},
      },
    ],
  },
];
interface IProps {
  user: UserModelState;
  [porps: string]: any;
}
interface ISupplierInfo {
  contactMobile: string;
}
function MainHeader(props: IProps) {
  const [supplierInfo, setSupplierInfo] = useState<ISupplierInfo>();
  const [userInfo, setUserInfo] = useState<any>({
    user: props.user.user,
  });
  const returnmrc = () => {
    if (process.env.PRM_URL) {
      window.location.href = process.env.PRM_URL;
    }
  };

  const getSupplierInfo = () => {
    props
      .dispatch({
        type: 'user/getSupplierInfo',
      })
      .then((res: any) => {
        if (res && res.length > 0) {
          setSupplierInfo(res[0]);
        }
      });
  };
  const goto = (pathname: string, query?: any) => {
    history.push({
      pathname,
      query,
    });
  };
  const handleLogout = () => {
    window.localStorage.removeItem('persist:root');
    window.location.reload();
  };
  useEffect(() => {
    // getSupplierInfo();
    console.log(userInfo);
  }, []);
  return (
    <>
      <div className="main-header">
        <div className="header-body">
          <div className="m-h-l">
            <span className="title mr30">
              您好！欢迎来到{' '}
              <span style={{ color: '#0277FF', fontWeight: 500 }}>
                宠货通批发服务平台
              </span>{' '}
              <a
                href="javascript:;"
                onClick={handleLogout}
                style={{ marginLeft: 12 }}
              >
                退出登录
              </a>
            </span>
            <li className="m-h-contactInfo">
              {/* <Popover
                content={
                  // 图片待设置
                  <img
                    className="icon"
                    src={require('@/assets/common/zuo.svg')}
                    alt=""
                  />
                }
                trigger="hover"
              >
                &nbsp;
                <span className="dropdown">客服</span>
                <Icon className="icon2" type="iconxiangyou" />
              </Popover> */}
            </li>
          </div>
          <ul className="m-h-r">
            <li className="m-li-item">
              <Link to="/" className="index">
                首页
              </Link>
            </li>
            <li className="m-li-item">
              <MSelect label="我的个人中心" menus={men} />
            </li>

            {/* <li className="m-li-item goReturn" onClick={returnmrc}>
              <Icon className="icon" type="iconmendianguanlixitong1" />
              &nbsp;
              <span className="goReturn-title">门店管理系统</span>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="main-header-plac"></div>
    </>
  );
}

export default connect(({ user }: { user: UserModelState }) => ({ user }))(
  MainHeader,
);
