import React, { useEffect } from 'react';
import HSearch from '@/components/Home/Search';
import { Image, Badge } from 'antd';
import { history, connect, OrderModelState, Dispatch } from 'umi';
import './index.less';
import { useScroll } from 'ahooks';
import Icon from '@/components/Icon';
interface IProps {
  order: OrderModelState;
  dispatch: Dispatch;
}
function SearchBanner(props: IProps) {
  const handleCart = () => {
    history.push('/order/shopping');
  };
  useEffect(() => {
    props.dispatch({
      type: 'order/getPurchaseListAllSkuNum',
    });
    console.log(props.order);
  }, []);

  const scroll = useScroll(document);

  return (
    <>
      <div
        className={`search-banner ${
          scroll && scroll.top > 100 ? 'search-banner-min' : ''
        }`}
      >
        <div className="search-banner-body">
          <div className="logoBox">
            <div className="textDimg">
              <Image
                className="logo"
                src={require('@/assets/common/cht.png')}
                preview={false}
                onClick={() => history.push('/')}
              ></Image>
              {/* <span>批发服务平台</span> */}
            </div>
            <div>
              {/* <Image
                className="textImg"
                src={require('@/assets/common/zzfu.png')}
                preview={false}
                onClick={() => history.push('/')}
              /> */}
              <p className="logo_desc">宠发发旗下大客户批采服务平台</p>
            </div>
          </div>
          <HSearch
            className={`b-search ${
              scroll && scroll.top > 100 ? 'b-search-min' : ''
            }`}
          />

          <div
            className={`b-cars ${
              scroll && scroll.top > 100 ? 'b-cars-min' : ''
            }`}
            onClick={handleCart}
          >
            <Badge
              color="#1473EC"
              count={props.order.purchaseListLen}
              offset={[-14, 6]}
            >
              {/* <Icon className="cars-icon" type="icongouwuche1" /> */}
              <img
                className="cars-icon"
                src={require('@/assets/common/car.png')}
                alt=""
              />
            </Badge>
            <span className="cars-title">我的进货单</span>
          </div>
        </div>
      </div>
      <div className="main-search-plac"></div>
    </>
  );
}

export default connect(({ order }: { order: OrderModelState }) => ({ order }))(
  SearchBanner,
);
