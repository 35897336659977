import React, { useEffect, useState } from 'react';
import { connect, UserModelState, Loading, Dispatch, IAddressItem } from 'umi';
import { Modal, Form, Input, Checkbox } from 'antd';
import AddressSelect, { IAddressResult } from '@/components/AddressSelect';
import NumericInput from '@/components/NumericInput';
import './index.less';
interface IAddressCreaterModalProps {
  visible: boolean;
  onCancel: () => void;
  initialValues?: IAddressItem;
  user: UserModelState;
  loading: Loading;
  dispatch: Dispatch;
}
interface AddressTreeItem {
  parentCode: number;
  code: number | string;
  name: string;
  children: AddressTreeItem[];
}

export const validatorPhone = (rule: any, value: any, callback: any) => {
  if (!value) {
    return Promise.resolve();
  }
  let reg = /^1[0-9]{10}$/;
  if (!reg.test(value)) {
    return Promise.reject('请输入正确的手机号');
  }
  return Promise.resolve();
};

const initValues = {
  id: '',
  consignee: '',
  realname: '',
  provinceId: '',
  cityId: '',
  areaId: '',
  region: '',
  address: '',
  code: '',
  phone: '',
  defaultAddress: false,
};
function AddressCreaterModal(props: IAddressCreaterModalProps) {
  const [isShowAddressSelect, setIsShowAddressSelect] = useState(false);
  const [province, setProvince] = useState('');
  const [provinceCode, setProvinceCode] = useState<number | string>('');
  const [id, setId] = useState<null | number>(null);
  const [city, setCity] = useState('');
  const [cityCode, setCityCode] = useState<number | string>('');
  const [addressList, setAddressList] = useState<any[]>([]);
  const [defaultAddress, setDefaultAddress] = useState<boolean>(false);
  const [addressTree, setAddressTree] = useState<AddressTreeItem[]>([]);
  const [county, setCounty] = useState('');
  const [countyCode, setCountyCode] = useState<number | string>('');
  const [form] = Form.useForm();
  const handleSelectAddress = () => {
    setIsShowAddressSelect(true);
  };
  const onAddressChange = (res: IAddressResult) => {
    setProvince(res.list[0].name);
    setProvinceCode(res.list[0].code);
    setCity(res.list[1].name);
    setCityCode(res.list[1].code);
    setCounty(res.list[2].name);
    setCountyCode(res.list[2].code);
    setAddressList(res.list);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      region: res.address,
    });
    setIsShowAddressSelect(false);
  };
  const handleSubmit = () => {
    form.validateFields().then((fields) => {
      const formData = {
        ...fields,
        province,
        provinceCode,
        city,
        cityCode,
        county,
        countyCode,
        defaultAddress,
        id,
      };
      props
        .dispatch({
          type: 'user/addressCreate',
          payload: formData,
        })
        .then(() => {
          props.onCancel();
        });
    });
  };
  /**
   * 省市区地址 列表树
   */
  const getAddressTree = () => {
    props
      .dispatch({
        type: 'user/addressTreeApi',
        payload: {},
      })
      .then((res: any) => {
        if (res.success) {
          setAddressTree(res.data);
        }
      });
  };
  useEffect(() => {
    getAddressTree();
  }, []);

  useEffect(() => {
    if (props.initialValues?.id) {
      setId(props.initialValues.id);
    } else {
      setId(null);
      form.setFieldsValue(initValues);
      setAddressList([]);
      setProvince('');
      setProvinceCode('');
      setCity('');
      setCityCode('');
      setCounty('');
      setCountyCode('');
    }
    let region = '';
    if (props.initialValues && props.initialValues.id) {
      if (props.initialValues?.provinceCode) {
        const {
          province,
          provinceCode,
          city,
          cityCode,
          county,
          countyCode,
          address,
        } = props.initialValues;
        setAddressList([
          {
            name: province,
            code: provinceCode + '',
          },
          {
            name: city,
            code: cityCode + '',
          },
          {
            name: county,
            code: countyCode + '',
          },
        ]);
        region = province + city + county;
        setProvince(province);
        setProvinceCode(provinceCode);
        setCity(city);
        setCityCode(cityCode);
        setCounty(county);
        setCountyCode(countyCode);
      }
      setDefaultAddress(props.initialValues.defaultAddress);
      form.setFieldsValue({
        ...initValues,
        ...props.initialValues,
        region,
      });
    }
  }, [props.visible, props.initialValues]);
  return (
    <Modal
      wrapClassName="address-creater-modal custom"
      visible={props.visible}
      onCancel={props.onCancel}
      title="创建地址"
      width="640px"
      onOk={handleSubmit}
      confirmLoading={props.loading.effects['user/addressCreate']}
    >
      <div className="modal-body">
        <Form
          labelAlign="right"
          wrapperCol={{ span: 19 }}
          labelCol={{ span: 5 }}
          form={form}
          initialValues={props.initialValues}
        >
          <Form.Item
            label="收货人姓名"
            name="consignee"
            rules={[{ required: true, message: '请输入收货人姓名' }]}
          >
            <Input placeholder="请输入收货人姓名" maxLength={12} />
          </Form.Item>
          <Form.Item
            label="手机号码"
            name="phone"
            rules={[
              { required: true, message: '请输入手机号码' },
              { len: 11, message: '请输入11位手机号码' },
            ]}
          >
            <NumericInput
              placeholder="请输入手机号码"
              type="int"
              maxLength={11}
            />
          </Form.Item>
          <Form.Item
            label="所在地区"
            className="address-con"
            name="region"
            rules={[{ required: true, message: '请选择所在地区' }]}
          >
            <Form.Item name="region" noStyle>
              <Input
                readOnly
                placeholder="请选择所在地区"
                onClick={handleSelectAddress}
              />
            </Form.Item>
            {isShowAddressSelect && (
              <AddressSelect
                level={3}
                parentClassName="address-con"
                defaultValue={addressList}
                addressTree={addressTree}
                onCancel={() => setIsShowAddressSelect(false)}
                onChange={onAddressChange}
              />
            )}
          </Form.Item>
          <Form.Item
            label="详细地址"
            name="address"
            rules={[{ required: true, message: '请输入详细地址' }]}
          >
            <Input.TextArea
              maxLength={50}
              style={{ height: '80px', resize: 'none' }}
              placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息"
            />
          </Form.Item>
          <Form.Item label="" wrapperCol={{ offset: 5 }}>
            <Form.Item noStyle name="defaultAddress">
              {props.visible && (
                <Checkbox
                  checked={defaultAddress}
                  onChange={(e) => setDefaultAddress(e.target.checked)}
                >
                  设置为默认收货地址
                </Checkbox>
              )}
            </Form.Item>
            <p className="tip">设置后系统将在下单时自动选中该收货地址</p>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
export default connect(
  ({ user, loading }: { user: UserModelState; loading: Loading }) => ({
    user,
    loading,
  }),
)(AddressCreaterModal);
