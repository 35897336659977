import React, { useEffect, useState } from 'react';
import RecommendItem from '@/components/Goods/RecommendItem';
import { GoodsModelState, Loading, Dispatch, connect } from 'umi';
import Icon from '@/components/Icon';
import './index.less';
interface IProps {
  goods: GoodsModelState;
  loading: Loading;
  tenantId?: number | string | undefined;
  dispatch: Dispatch;
  len?: number;
  title?: string;
}

function Index(props: IProps) {
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    props
      .dispatch({
        type: 'goods/hotList',
        payload: {
          tenantId: props.tenantId,
          sortName: 'SALES',
          sortAsc: false,
          page: 1,
          pageSize: 4,
        },
      })
      .then((data: any) => {
        setList(data.list || []);
      });
  }, [props.tenantId]);
  return (
    <div className="goods-hot-list-container">
      <div className="title">
        <Icon type="iconrexiao" className="h-t-icon" />
        <span>{props.title || '店内热销'}</span>
      </div>
      <div className="list">
        {list &&
          list.map((item: any, index: number) => (
            <RecommendItem key={item.spuId} idx={index} detail={item} />
          ))}
      </div>
    </div>
  );
}

export default connect(
  ({ goods, loading }: { goods: GoodsModelState; loading: Loading }) => ({
    goods,
    loading,
  }),
)(Index);
