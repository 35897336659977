import React, { useState } from 'react';
import './index.less';

export interface SearchItemProps {
  title: string;
  num: number;
  handleSerachResultJump: (title: string) => void;
}

const SearchItem: React.FC<SearchItemProps> = (props) => {
  return (
    <div
      className="search-item"
      onClick={() => props.handleSerachResultJump(props.title)}
    >
      <div className="item-lf">{props.title}</div>
      <div className="item-rg">
        约<span className="value">{props.num}</span>个搜索结果
      </div>
    </div>
  );
};

export default SearchItem;
