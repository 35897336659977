import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { NavLink, history } from 'umi';
import Icon from '@/components/Icon';
import FastReplenis from './Order/FastReplenis';
import Detail from './Order/Detail';
import ApplyRefund from './Order/ApplyRefund';
import './index.less';

const menuList = [
  {
    key: 'sub1',
    title: '订单管理',
    icon: 'iconwodedingdan',
    children: [
      {
        key: 'sub1-1',
        title: '直采订单',
        path: '/user/purchase',
      },
      // {
      //   key: 'sub1-2',
      //   title: '快速补货',
      //   path: '/user/fastReplenishment',
      // },
    ],
  },
  // {
  //   key: 'sub2',
  //   title: '进货单',
  //   icon: 'iconjinhuodan1',
  //   children: [
  //     {
  //       key: 'sub2-1',
  //       title: '进货单',
  //       path: '/order/shopping',
  //     },
  //   ],
  // },
  {
    key: 'sub4',
    title: '售后管理',
    icon: 'icontuikuanguanli',
    children: [
      {
        key: 'sub4-1',
        title: '退款/退货',
        path: '/user/refund',
      },
    ],
  },
  // {
  //   key: 'sub5',
  //   title: '权益管理',
  //   icon: 'icondizhiguanli1',
  //   children: [
  //     {
  //       key: 'sub5-1',
  //       title: '优惠券',
  //       path: '/user/coupon',
  //     },
  //   ],
  // },
  {
    key: 'sub3',
    title: '更多管理',
    icon: 'icondizhiguanli',
    children: [
      {
        key: 'sub3-1',
        title: '收货地址',
        path: '/user/address',
      },
    ],
  },
];
export default function OrderList(props: any) {
  // state
  const [showComponent, setShowComponent] = useState('/user/purchase');
  // methods
  const menuChange = (path: string) => {
    if (path === '/user/shoppingcart') {
      return;
    }
    setShowComponent(path);
  };
  console.log(props);
  return (
    <div className="order-container">
      {props.location.pathname === '/user/fastReplenishment' ? (
        <FastReplenis />
      ) : props.location.pathname === '/user/order/detail' ? (
        <Detail />
      ) : props.location.pathname === '/user/order/applyRefund' ? (
        <ApplyRefund />
      ) : (
        <div className="order-list-container">
          <div className="menu">
            {menuList.map((item) => {
              return (
                <div className="item" key={item.key}>
                  <div className="title">
                    <Icon
                      className="icon"
                      style={{ fontSize: '20px', marginRight: '14px' }}
                      type={item.icon}
                    />
                    <span className="tit">{item.title}</span>
                  </div>
                  <div className="con">
                    {item.children.map((ele: any) => {
                      if (
                        ele.path !== '/user/shoppingcart' ||
                        ele.path !== '/user/fastReplenishment'
                      ) {
                        return (
                          <NavLink
                            to={ele.path}
                            key={ele.key}
                            activeClassName="active"
                          >
                            {ele.title}
                          </NavLink>
                        );
                      } else {
                        return (
                          <span
                            className="tit"
                            key={ele.key}
                            onClick={() => history.push(ele.path)}
                          >
                            {ele.title}
                          </span>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="main">{props.children}</div>
        </div>
      )}
    </div>
  );
}
