import React from 'react';
import { RightOutlined, CloseOutlined } from '@ant-design/icons';
import './index.less';
interface IProps {
  tags: any[];
  onDelete?: (id: number, type: string) => void;
}
export default function Index(props: IProps) {
  const handleDelete = (item: any) => {
    props.onDelete && props.onDelete(item.id, item.type);
  };
  return (
    <div className="search-more-breadcrumb-component">
      <div className="w1200">
        <div className="text">您的位置：</div>
        <div className="text">全部结果</div>
        {props.tags.length > 0 && <RightOutlined className="icon-arrow" />}
        {props.tags.map((item: any, index: number) => (
          <div className="item" key={item.id}>
            <div className="tag">
              <span>{item.label}</span>
              <CloseOutlined
                className="icon-close"
                onClick={() => handleDelete(item)}
              />
            </div>
            {item.type === 'category' && props.tags.length > 1 && (
              <RightOutlined className="icon-arrow" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
