import React, { useEffect, useState } from 'react';
import {
  Radio,
  Spin,
  Empty,
  Pagination,
  Select,
  Button,
  Dropdown,
  Menu,
} from 'antd';
import { history, connect, OrderModelState, Loading } from 'umi';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import emptyImg from '@/assets/common/empty.png';
import CouponBig from '@/components/User/Coupon';
const { Option } = Select;
import './index.less';
const couponTypeArr: any = {
  0: '可用优惠券',
  1: '已用优惠券',
  2: '过期优惠券',
};
function Coupon(props: any) {
  // state
  const [data, setData] = useState([]);
  const [listQuery, setListQuery] = useState({
    pageNo: 1,
    pageSize: 8,
    item: {
      status: 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const [couponType, setCouponType] = useState(0);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState(0);
  useEffect(() => {
    const query = history.location.query;
    if (query && query.status) {
      setTimeout(() => {
        setListQuery({
          ...listQuery,
          pageNo: 1,
          item: { status: query.status * 1 },
        });
      }, 100);
    }
  }, []);

  // 生命周期
  useEffect(() => {
    getCouponList();
  }, [listQuery]);

  // methods
  // 获取订单列表
  const getCouponList = () => {
    setLoading(true);
    props
      .dispatch({
        type: 'userOrder/getCouponList',
        payload: {
          ...listQuery,
        },
      })
      .then((res: any) => {
        setData(res.items || []);
        setTotal(res.count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeCoupon = (e: any) => {
    const val = e.key * 1;
    setCouponType(val);
    val < 3 && setStatus(val);
    setListQuery({
      ...listQuery,
      pageNo: 1,
      item: { status: val },
    });
  };

  return (
    <div className="coupon-container">
      {/* 头部tabs */}
      <div className="coupon-top">
        <div className="line"></div>
        <span>优惠券</span>
      </div>
      {/* 优惠券列表主体 */}
      <div className="coupon-main">
        <div className="coupon-tabbar">
          <Dropdown
            overlay={
              <Menu onClick={handleChangeCoupon} style={{ width: 120 }}>
                <Menu.Item key={0}>可用优惠券</Menu.Item>
                <Menu.Item key={1}>已用优惠券</Menu.Item>
                <Menu.Item key={2}>过期优惠券</Menu.Item>
              </Menu>
            }
          >
            <Button className="btn" type="primary">
              {couponTypeArr[status]} <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            className="btn"
            type="primary"
            onClick={() => handleChangeCoupon({ key: 3 })}
          >
            即将过期
          </Button>
        </div>
        {/* 优惠券列表 */}
        <Spin spinning={loading}>
          <div className="coupon-list">
            {data &&
              data.length > 0 &&
              data.map((item: any) => {
                return (
                  <CouponBig
                    key={item.id}
                    couponType={couponType}
                    couponData={item}
                  />
                );
              })}
          </div>
          {data && data.length === 0 && (
            <Empty
              image={emptyImg}
              description="暂无优惠券"
              imageStyle={{
                width: '300px',
                height: '207px',
                margin: '65px auto',
                color: '#666',
              }}
            />
          )}
          {total > 0 && (
            <div className="pagination">
              <Pagination
                current={listQuery.pageNo}
                total={total}
                showSizeChanger={false}
                showQuickJumper
                onChange={(page, pageSize) => {
                  setListQuery({
                    ...listQuery,
                    pageNo: page,
                  });
                }}
              />
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(Coupon);
