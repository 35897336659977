import React from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import SortArrow from '@/components/SortArrow';
import NumericInput from '@/components/NumericInput';
import { message } from 'antd';
import './index.less';
import { useState } from 'react';
import { useEffect } from 'react';

interface IProps {
  pagin: {
    pageNo: number;
    pageSize: number;
  };
  total: number;
  onParamsChange?: (params: any) => void;
  onPaginChange: (pageNo: number) => void;
}
export default function Index(props: IProps) {
  const [radioIndex, setRadioIndex] = useState(0);
  const [params, setParams] = useState<any>({
    categoryId: '',
    brandIds: [],
    sort: 1,
    sortType: 0,
    lowPrice: null,
    highPrice: null,
  });
  const [priceParams, setPriceParams] = useState<any>({
    lowPrice: '',
    highPrice: '',
  });
  const totalPageNo = React.useMemo(() => {
    return Math.ceil(props.total / props.pagin.pageSize);
  }, [props.total, props.pagin]);
  const handlePaginClick = React.useCallback(
    (changeNum: number) => {
      if (
        props.pagin.pageNo + changeNum < 1 ||
        props.pagin.pageNo + changeNum > totalPageNo
      ) {
        return;
      }
      props.onPaginChange &&
        props.onPaginChange(props.pagin.pageNo + changeNum);
    },
    [props.pagin.pageNo, totalPageNo, props.onPaginChange],
  );
  const handleRadio = (type: number) => {
    let value = type;
    if (type === 2) {
      value = radioIndex === type ? type + 1 : type;
    }

    if (value >= 2) {
      setParams({
        ...params,
        sort: 2,
        sortType: value - 2,
      });
    } else {
      setParams({
        ...params,
        sort: value,
        sortType: 0,
      });
    }
    setRadioIndex(value);
  };
  const handlePriceConfirm = () => {
    // if (Number(priceParams.lowPrice) > Number(priceParams.highPrice)) {
    //   message.warning('');
    //   return;
    // }
    setParams({
      ...params,
      ...priceParams,
    });
  };
  const handlePriceInput = (value: string, type: string) => {
    setPriceParams({
      ...priceParams,
      [type]: value === '' ? null : value,
    });
  };
  useEffect(() => {
    props.onParamsChange && props.onParamsChange(params);
  }, [params]);
  return (
    <div className="search-more-filter-container">
      <div className="l">
        <div className="radio-group">
          <div
            className={`radio ${radioIndex === 0 ? 'selected' : ''}`}
            onClick={() => handleRadio(0)}
          >
            综合
          </div>
          <div
            className={`radio ${radioIndex === 1 ? 'selected' : ''}`}
            onClick={() => handleRadio(1)}
          >
            销量
          </div>
          <div
            className={`radio ${
              radioIndex === 2 || radioIndex === 3 ? 'selected' : ''
            }`}
            onClick={() => handleRadio(2)}
          >
            价格 <SortArrow type={radioIndex} />
          </div>
        </div>
        <div className="price-con">
          <NumericInput
            type="amount"
            prefix="¥"
            max={999999.99}
            style={{ width: 100 }}
            value={priceParams.lowPrice}
            onChange={(value) => handlePriceInput(value, 'lowPrice')}
          />
          <span className="split">-</span>
          <NumericInput
            type="amount"
            prefix="¥"
            max={999999.99}
            style={{ width: 100 }}
            value={priceParams.highPrice}
            onChange={(value) => handlePriceInput(value, 'highPrice')}
          />
          <Button type="default" className="btn" onClick={handlePriceConfirm}>
            确定
          </Button>
        </div>
      </div>
      <div className="r">
        <div className="total">
          共 <span>{props.total}</span> 件商品
        </div>
        <div className="pagin">
          <span className="page">
            {totalPageNo === 0 ? 0 : props.pagin.pageNo}
          </span>
          <span>/{totalPageNo}</span>
        </div>
        <div className="handle-con">
          <Button
            icon={<LeftOutlined />}
            className="btn"
            onClick={() => handlePaginClick(-1)}
          ></Button>
          <Button
            icon={<RightOutlined />}
            className="btn"
            onClick={() => handlePaginClick(1)}
          ></Button>
        </div>
      </div>
    </div>
  );
}
