import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import CouponItem from '@/pages/Home/components/coupon/item';
import { connect, UserModelState, Dispatch, history } from 'umi';
import GoodsItem from '@/components/Goods/Item';
import './Activity.less';
const { TabPane } = Tabs;
const Icons = {
  zuo: require('@/assets/home/activity/icon-title-zuo.png'),
  you: require('@/assets/home/activity/icon-title-you.png'),
};

function CouponList(props: any) {
  const [list, setList] = useState<any[]>([]);
  const fetchList = () => {
    props
      .dispatch({
        type: 'goods/couponListCanReceive',
        payload: {
          pageSize: 6,
          pageNo: 1,
        },
      })
      .then((data: any) => {
        setList(data);
      });
  };
  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div className="coupon-list">
      <div className="w1200">
        {Array.isArray(list) &&
          list.map((item) => (
            <CouponItem detail={item} onSuccess={fetchList} />
          ))}
      </div>
    </div>
  );
}

interface IProps {
  user: UserModelState;
  dispatch: Dispatch;
}
function Index(props: IProps) {
  const [sectionList, setSectionList] = useState([]);
  useEffect(() => {
    const type = history.location.query?.type;
    props
      .dispatch({
        type: 'goods/homeFourthFloorDetail',
        payload: {
          type,
        },
      })
      .then((data: any) => {
        setSectionList(data);
      });
  }, [history.location.query]);
  const currentDefaultActiveKey = useCallback(
    (tabList) => {
      if (!Array.isArray(tabList) || !tabList.length) {
        return undefined;
      }
      return tabList[0].name;
    },
    [sectionList],
  );

  return (
    <div className="home-activity-container">
      <div className="banner"></div>
      <CouponList {...props} />
      {sectionList &&
        sectionList.map((item: any, index) => (
          <div className="section" key={index}>
            <div className="w1200">
              <div className="section-title">
                <img src={Icons.zuo} alt="" />
                <h1>{item.activityName}</h1>
                <img src={Icons.you} alt="" />
              </div>
              <Tabs
                className="section-tabs"
                defaultActiveKey={currentDefaultActiveKey(item.spuList)}
              >
                {item.spuList &&
                  item.spuList.map((tab: any) => (
                    <TabPane tab={tab.name} key={tab.name}>
                      <div className="section-con">
                        {tab.spuList &&
                          tab.spuList.map((spu: any) => (
                            <GoodsItem key={spu.id} detail={spu}></GoodsItem>
                          ))}
                      </div>
                    </TabPane>
                  ))}
              </Tabs>
            </div>
          </div>
        ))}
      <div className="no-more">
        <span>商品全部展示完毕</span>
      </div>
    </div>
  );
}
export default connect(({ user }: { user: UserModelState }) => ({ user }))(
  Index,
);
