import React, { useEffect, useState } from 'react';
import AddressSelect from '@/components/AddressSelect';
import AMap from '@/components/AMap';
import {
  Dispatch,
  ILocaltionPosition,
  CommonModelState,
  UserModelState,
} from 'umi';
import './index.less';
import { useMemo } from 'react';
import { connect } from 'react-redux';
interface IProps {
  skuId?: number;
  dispatch: Dispatch;
  user: UserModelState;
  common: CommonModelState;
  getFreightDetail?: (val: any) => void;
}
interface AddressTreeItem {
  parentCode: number;
  code: number | string;
  name: string;
  children: AddressTreeItem[];
}

function ShippingAddress(props: IProps) {
  const [freightDetail, setFreightDetail] = useState<any>({});
  const [isShowAddressSelect, setIsShowAddressSelect] = useState(false);
  const [currentAddressList, setCurrentAddressList] = useState<any>([]);
  const [addressTree, setAddressTree] = useState<AddressTreeItem[]>([]);
  const onAddressChange = (params: any) => {
    setCurrentAddressList(params.list);
    getFreight(params.list.map((item: any) => item.code)).then((data: any) => {
      setFreightDetail(data);
      props.getFreightDetail && props.getFreightDetail(data);
    });
    setIsShowAddressSelect(false);
  };
  const onAddressCancel = () => {
    setIsShowAddressSelect(false);
  };
  const getFreight = (arr?: string[]) => {
    let params = {};
    if (!props.skuId) {
      return Promise.reject();
    }
    if (Array.isArray(arr) && arr.length === 3) {
      params = {
        province: arr[0],
        city: arr[1],
        district: arr[2],
      };
    }
    return props.dispatch({
      type: 'goods/freightCalculate',
      payload: {
        ...params,
        skuId: props.skuId,
        userId: props.user.user.id,
      },
    });
  };

  const getDefaultAddress = () => {
    props
      .dispatch({
        type: 'goods/fetchDefaultAddress',
      })
      .then((result: any) => {
        setCurrentAddressList([
          {
            code: result.provinceCode,
            name: result.province,
          },
          {
            code: result.cityCode,
            name: result.city,
          },
          {
            code: result.countyCode,
            name: result.county,
          },
        ]);
        getFreight([
          result.provinceCode,
          result.cityCode,
          result.countyCode,
        ]).then((data: any) => {
          setFreightDetail(data);
          props.getFreightDetail && props.getFreightDetail(data);
        });
      });
  };

  const currentAddress = useMemo(
    () =>
      currentAddressList.map((item: any) => item && item.name).join('') ||
      '获取中...',
    [currentAddressList],
  );
  const startAddress = useMemo(() => {
    if (!freightDetail.depotProvince) {
      return '获取中...';
    }

    return `${freightDetail.depotProvince}${freightDetail.depotCity}${freightDetail.depotCounty}`;
  }, [freightDetail]);

  const currentFreight = useMemo(() => {
    if (freightDetail.freightPrice === undefined) {
      return '获取中...';
    }
    return freightDetail.freightPrice
      ? `¥${freightDetail.freightPrice}`
      : '包邮';
  }, [freightDetail]);

  /**
   * 省市区地址 列表树
   */
  const getAddressTree = () => {
    props
      .dispatch({
        type: 'user/addressTreeApi',
        payload: {},
      })
      .then((res: any) => {
        if (res.success) {
          setAddressTree(res.data);
        }
      });
  };
  useEffect(() => {
    getAddressTree();
  }, []);
  // const onCurrentPosition = (result: ILocaltionPosition) => {
  //   setCurrentAddressList([
  //     {
  //       label: result.province,
  //     },
  //     {
  //       label: result.city,
  //     },
  //     {
  //       label: result.district,
  //     },
  //   ]);
  //   getFreight([result.province, result.city, result.district]).then(
  //     (data: any) => {
  //       setFreightDetail(data);
  //       props.getFreightDetail && props.getFreightDetail(data);
  //     },
  //   );
  // };
  useEffect(() => {
    if (props.skuId) {
      getFreight(currentAddressList.map((item: any) => item.code)).then(
        (data: any) => {
          setFreightDetail(data);
          props.getFreightDetail && props.getFreightDetail(data);
          // if (data.province) {
          //   setCurrentAddressList([
          //     {
          //       label: data.province,
          //     },
          //     {
          //       label: data.city,
          //     },
          //     {
          //       label: data.district,
          //     },
          //   ]);
          // }
        },
      );
    }
  }, [props.skuId, currentAddressList]);

  useEffect(() => {
    getDefaultAddress();
  }, []);

  return (
    <div className="shipping-address-con">
      {/* <div style={{ display: 'none' }}>
        {freightDetail.city === null && (
          <AMap onCurrentPosition={onCurrentPosition} />
        )}
      </div> */}
      <div className="title">配送：</div>
      {/* <div className="from-item">{startAddress}</div> */}
      <div className="split">至</div>
      <div className="to-item">
        <div
          className={`to-item-child ${isShowAddressSelect && 'selected'}`}
          onClick={() => setIsShowAddressSelect(!isShowAddressSelect)}
        >
          {currentAddress}
        </div>
        <div className="address-select-con">
          {isShowAddressSelect && (
            <AddressSelect
              level={3}
              addressTree={addressTree}
              parentClassName="shipping-address-con"
              onChange={onAddressChange}
              onCancel={onAddressCancel}
              defaultValue={currentAddressList}
            />
          )}
        </div>
      </div>
      {freightDetail.sendToFlag === true ? (
        <div className="price">快递 {currentFreight}</div>
      ) : freightDetail.sendToFlag === false ? (
        <div className="price" style={{ color: '#1473EC' }}>
          该区域不支持配送
        </div>
      ) : (
        <></>
      )}

      {/* <div className="price">快递：￥0 </div> */}
    </div>
  );
}

export default connect(
  ({ common, user }: { common: CommonModelState; user: UserModelState }) => ({
    common,
    user,
  }),
)(ShippingAddress);
