const env = process.env.CURRENT_ENV || 'prod';

const domain: any = {
  dev: 'http://api-test.petfafa.com:9627',
  // dev: 'http://10.20.191.41:9627',
  // dev: 'https://server.petfafa.com',
  test: 'http://api-test.petfafa.com:9627',
  preprod: 'api-store-test.zhuayinline.com',
  prod: 'https://server.petfafa.com',
};
// 新版
const domain_v2: any = {
  dev: 'http://api-test.petfafa.com:9627',
  // dev: 'http://10.20.191.41:9627',
  // dev: 'https://server.petfafa.com',
  test: 'http://api-test.petfafa.com:9627',
  preprod: 'api-supplier-test.zhuayinline.com',
  prod: 'https://server.petfafa.com',
};
const domain_h5: any = {
  dev: 'store-h5-test.zhuayinline.com',
  test: 'store-h5-test.zhuayinline.com',
  preprod: 'store-h5-test.zhuayinline.com',
  prod: 'store-h5.zhuayinline.com',
};
const host = domain[env];
const host_v2 = domain_v2[env];
const host_h5 = domain_h5[env];
export const BASE_URL = host;
export const BASE_URL_V2 = `${host_v2}`;
export const BASE_URL_H5 = `${host_h5}`;
export const BASE_UPLOAD = `${host_v2}/api/oss-center/upload/singleUpload`;
