import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { getLabelByValueFromEnum } from '@/utils';
import { EFFECT_GOODS_MAP } from '@/utils/enum';
import moment from 'moment';
import './item.less';
interface IProps {
  type?: 'receive' | 'select' | 'received';
  selected?: boolean;
  onClick?: (couponId: number) => void;
  onReveice?: (couponId: number) => void;
  detail: any;
}
export default function Index(props: IProps) {
  const handleClick = () => {
    props.onClick && props.onClick(props.detail.couponId);
  };
  const date = React.useMemo(() => {
    return `${moment(props.detail.startTime).format('YYYY-MM-DD')}-${moment(
      props.detail.endTime,
    ).format('YYYY-MM-DD')}`;
  }, [props.detail.startTime, props.detail.endTime]);
  return (
    <div
      onClick={handleClick}
      className={`drawer-coupon-item-component type-${props.type} ${
        props.selected ? 'selected' : ''
      }`}
    >
      <div className="drawer-coupon-item">
        <div className="l">
          <div className="value">
            <span className="unit">¥</span>
            <p>{props.detail.discount}</p>
          </div>
          <div className="desc">
            {props.detail.min === 0 ? '无门槛' : `满${props.detail.min}可用`}
          </div>
        </div>
        <div className="m">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <div className="r">
          <p className="title">
            {props.detail.couponTitle || props.detail.title}
          </p>
          <p className="date">{date}</p>
        </div>
      </div>
      {(props.type === 'receive' || props.type === 'received') && (
        <div
          className={`handle-con ${
            props.type === 'received' ? 'received' : ''
          }`}
          onClick={() => props.onReveice && props.onReveice(props.detail.id)}
        >
          <span>立即领取</span>
        </div>
      )}
      {props.type === 'select' && (
        <div className="select-con">
          {props.selected && <div className="s-c-selected"></div>}
        </div>
      )}
    </div>
  );
}
