import React, { useState, useMemo, useEffect } from 'react';
import { Upload, UploadProps, Modal, message } from 'antd';
import lrz from 'lrz/dist/lrz.bundle.js';
import { BASE_UPLOAD } from '@/config/api';
import { base64ToFile } from '@/utils';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { connect, UserModelState } from 'umi';
import './Upload.less';

type UploadTitleElement =
  | React.ReactElement<unknown, string | React.FC | typeof React.Component>
  | string
  | null;
interface MyUploadProps extends UploadProps {
  value?: string;
  children?: any;
  uploadTitle?: UploadTitleElement;
  size?: number;
  limitNumber?: number; // 限制上传图片数量
  maxSize?: number | 6;
  [props: string]: any;
}
interface IUploadButtonProps {
  loading: boolean;
  uploadTitle?: UploadTitleElement;
  size?: number;
}
function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const UploadButton = (props: IUploadButtonProps) => {
  return (
    <div>
      <PlusOutlined style={props.size ? { fontSize: `${props.size}px` } : {}} />
      {props.uploadTitle ? (
        typeof props.uploadTitle === 'string' ? (
          <div style={{ marginTop: 14 }}>{props.uploadTitle}</div>
        ) : (
          props.uploadTitle
        )
      ) : null}
    </div>
  );
};
const MyUpload = (props: MyUploadProps) => {
  const [fileList, setFileList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [url, setUrl] = useState<string | undefined>('');
  const handleChange = (info) => {
    setFileList(info.fileList);
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'removed') {
      props.onChange && props.onChange('');
      setUrl('');
      return;
    }
    if (info.file.status === 'done') {
      if (!multiple) {
        props.onChange && props.onChange(info.file.response.data);
        setUrl(info.file.response.data);
      }
      setLoading(false);
    }
  };
  const multiple = useMemo<boolean>(
    () => (props.limitNumber ? props.limitNumber > 1 : false),
    [props.limitNumber],
  );
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    );
  };
  const beforeUpload = (file) => {
    if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) === -1) {
      message.error('请上传jpg、jpeg、png和bmp格式图片');
      return Upload.LIST_IGNORE;
    }
    return new Promise((resolve, reject) => {
      // if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) === -1) {
      //   message.error('请上传jpg、jpeg、png和bmp格式图片');
      //   reject();
      //   return false;
      // }
      lrz(file, {
        width: 1500,
      }).then((res) => {
        const imageSize = props.maxSize || 6;
        if (res.fileLen > imageSize * 1024 * 1024) {
          message.error(`图片大小需小于${imageSize}M`);
          reject();
          return;
        }
        const file = base64ToFile(res.base64, res.origin.name);
        resolve(file);
      });
    });
  };
  useEffect(() => {
    if (isDefault && props.defaultFileList && props.defaultFileList.length) {
      setIsDefault(false);
      return;
    }

    if (fileList.some((item) => item.status !== 'done') || !multiple) {
      return;
    }
    props.onChange &&
      props.onChange(fileList.map((item: any) => item.response?.data));
  }, [fileList]);
  useEffect(() => {
    setUrl(props.value);
  }, [props.value]);
  useEffect(() => {
    if (props.defaultFileList && props.defaultFileList.length && isDefault) {
      const temp = props.defaultFileList.map((item, index) => ({
        name: index,
        status: 'done',
        thumbUrl: item,
        response: {
          errno: 200,
          url: item,
        },
      }));
      setIsDefault(false);
      setFileList(temp);
    }
  }, [props.defaultFileList]);
  return (
    <>
      <Upload
        listType="picture-card"
        className="sku-image-uploader"
        showUploadList={multiple}
        accept="image/*"
        action={BASE_UPLOAD}
        headers={{
          'SUP-STORE-TOKEN': props?.user?.token,
        }}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        multiple={multiple}
        fileList={fileList}
        onPreview={handlePreview}
      >
        {typeof props.limitNumber === 'undefined' && url ? (
          <img src={url} style={{ width: '100%', height: '100%' }} />
        ) : props.limitNumber && fileList.length >= props.limitNumber ? null : (
          <UploadButton
            loading={loading}
            uploadTitle={props.uploadTitle}
            size={props.size}
          />
        )}
      </Upload>
      <Modal
        visible={previewVisible}
        footer={null}
        title={previewTitle}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
export default connect(({ user }: { user: UserModelState }) => ({
  user,
}))(MyUpload);
