import React, { useEffect, useState } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import NumericInput from '@/components/NumericInput';
import { Input, Button } from 'antd';
import './index.less';

interface INumberStepProps {
  value: number;
  /**
   * min 最小值
   */
  min?: number;
  /**
   * min 最大值
   */
  max?: number;
  step?: number;
  disabledEdit?: boolean;
  onChange?: Function;
  disabled?: boolean;
}
export default function NumberStep(props: INumberStepProps) {
  const [isFirst, setIsFirst] = useState(true);
  const onChange = (value: number) => {
    props.onChange && props.onChange(value);
  };
  const handleChangeValue = (number: number) => {
    if (
      typeof props.min !== 'undefined' &&
      props.value <= props.min &&
      number < 0
    ) {
      return;
    }
    if (
      typeof props.max !== 'undefined' &&
      props.value >= props.max &&
      number > 0
    ) {
      return;
    }
    if (typeof props.step !== 'undefined') {
      onChange(props.value + number * props.step);
    } else {
      onChange(props.value + number);
    }
  };
  const onValueChange = (val: string) => {
    // if (val === '') {
    //   onChange(val);
    // } else {
    //   if (props.max && Number(val) > props.max) {
    //     onChange(props.max);
    //   } else if (props.min && Number(val) < props.min) {
    //     onChange(props.min);
    //   } else {
    //     const inVal: number = isNaN(Number(val)) ? props.min : Number(val);
    //     onChange(inVal);
    //   }
    // }
    onChange(val);
  };
  const isOverMin = React.useMemo(
    () => props.min && Number(props.value) <= props.min,
    [props.value, props.min],
  );
  const isOverMax = React.useMemo(
    () => props.max && Number(props.value) >= props.max,
    [props.value, props.max],
  );
  const handleInputBlur = (e: any) => {
    let val = e.target.value;
    if (props.max && Number(val) > props.max) {
      onChange(props.max);
    } else if (props.min && Number(val) < props.min) {
      onChange(props.min);
    } else {
      const inVal: number = isNaN(Number(val)) ? props.min : Number(val);
      onChange(inVal);
    }
  };
  return (
    <div className="number-step-container">
      <Button
        icon={<MinusOutlined />}
        disabled={props.disabled}
        className={`btn left ${isOverMin && 'disabled'}`}
        onClick={() => handleChangeValue(-1)}
      />
      <NumericInput
        type="int"
        readOnly={props.disabledEdit}
        className="input-con"
        disabled={props.disabled}
        value={props.value}
        onChange={(value: string) => onValueChange(value)}
        onBlur={handleInputBlur}
      />
      <Button
        icon={<PlusOutlined />}
        className={`btn right ${isOverMax && 'disabled'}`}
        disabled={props.disabled}
        onClick={() => handleChangeValue(1)}
      />
    </div>
  );
}
