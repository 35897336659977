import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { connect, UserModelState, Dispatch, history } from 'umi';
import GoodsItem from '@/components/Goods/GoodsItem';
// import GoodsItem from '@/components/Goods/Item';
import { useScroll } from 'ahooks';
import './Recommend.less';
const { TabPane } = Tabs;
const Banner: any = {
  hot: require('@/assets/home/recommend/banner.png'),
  new: require('@/assets/home/recommend/banner-new.png'),
};

interface IProps {
  user: UserModelState;
  dispatch: Dispatch;
}
function Index(props: IProps) {
  const scroll = useScroll(document);
  const [pagin, setPagin] = useState({
    pageSize: 10,
    pageNo: 1,
  });
  const [bannerImage, setBannerImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNoMore, setIsNoMore] = useState(false);
  const [goodsList, setGoodsList] = useState<any>([]);
  const [tabsData, setTabsData] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const [selectCategoryId, setSelectCategoryId] = useState<number | null>(null);
  const [categoryList, setCategoryList] = useState([]);
  const getCategoryList = () => {
    props
      .dispatch({
        type: 'goods/categoryList',
      })
      .then((data: any) => {
        console.log('data', data);
        setCategoryList(data);
        if (data.length) {
          setSelectCategoryId(data[0].id);
        }
      });
  };
  const init = () => {
    setPagin({
      ...pagin,
      pageNo: 1,
    });
    setIsNoMore(false);
    setLoading(false);
  };
  const onTabChange = (key: string) => {
    setActiveKey(key);
    init();
    getHomeInfoGoodsById(+key);
  };
  const handleCategoryId = (id: number) => {
    setSelectCategoryId(id);
    init();
    setActiveKey(undefined);
  };

  const getGoodsList = () => {
    if (loading || isNoMore) {
      return;
    }
    setLoading(true);
    props
      .dispatch({
        type: 'goods/homeThirdFloor',
        payload: {
          ...pagin,
          regionId: selectCategoryId,
          categoryParentId: activeKey,
          // hotOrNewSwitch: fetchType === 'hot',
        },
      })
      .then((data: any) => {
        if (data.pageNo === 1) {
          setGoodsList(data.items);
        } else {
          setGoodsList(goodsList.concat(data.items));
        }
        setIsNoMore(data.count <= pagin.pageSize * pagin.pageNo);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchId = useMemo<any>(() => {
    const id = history.location.query?.id;
    return id;
  }, [history.location.query]);
  // const bannerImage = useMemo(() => {
  //   return Banner[fetchType];
  // }, [fetchType]);

  const getHomeInfoGoodsById = (id: number) => {
    props
      .dispatch({
        type: 'goods/getHomeInfoGoodsById',
        payload: { id },
      })
      .then((data: any) => {
        setGoodsList(data.list || []);
      });
  };

  const getHomeGoodInfos = (id: string) => {
    props
      .dispatch({
        type: 'goods/getHomeGoodInfos',
        payload: {
          id,
        },
      })
      .then((data: any) => {
        setBannerImage(data.img);
        setTabsData(data.tabs);
        if (data.tabs.length > 0) {
          getHomeInfoGoodsById(data.tabs[0].id);
        }
      });
  };

  useEffect(() => {
    if (fetchId) {
      getHomeGoodInfos(fetchId);
    }
  }, [fetchId]);

  // useEffect(() => {
  //   getCategoryList();
  // }, [fetchType]);
  // const currentTabs = useMemo(() => {
  //   const item: any = categoryList.find(
  //     (item: any) => item.id === selectCategoryId,
  //   );
  //   return item
  //     ? item.categoryDTOList.map((item: any) => ({
  //       ...item,
  //       id: item.id + '',
  //     }))
  //     : [];
  // }, [selectCategoryId, categoryList]);
  // useEffect(() => {
  //   if (!Array.isArray(currentTabs) || !currentTabs.length) {
  //     setActiveKey(undefined);
  //     return;
  //   }
  //   setActiveKey(currentTabs[0].id);
  // }, [currentTabs]);
  // useEffect(() => {
  //   if (!selectCategoryId || !activeKey) {
  //     setGoodsList([]);
  //     return;
  //   }
  //   getGoodsList();
  // }, [activeKey, pagin, selectCategoryId, fetchType]);

  // useEffect(() => {
  //   // 无限加载
  //   if (scroll) {
  //     if (window.innerHeight - scroll.top <= 690 && !loading && !isNoMore) {
  //       setPagin({
  //         ...pagin,
  //         pageNo: pagin.pageNo + 1,
  //       });
  //     }
  //   }
  // }, [scroll, loading, isNoMore]);

  return (
    <div className="home-activity-container home-recommend-container">
      <div
        className="banner"
        style={{ backgroundImage: `url('${bannerImage}')` }}
      ></div>
      <div className="section">
        <div className="w1200">
          {/* <div className="category-list">
            {categoryList &&
              categoryList.map((item: any) => (
                <div
                  key={item.id}
                  className={`category-item ${item.id === selectCategoryId ? 'selected' : ''
                    }`}
                  onClick={() => handleCategoryId(item.id)}
                >
                  {item.title}
                </div>
              ))}
          </div> */}
          {tabsData && tabsData.length ? (
            <Tabs
              className="section-tabs"
              activeKey={activeKey}
              onChange={onTabChange}
            >
              {tabsData.map((tab: any) => (
                <TabPane tab={tab.name} key={tab.id}></TabPane>
              ))}
            </Tabs>
          ) : null}
          <div className="section-con">
            {goodsList &&
              goodsList.map((item: any) => (
                // <GoodsItem key={item.id} detail={item}></GoodsItem>
                <GoodsItem key={item.id} data={item} />
              ))}
          </div>
        </div>
      </div>
      {isNoMore && (
        <div className="no-more">
          <span>商品全部展示完毕</span>
        </div>
      )}
    </div>
  );
}
export default connect(({ user }: { user: UserModelState }) => ({ user }))(
  Index,
);
