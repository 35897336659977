import React, { useState } from 'react';
import { Checkbox, Image, Input } from 'antd';
import { useDispatch } from 'umi';
import './ProductItem.less';
interface GoodItem {
  showDataFlag?: boolean;
  deliverStatus?: number;
  /**
   * spu名称
   */
  spuTitle: string;
  /**
   * skuId
   */
  skuId: string;
  selectedRowKeys: any[];
  setSelectedRowKeys: () => void;
  [props: string]: any;
}
interface IProps {
  /**
   * 是否是查看
   */
  showDataFlag?: boolean;
  [props: string]: any;
}
function ProductItem(props: IProps) {
  const dispatch = useDispatch();
  /**
   *
   * @param goods 商品信息
   * status状态（0-可买，1-缺货，2-下架，3-已删除）
   * @returns
   */
  const GoodsRow = (propsGoods: GoodItem) => {
    const {
      spuTitle,
      deliverStatus,
      skuImg,
      attributeVOList,
      price,
      actualPriceSubtotal,
      acPrice,
      acSubPrice,
      preferentialPrice,
      num,
      refundNum,
      actualPrice,
      orderSkuId,
      selectedRowKeys = [],
      isPresent,
      gift,
      setSelectedRowKeys,
    } = propsGoods;
    const handleCheckChange = (e: any) => {
      const bool = e.target.checked;
      const idx = selectedRowKeys.findIndex((id: any) => id === orderSkuId);
      if (bool) {
        if (idx === -1) {
          setSelectedRowKeys([...selectedRowKeys, orderSkuId]);
        }
      } else {
        const idx = selectedRowKeys.findIndex((id: any) => id === orderSkuId);
        if (idx > -1) {
          selectedRowKeys.splice(idx, 1);
          setSelectedRowKeys([...selectedRowKeys]);
        }
      }
    };
    return (
      <div className={`good-row ${showDataFlag ? 'view' : ''}`}>
        {!showDataFlag ? (
          <div className="good-checkbox">
            <Checkbox
              checked={selectedRowKeys.includes(orderSkuId)}
              onChange={handleCheckChange}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="good-item">
          <Image className="good-img" src={skuImg} preview={false} />
          <div className="good-info">
            <div className="title">{spuTitle}</div>
            <div className="info-row">
              {attributeVOList &&
                attributeVOList.map((sku: any, index: number) => (
                  <>
                    <span className="sku-title" key={index}>
                      {sku.name}：{sku.value}
                    </span>
                    {index < attributeVOList?.length - 1 ? '; ' : ''}
                  </>
                ))}
            </div>
            {isPresent === 1 && <span className="gift-tag">赠品</span>}
            {gift && gift?.spuTitle && (
              <div className="good-gifts">
                <span className="gift-tag">赠品</span>
                <span className="gift-info">{gift.spuTitle}</span>
                <span style={{ marginLeft: 10 }}>x{gift.num}</span>
              </div>
            )}
          </div>
        </div>
        <div className="unit-price">¥{price}</div>
        <div className="unit-price">{num || refundNum || 0}</div>
        <div className="unit-price">
          {acPrice || acSubPrice || preferentialPrice || 0}
        </div>
        <div className="total-price">
          {actualPrice || actualPriceSubtotal || 0}
        </div>
        <div className="unit-price">
          {deliverStatus === 0
            ? '未发货'
            : deliverStatus === 1
            ? '已发货'
            : '-'}
        </div>
      </div>
    );
  };
  const {
    showDataFlag,
    purchaseListVOList = [],
    selectedRowKeys = [],
    setSelectedRowKeys,
  } = props;

  // 店铺是否checked
  const isStoreChecked = React.useMemo(() => {
    const selectedArr =
      (!showDataFlag &&
        purchaseListVOList.filter((skuGood: any) =>
          selectedRowKeys.includes(skuGood.orderSkuId),
        )) ||
      [];
    return selectedArr.length === purchaseListVOList.length;
  }, [selectedRowKeys]);
  //
  const handleCheckChange = (e: any) => {
    const bool = e.target.checked;
    console.log(bool);
  };
  return (
    <div className="product-item">
      <div className="p-i_goods">
        <GoodsRow
          {...props}
          showDataFlag={showDataFlag}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>
    </div>
  );
}

export default ProductItem;
