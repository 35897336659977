import React from 'react';
import { Input, InputProps } from 'antd';
interface IProps extends InputProps {
  type: 'amount' | 'int';
  onChange?: (value: any) => void;
}
export default function NumericInput(props: IProps) {
  const onChange = (e: any) => {
    let { value } = e.target;
    const reg =
      props.type === 'amount'
        ? /^-?\d*(\.\d{0,2})?$/
        : props.type === 'int'
        ? /^-?\d*$/
        : /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      if (typeof props.max === 'number' && Number(value) > props.max) {
        value = props.max + '';
      }
      props.onChange && props.onChange(value);
    }
  };
  return <Input {...props} onChange={onChange} />;
}
