import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Modal, Pagination, Empty } from 'antd';
import Breadcrumb from '@/components/Breadcrumb';
import Item from './components/FastReplenis/Item';
import Footer from './components/FastReplenis/Footer';
import Thead from './components/FastReplenis/Thead';
import { connect, OrderModelState, Loading, history } from 'umi';
import chenggongIcon from '@/assets/common/chenggong.svg';
import './FastReplenis.less';
const { Option } = Select;
const { Search } = Input;
interface IProps {
  [props: string]: any;
}
function FastReplenis(props: IProps) {
  const [form] = Form.useForm();
  const [tenantList, setTenantList] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isShowAppToListSuccess, setIsShowAppToListSuccess] = useState(false);
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const onFinish = () => {};
  const onSearch = () => {
    getFrequentList();
  };

  // 历史商品list
  const getFrequentList = () => {
    const spuTitle = form.getFieldValue('spuTitle');
    const tenantId = form.getFieldValue('tenantId');
    const temParams: any = {
      ...params,
    };
    if (tenantId || spuTitle) {
      temParams.item = {
        tenantId,
        spuTitle,
      };
    }
    props
      .dispatch({
        type: 'order/frequentList',
        payload: temParams,
      })
      .then((data: any) => {
        let tempTableData = data.items || [];
        tempTableData = tempTableData.map((item: any) => {
          const tempVOList = item.frequentListVOList || [];
          return {
            ...item,
            frequentListVOList: tempVOList.map((skuGood: any) => ({
              ...skuGood,
              num: 0,
            })),
          };
        });
        setTableData(tempTableData);
        setTotal(data.count || 0);
      });
  };
  useEffect(() => {
    getFrequentList();
  }, [params]);
  // 商家列表
  const queryTenantIdList = () => {
    props
      .dispatch({
        type: 'order/queryTenantIdList',
        payload: {
          ...params,
          items: {
            ...form.getFieldsValue(),
          },
        },
      })
      .then((data: any) => {
        setTenantList(data);
      });
  };
  // 修改数量
  const handleNumberStep = (
    val: any,
    item: any,
    parentIndex: number,
    currentIndex: number,
  ) => {
    tableData[parentIndex].frequentListVOList[currentIndex].num = val;
    setTableData([...tableData]);
  };

  /**
   * 加入进货单
   */
  const handleAddPurchaseList = () => {
    const skuTemp = tableData.map((row: any) => row.frequentListVOList).flat(2);
    const skuIds: any[] = [];
    skuTemp.map((row: any) => {
      if (row.num > 0) {
        skuIds.push({
          skuId: row.skuId,
          skuNum: row.num,
          presentNum: 0,
          totalNum: row.num,
        });
      }
    });
    props
      .dispatch({
        type: 'order/checkStock',
        payload: skuIds,
      })
      .then(() => {
        props
          .dispatch({
            type: 'goods/addPurchaseListForList',
            payload: skuIds?.map((item: any) => ({
              skuId: item.skuId,
              num: item.skuNum,
            })),
          })
          .then((data: any) => {
            if (data) {
              setIsShowAppToListSuccess(true);
            }
          });
      });
  };
  const handleCart = () => {
    history.push('/order/shopping');
  };

  const handleSubmit = () => {
    const skuTemp = tableData.map((row: any) => row.frequentListVOList).flat(2);
    const skuIds: any = skuTemp.map((row: any) => ({
      skuId: row.skuId,
      num: row.num,
    }));
    history.push({
      pathname: '/order/confirm',
      query: {
        orderRequestSkuROList: skuIds,
      },
    });
  };

  useEffect(() => {
    queryTenantIdList();
  }, []);

  return (
    <div className="fast-replenishment">
      <Breadcrumb />
      <div className="search-container">
        <Form layout="inline" form={form} onFinish={onFinish}>
          <Form.Item name="tenantId" label="">
            <Select style={{ width: 190 }} allowClear placeholder="全部商家">
              {tenantList.map((tenant: any) => (
                <Option key={tenant.tenantId} value={tenant.tenantId}>
                  {tenant.tenantName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="spuTitle" label="">
            <Search
              allowClear
              enterButton="搜索"
              style={{ width: 312 }}
              placeholder="请输入货品名称"
              onSearch={onSearch}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="content">
        <Thead />
        {total > 0 && (
          <div className="pagination">
            <Pagination
              current={params.pageNo}
              total={total}
              showSizeChanger={false}
              showQuickJumper
              onChange={(page, pageSize) => {
                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
                setParams({
                  ...params,
                  pageNo: page,
                });
              }}
            />
          </div>
        )}
        {tableData.map((row: any, index: number) => (
          <Item
            {...row}
            key={index}
            parentIndex={index}
            handleNumberStep={handleNumberStep}
          />
        ))}
        {total > 0 && (
          <div className="pagination">
            <Pagination
              current={params.pageNo}
              total={total}
              showSizeChanger={false}
              showQuickJumper
              onChange={(page, pageSize) => {
                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
                setParams({
                  ...params,
                  pageNo: page,
                });
              }}
            />
          </div>
        )}
        {total === 0 && (
          <Empty
            className="custom"
            imageStyle={{ marginTop: 100, height: 195 }}
            style={{ marginBottom: 121 }}
            image={require('@/assets/common/empty-cart.png')}
            description="快速补货空空如也，快去挑好货吧～"
          />
        )}
        <Footer
          purchaseLoading={
            props.loading.effects['goods/addPurchaseListForList']
          }
          handleAddPurchaseList={handleAddPurchaseList}
          handleSubmit={handleSubmit}
          list={tableData}
        />
      </div>
      <Modal
        title={null}
        footer={null}
        visible={isShowAppToListSuccess}
        onCancel={() => setIsShowAppToListSuccess(false)}
        className="add-success-modal"
        centered
      >
        <div className="add-success-body">
          <img src={chenggongIcon} alt="" className="success-icon" />
          <div className="content">
            <h1>已将货品添加到进货单！</h1>
            <p>多个货品添加完毕后您可以在右上方进货单进行结算</p>
          </div>
          <div className="checkout-icon" onClick={handleCart}>
            去进货单结算
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(FastReplenis);
