import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Radio, message } from 'antd';
import { OrderModelState, Dispatch, connect, Loading } from 'umi';
import CategoryCascader from '@/components/Goods/CategoryCascader';
import './index.less';
interface IProps {
  visible: boolean;
  skus: [];
  orderId: string | number;
  onCancel: () => void;
  onSuccess: () => void;
  order: OrderModelState;
  loading: Loading;
  dispatch: Dispatch;
}
function WarehousingModal(props: IProps) {
  console.log('弹窗数据在这', props);
  const [form] = Form.useForm();
  const [categoryNameList, setCategoryNameList] = useState<any>([]);
  useEffect(() => {
    form.setFieldsValue({
      status: 1,
    });
    props.skus &&
      props.skus.forEach((ele: any, index: number) => {
        form.setFieldsValue({
          ['categoryIds' + index]:
            ele.categorySpuVO.categoryIds &&
            ele.categorySpuVO.categoryIds.split(',').map(Number),
        });
      });
  }, []);
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const formData = { status: values.status };
      let newArr: any = [];
      props.skus &&
        props.skus.forEach((ele: any, idx: number) => {
          newArr.push({
            skuId: ele.skuId,
            spuId: ele.spuId,
            categoryId:
              values['categoryIds' + idx][
                values['categoryIds' + idx].length - 1
              ],
            num: ele.num,
            categoryName:
              categoryNameList[idx] || ele.categorySpuVO.categoryNames,
          });
        });
      props
        .dispatch({
          type: 'userOrder/wareHousing',
          payload: {
            ...formData,
            orderId: props.orderId,
            warehousingROs: [...newArr],
          },
        })
        .then(() => {
          message.success(
            `商品已完成一键入库${
              formData.status !== 0 ? '，并同时上架到门店小程序/收银系统' : ''
            }`,
          );
          props.onSuccess();
        });
    });
  };
  return (
    <Modal
      wrapClassName="purchase-warehousing-modal"
      destroyOnClose
      title="一键入库"
      visible={props.visible}
      width="640px"
      onCancel={props.onCancel}
      onOk={handleSubmit}
      confirmLoading={props.loading.effects['userOrder/wareHousing']}
    >
      <Form
        className="purchase-warehousing-form"
        form={form}
        wrapperCol={{ span: 10 }}
        labelCol={{ span: 11 }}
      >
        {props.skus &&
          props.skus.map((ele: any, index: number) => {
            return (
              <Form.Item
                key={ele.id}
                label={ele.spuTitle}
                name={'categoryIds' + index}
                rules={[
                  {
                    required: true,
                    message: '请选择商品类目',
                  },
                ]}
              >
                <CategoryCascader
                  disabled={ele.categorySpuVO.categoryId}
                  expandTrigger="hover"
                  placeholder="请选择商品类目"
                  onChange={(val, selectedOptions: any) =>
                    (categoryNameList[index] =
                      selectedOptions[selectedOptions.length - 1].fullName)
                  }
                />
              </Form.Item>
            );
          })}
        <Form.Item label="一键上架到门店小程序/门店收银系统" name="status">
          <Radio.Group>
            <Radio value={1}>是</Radio>
            {/* <Radio value={0}>否</Radio> */}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(WarehousingModal);
