import React from 'react';
import { GoodsHotList, history } from 'umi';
import './index.less';
interface IProps {
  detail: GoodsHotList;
  idx: number;
}
export default function RecommentItem(props: IProps) {
  const handleClick = (id: number) => {
    history.push('/goods/detail/' + id);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  return (
    <div
      className="recomment-item-container"
      onClick={() => handleClick(props.detail.spuId)}
    >
      <img className="image" src={props.detail.imgSrc} alt="" />
      <h3 className="r-i-title">{props.detail.title}</h3>
      <div className="info">
        <p className="price">¥{props.detail.price}</p>
        <p className="sale">已售 {props.detail.sales}</p>
      </div>
      <div className={`hot-tag bg bg${props.idx + 1}`}>
        <span className="t">TOP{props.idx + 1}</span>
      </div>
    </div>
  );
}
