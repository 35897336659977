import { Effect, request, Reducer, Subscription, history } from 'umi';
import { fixAmount } from '@/utils';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
export interface OrderModelState {
  purchaseList: IPurchaseItem[];
  ineffectivePurchaseListVOList: IPurchaseItem[];
  purchaseListLen: number;
}

export interface ISkuAttribute {
  attributeId: null | number;
  name: string;
  value: string;
}
export interface IPurchaseItem {
  img: string;
  num: number;
  price: number;
  priceSubtotal: number;
  purchaseListId: number;
  skuAttributeVOList: ISkuAttribute[];
  spuTitle: string;
  status: 0 | 1;
  stock: number;
}

export interface IndexModelType {
  state: OrderModelState;
  effects: {
    addPurchaseList: Effect;
    purchaseList: Effect;
    getPurchaseListAllSkuNum: Effect;
    updatePurchaseNum: Effect;
    deletePurchase: Effect;
    getHomeOrderNum: Effect;
    calcOrderAmount: Effect;
    takeOrder: Effect;
    getTakeOrderInfo: Effect;
    getOrderStatus: Effect;
    prepay: Effect;
    payment: Effect;
    frequentList: Effect;
    queryTenantIdList: Effect;
    queryFreight: Effect;
    checkStock: Effect;
    confirmCalcApplyRefund: Effect;
    transferOrder: Effect;
  };
  reducers: {
    save: Reducer<OrderModelState>;
    updatePurchaseListItemByPurchaseListId: Reducer<any>;
    // 启用 immer 之后
    // save: ImmerReducer<OrderModelState>;
  };
  subscriptions?: { setup: Subscription };
}

const OrderModal: IndexModelType = {
  state: {
    purchaseList: [],
    ineffectivePurchaseListVOList: [],
  },

  effects: {
    *addPurchaseList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/purchaseList/addPurchaseList',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      yield put({ type: 'getPurchaseListAllSkuNum' });
      return res.data;
    },

    *purchaseList({ payload }, { call, put }) {
      const pageSize = payload ? payload.pageSize || 999 : 999;
      const res = yield call(
        request,
        '/supplier-order-center/purchase/purchaseList/list',
        {
          method: 'get',
          params: { ...payload, pageNo: 1, pageSize, shippingChannel: 1 },
        },
        { loading: true },
      );
      if (res.data) {
        yield put({
          type: 'save',
          payload: {
            purchaseList: res.data.purchaseSkuListBySupplierVOList || [],
            ineffectivePurchaseListVOList:
              res.data.ineffectivePurchaseListVOList || [],
          },
        });
      }
      yield put({ type: 'getPurchaseListAllSkuNum' });
      return res.data;
    },
    *transferOrder({ payload }, { call, put }) {
      const pageSize = payload ? payload.pageSize || 999 : 999;
      const res = yield call(
        request,
        '/supplier-pay-center/proof/bank',
        {
          method: 'get',
          params: { ...payload },
        },
        { loading: true },
      );

      return res.data;
    },
    // 对公转账
    *transferPay({ payload }, { call, put }) {
      const pageSize = payload ? payload.pageSize || 999 : 999;
      const res = yield call(
        request,
        '/supplier-pay-center/proof/payment',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );

      return res;
    },
    *updatePurchaseNum({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/purchaseList/updateNum',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      yield put({ type: 'purchaseList' });
      return res.data;
    },
    // 删除进货单
    *deletePurchase({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/purchaseList/delByIds',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      if (res.data) {
        message.success('删除成功');
        yield put({ type: 'purchaseList' });
        yield put({ type: 'getPurchaseListAllSkuNum' });
      }
      return res.data;
    },
    *getHomeOrderNum({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/getHomeOrderNum',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *getPurchaseListAllSkuNum({ payload }, { call, put, select }) {
      const token = yield select((state: any) => state.user.token);
      if (!token) {
        return;
      }
      const res = yield call(
        request,
        '/supplier-order-center/purchase/purchaseList/getPurchaseListAllSkuNum',
        {
          method: 'get',
          params: payload,
        },
        { loading: false },
      );
      if (typeof res.data === 'number') {
        yield put({ type: 'save', payload: { purchaseListLen: res.data } });
      }
      return res.data;
    },
    *calcOrderAmount({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/calcOrderAmount',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *takeOrder({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/takeOrder',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      // yield put({ type: 'getPurchaseListAllSkuNum' }); // 获取进货单数量
      return res.data;
    },
    *getTakeOrderInfo({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/getTakeOrderInfo',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      yield put({ type: 'getPurchaseListAllSkuNum' });
      return res.data;
    },
    *getOrderStatus({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/getOrderStatus',
        {
          method: 'post',
          data: payload,
        },
        { loading: false },
      );
      return res.data;
    },
    *prepay({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/orderPay/alipay/prepay',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    // 支付
    *payment({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-pay-center/miniapp/pay/payment',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    // 快速补货
    *frequentList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/frequent/list',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    // 商家列表
    *queryTenantIdList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/order/frequent/queryTenantIdList',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    // 商家列表
    *queryFreight({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-order-center/prm/freight/${payload.id}`,
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    // 校验库存
    *checkStock({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-order-center/purchase/purchaseList/checkStock`,
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    // 预计算
    *confirmCalcApplyRefund({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-order-center/purchase/refund/order/calcApplyRefund`,
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updatePurchaseListItemByPurchaseListId(state, action) {
      const { purchaseListId, gift, num } = action.payload;
      const index = state.purchaseList.findIndex(
        (item: any) => item.purchaseListId === purchaseListId,
      );
      const temp = cloneDeep(state.purchaseList);
      const item = temp[index];
      temp.splice(index, 1, {
        ...item,
        gift: gift.spuId ? gift : null,
        num,
        priceSubtotal: fixAmount(num * item.price),
      });
      return {
        ...state,
        purchaseList: temp,
      };
    },
  },
  subscriptions: {
    setup({ dispatch }) {
      return history.listen(async ({ pathname }) => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        if (pathname === '/order/shopping') {
          await dispatch({
            type: 'purchaseList',
          });
        }
      });
    },
  },
};

export default OrderModal;
