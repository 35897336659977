import { connect, history } from 'umi';
import './index.less';

export default connect((store) => {
  return store;
})((props: any) => {
  const goodInfo = props.goodInfo;

  const handleDetail = () => {
    history.push(`/goods/detail/${goodInfo.spuId}`);
  };

  return (
    <div className="big_customer_good" onClick={handleDetail}>
      <div className="main_img">
        <img src={goodInfo.imgSrc} alt="" />
      </div>
      <div className="price_info">
        <span className="price">
          <span className="price_unit">¥ </span>
          {goodInfo.price}
        </span>
        <span className="sale_num">已售{goodInfo.sales}</span>
      </div>
      <div className="good_title">{goodInfo.title}</div>
      <div className="tag_list">
        {goodInfo?.salesPromotion?.map((item: any) => {
          return (
            <div key={item} className="tag">
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
});
