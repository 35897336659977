import React, { useEffect, useState } from 'react';
import Breadcrumb from '@/components/Breadcrumb';
import './detail.less';
import { connect, Dispatch } from 'umi';
interface IProps {
  dispatch: Dispatch;
  match: any;
}
function Index(props: IProps) {
  const [detail, setDetail] = useState<any>({
    content: '',
    gmtUpdate: '',
    title: '',
  });
  useEffect(() => {
    const id = props.match.params.id;
    if (id) {
      props
        .dispatch({
          type: 'goods/noticeDetail',
          payload: {
            id,
          },
        })
        .then((data: any) => {
          setDetail(data);
        });
    }
  }, [props.match.params.id]);
  return (
    <div className="notice-detail-container">
      <Breadcrumb />
      <div className="notice-detail-body">
        <div className="header">
          <h1 className="title">{detail.title}</h1>
          <p className="subtitle">发布时间：{detail.gmtUpdate}</p>
        </div>
        <div className="w1200">
          {detail.content ? (
            <div
              className="notice-detail-content"
              dangerouslySetInnerHTML={{ __html: detail.content }}
            ></div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default connect()(Index);
