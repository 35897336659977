import { cloneDeep } from 'lodash';
import { message } from 'antd';
export const fixAmount = (amount: string | number) => {
  return Math.floor(Number(amount) * 100 + 0.000001) / 100.0;
};
export const spliceByIndex = (index: number, array: any[]) => {
  const tempArray = cloneDeep(array);
  tempArray.splice(index, 1);
  return tempArray;
};
export const getLabelByValueFromEnum = (
  value: string | number,
  enumData: any[],
) => {
  const item = enumData.find((item) => item.value === value);
  if (item) {
    return item.label;
  }
  return '';
};
export const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};
export function getBroswer() {
  var sys: any = {};
  var ua = navigator.userAgent.toLowerCase();
  var s;
  (s = ua.match(/edge\/([\d.]+)/))
    ? (sys.edge = s[1])
    : (s = ua.match(/rv:([\d.]+)\) like gecko/))
    ? (sys.ie = s[1])
    : (s = ua.match(/msie ([\d.]+)/))
    ? (sys.ie = s[1])
    : (s = ua.match(/firefox\/([\d.]+)/))
    ? (sys.firefox = s[1])
    : (s = ua.match(/chrome\/([\d.]+)/))
    ? (sys.chrome = s[1])
    : (s = ua.match(/opera.([\d.]+)/))
    ? (sys.opera = s[1])
    : (s = ua.match(/version\/([\d.]+).*safari/))
    ? (sys.safari = s[1])
    : 0;

  if (sys.edge) return { broswer: 'edge', version: sys.edge };
  if (sys.ie) return { broswer: 'ie', version: sys.ie };
  if (sys.firefox) return { broswer: 'firefox', version: sys.firefox };
  if (sys.chrome) return { broswer: 'chrome', version: sys.chrome };
  if (sys.opera) return { broswer: 'opera', version: sys.opera };
  if (sys.safari) return { broswer: 'safari', version: sys.safari };

  return { broswer: '', version: '0' };
}
export function base64ToFile(base64: string, filename: string) {
  var arr = base64.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new File([u8arr], filename, { type: mime });
  Object.assign(file, { uid: file.lastModified });
  return file;
}
export const isMac = /macintosh|mac os x/i.test(navigator.userAgent);

export const fixNumber = (number: number) => {
  if (number < 10) {
    return '0' + number;
  }
  return '' + number;
};
export const isEqualIfArray = (arr1: any[], arr2: any[]) => {
  let res = false;
  for (let index = 0; index < arr1.length; index++) {
    const element = arr1[index];
    res = JSON.stringify(arr2).indexOf(JSON.stringify(element)) > -1;
    if (!res) {
      return false;
    }
  }
  return res;
};

export const ossProcess = (value?: string, size = 'h_256,w_256') => {
  if (!value) return '';
  return `${value}?x-oss-process=image/resize,${size},m_fill`;
};
export const useLeftTime = (diff: number): string[] => {
  if (diff <= 0) {
    return ['00', '00', '00'];
  }
  const HH = fixNumber(Math.floor(diff / (1000 * 60 * 60)));
  const mm = fixNumber(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
  const ss = fixNumber(Math.floor((diff / 1000) % 60));
  return [HH, mm, ss];
};
// 复制 如下换行设置 \r\n
export const copy = (copyText: string) => {
  // let copyText = "分享文字：\r\n" + this.shareQrcodeUrl + "\r\n";
  copyText = copyText;
  const input = document.createElement('textarea');
  document.body.appendChild(input);
  input.innerHTML = copyText;
  input.setAttribute('code', '1');
  input.select();
  document.execCommand('Copy');

  var list = document.getElementsByTagName('textarea');
  var inputList = Array.prototype.slice.call(list);
  inputList.forEach((item) => {
    if (item.getAttribute('code')) document.body.removeChild(item);
  });
  message.success('复制成功');
};
