import React, { useEffect, useMemo, useState } from 'react';
import './index.less';
import Icon from '@/components/Icon';
import { RightOutlined } from '@ant-design/icons';
import { connect, GoodsModelState, Dispatch } from 'umi';
const Icons = {
  狗狗: require('@/assets/common/gou.png'),
  猫猫: require('@/assets/common/maomao.png'),
  通用: require('@/assets/common/tongyong.png'),
};
const defaultCategoryList: any = {
  狗狗: {
    icon: Icons.狗狗,
    subtitle: '狗粮 狗零食',
  },
  猫猫: {
    icon: Icons.猫猫,
    subtitle: '猫粮 猫窝',
  },
  通用: {
    icon: Icons.通用,
    subtitle: '外出包 湿巾',
  },
};

import iconImg from '@/assets/home/dog_icon.png';

interface IProps {
  goods: GoodsModelState;
  visible: Boolean;
  dispatch: Dispatch;
  onClick?: (item: any) => void;
}
function HMenuList(props: IProps) {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryOtherList, setCategoryOtherList] = useState([]);
  const handleClick = (item: any) => {
    props.onClick && props.onClick(item);
  };

  const getAllCategory = (parentId: any) => {
    props
      .dispatch({
        type: 'goods/getAllCategory',
        payload: {
          parentId,
        },
      })
      .then((data: any) => {
        setCategoryOtherList(data);
      });
  };

  const handleonMouseEnter = (id: string | number) => {
    getAllCategory(id);
  };

  const handleOnMouseLeave = () => {
    // setCategoryOtherList([]);
  };

  useEffect(() => {
    props
      .dispatch({
        type: 'goods/getAllCategory',
        payload: {
          parentId: 0,
        },
      })
      .then((data: any) => {
        setCategoryList(data);
      });
  }, []);

  return (
    <>
      {props.visible && (
        <div className="menu-list">
          <div className="all-title">
            <Icon className="icon" type="iconchongwufenlei" />
            <span className="t">商品分类</span>
          </div>
          <div className="category-list">
            {categoryList.map((item: any) => (
              <div
                className="category-item"
                key={item.id}
                onMouseEnter={() => handleonMouseEnter(item.id)}
                onMouseLeave={handleOnMouseLeave}
              >
                {/* <img className="icon" src={item.icon} alt="" /> */}
                <img className="icon" src={iconImg} alt="" />
                <p className="title">{item.title}</p>
                {/* <div className="right-con">
                  <p className="subtitle">{item.subtitle}</p>
                </div> */}
                <div className="category-children-con">
                  {categoryOtherList.map((children: any) => (
                    <div className="category-children-item" key={children.id}>
                      <div className="category-level-1">
                        {children.title}{' '}
                        <RightOutlined className="icon-right" />
                      </div>
                      <div className="category-level-2-list">
                        {children.childList &&
                          children.childList.map((parentChild: any) => (
                            <div
                              className="category-level-2"
                              key={parentChild.id}
                              onClick={() => handleClick(parentChild)}
                            >
                              {parentChild.title}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default connect(({ goods }: { goods: GoodsModelState }) => ({ goods }))(
  HMenuList,
);
