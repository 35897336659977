import React, { useState, useEffect } from 'react';
import Icon from '@/components/Icon';
import { useDispatch, history } from 'umi';
import './index.less';
{
  /* <Icon type="icondianneifenlei-zhankai" />
<Icon type="icondianneifenlei-shouqi" /> */
}
interface IProps {
  tenantId: number | string;
  companyName: string;
  /**
   * childItem 点击的item
   * childIndex 点击的item index
   * parentItem 父级Item
   */
  handleChildClick?: (
    childItem: any,
    childIndex: number,
    parentItem: any,
  ) => void;
  getList?: (list: any[]) => void;
  isShowAll: boolean;
}
interface ICategory {
  id: number;
  /**
   * 分类名字
   */
  title: string;
  /**
   * 分类级别
   */
  level: number;
}
function StoreCategoryList(props: IProps) {
  const dispatch = useDispatch();
  const [treeData, setTreeData] = useState<ICategory[]>([]);
  const handleItemClick = (item: any, index: number) => {
    item.expand = !item.expand;
    treeData[index] = item;
    setTreeData([...treeData]);
  };
  const getCategory = () => {
    dispatch({
      type: 'goods/getCategory',
      payload: {
        type: 1,
        tenantId: props.tenantId,
      },
    }).then((data: any) => {
      const newData = data.map((category: any) => {
        category.expand = false;
        category.childList = category.childList.map((childItem: any) => {
          childItem.expand = false;
          return childItem;
        });
        return category;
      });
      setTreeData(newData);
      props.getList && props.getList(newData);
    });
  };
  const handleChildClick = (
    childItem: any,
    childIndex: number,
    parentItem: any,
  ) => {
    props.handleChildClick &&
      props.handleChildClick(childItem, childIndex, parentItem);
  };
  const handleGoto = (pathname: string, query?: any) => {
    history.push({
      pathname,
      query,
    });
  };
  useEffect(() => {
    if (props.tenantId) getCategory();
  }, [props.tenantId]);
  return (
    <div className="store-category-list">
      {props.companyName && (
        <div
          className="s-c-title"
          onClick={() =>
            props.tenantId && handleGoto(`/shop/${props.tenantId}/category`)
          }
        >
          <Icon className="s-c-icon_dianpu" type="icona-dianpu-42" />
          <span className="name">{props.companyName || ''}</span>
        </div>
      )}
      <div className="s-c-info">
        <span>店内分类</span>
      </div>
      <div className="s-c-list">
        {props.isShowAll ? (
          <div
            className={`s-c-item `}
            onClick={() => handleChildClick(null, -1, null)}
          >
            <span>全部</span>
          </div>
        ) : (
          <></>
        )}

        {treeData.map((item: any, index: number) => (
          <div key={index}>
            <div
              className={`s-c-item ${item.expand ? 'expand' : ''}`}
              onClick={() => handleItemClick(item, index)}
            >
              {item.expand ? (
                <Icon className="s-c-expand" type="icondianneifenlei-zhankai" />
              ) : (
                <Icon className="s-c-expand" type="icondianneifenlei-shouqi" />
              )}
              <span>{item.title}</span>
            </div>
            {item.childList &&
              item.childList.map((childrenItem: any, childrenIndex: number) => (
                <div
                  className={`s-c-children-item ${item.expand ? 'expand' : ''}`}
                  key={childrenIndex}
                  onClick={() =>
                    handleChildClick(childrenItem, childrenIndex, item)
                  }
                >
                  {childrenItem.title}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default StoreCategoryList;
