import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs } from 'swiper/core';
import './index.less';

interface SwiperItem {
  type: string;
  src?: string;
  img: string;
}
interface IGoodsDetailSwiperProps {
  list: SwiperItem[];
}
SwiperCore.use([Thumbs]);
export default function GoodsDetailSwiper(props: IGoodsDetailSwiperProps) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const slideTo = (index: number) => {
    swiper.slideTo(index);
  };
  return (
    <div className="goods-detail-swiper-container">
      <Swiper
        className="swiper-con"
        navigation={true}
        onSwiper={setSwiper}
        width={420}
        thumbs={{ swiper: thumbsSwiper }}
        navigation={false}
      >
        {props.list.map((item, index) => (
          <SwiperSlide key={index}>
            {item.type === 'video' ? (
              <video
                className="swiper-video"
                src={item.src}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                disableRemotePlayback
                height="200px"
                preload="metadata"
              ></video>
            ) : (
              <div
                className="swiper-image"
                style={{ backgroundImage: `url('${item.img}')` }}
              ></div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-thumb-con">
        <div
          className="gd-swiper-button-prev"
          onClick={() => swiper.slidePrev()}
        >
          <img
            className="icon"
            src={require('@/assets/common/zuo.svg')}
            alt=""
          />
        </div>
        <div
          className="gd-swiper-button-next"
          onClick={() => swiper.slideNext()}
        >
          <img
            className="icon"
            src={require('@/assets/common/you.svg')}
            alt=""
          />
        </div>
        <Swiper
          className="swiper-con"
          onSwiper={setThumbsSwiper}
          freeMode={true}
          spaceBetween={16}
          slidesPerView={4}
          width={336}
        >
          {props.list.map((item, index) => (
            <SwiperSlide key={index} onClick={() => slideTo(index)}>
              <div
                className="swiper-image"
                style={{ backgroundImage: `url('${item.img}')` }}
              ></div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
