import { Effect, request, Reducer, Subscription } from 'umi';
import { message } from 'antd';
import { ossProcess } from '@/utils';
export interface GoodsSkuDetailList {
  key: string;
  value: string;
}
export interface GoodsSkuDetail {
  minPrice: number | string; // 活动价
  cooperationPrice: number | string; // 大客户价格
  costPrice: number | string; // 成本价
  id?: number;
  img: string;
  issuingPrice: number | string; // 代发价
  list: GoodsSkuDetailList[];
  retailPrice: number | string; // 零售价
  spotPrice: number | string; // 直采价
  stock: number | string;
  purchaseGiftVO: any;
  /**
   * 起订量
   */
  miniNum: number | string;
  suggestedPrice: number | string; // 建议价
}
export interface GoodsSkuSplicings {
  key: string;
  title: string[];
}
export interface GiveInfoItemType {
  activityName: string;
  attributeName: string;
  id: number;
  img: string;
  price: number;
  retailPrice: number;
  skuId: number;
  spuId: number;
  stock: number;
  title: string;
}
export interface GoodsModelState {
  goodsId: number;
  detail: {
    img: string;
    imgs: string[];
    video: string;
    freight: number;
    id: number | null;
    status: 0 | 1;
    title: string;
    purchaseGiftVO?: {
      activityName: string;
      fullNum?: number;
      presentNum: number;
      spuId: number;
      spuTitle: string;
    };
    sales: number;
    unit: string;
    suitObject: string;
    isConsignment: boolean;
    descriptions: string[];
    brandName: string;
    tenantId: number | string;
    categoryId: number;
    salesPromotion: string[];
    giveInfos: GiveInfoItemType[];
  };
  skuDetail: {
    skuDetails: GoodsSkuDetail[];
    skuSplicings: GoodsSkuSplicings[];
  };
  brandList: any[];
}

export interface IndexModelType {
  state: GoodsModelState;
  effects: {
    detail: Effect;
    skuDetail: Effect;
    distribution: Effect;
    list: Effect;
    hotList: Effect;
    categorySecondLevelTree: Effect;
    addPurchaseList: Effect;
    addPurchaseListForList: Effect;
    getCategory: Effect;
    getBanner: Effect;
    getGoodsVague: Effect;
    freightCalculate: Effect;
    couponReceive: Effect;
    couponListCanReceive: Effect;
    couponListCanReceiveBySpuId: Effect;
    couponListCanReceiveBySpuIds: Effect;
    userCouponList: Effect;
    userCouponListCanUse: Effect;
    couponListReceivedBySpuId: Effect;
    couponListHasBySpuId: Effect;
    getFullPresentGoodsPage: Effect;
    getBrandList: Effect;
    categoryList: Effect;
    homeFourthFloor: Effect;
    homeFirstFloor: Effect;
    homeSecondFloor: Effect;
    homeThirdFloor: Effect;
    categoryRegionList: Effect;
    homeFourthFloorDetail: Effect;
    noticeList: Effect;
    noticeDetail: Effect;
    storeCategoryList: Effect;
    spuRolledOut: Effect;
    couponSum: Effect;
    goodsDetailCouponList: Effect;
    getHomeActivitys: Effect;
    getHomeRecommends: Effect;
    getHomeRecommendGoodsById: Effect;
    getGoodColumns: Effect;
    getColumnGoodsById: Effect;
    getHomeGoodInfos: Effect;
    getHomeInfoGoodsById: Effect;
    getAllCategory: Effect;
    fetchDefaultAddress: Effect;
  };
  reducers: {
    save: Reducer<GoodsModelState>;
    changeSideModal: Reducer<GoodsModelState>;
    // 启用 immer 之后
    // save: ImmerReducer<GoodsModelState>;
  };
  subscriptions?: { setup: Subscription };
}

const GoodsModel: IndexModelType = {
  state: {
    goodsId: 0,
    detail: {
      img: '',
      imgs: [],
      id: null,
      title: '--',
      unit: '--',
      suitObject: '',
      isConsignment: false,
      descriptions: [],
      brandName: '--',
    },
    skuDetail: {
      skuDetails: [],
      skuSplicings: [],
    },
    brandList: [],
    showSiderModal: false,
    selectSiderIndex: -1,
  },

  effects: {
    *detail({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { skuDetail: { skuSplicings: [], skuDetails: [] } },
      });
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/spu/getGoodsDetail',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      if (res.data) {
        yield put({ type: 'save', payload: { detail: res.data } });
      }
      yield put({ type: 'skuDetail', payload });
      yield put({ type: 'hotList', payload });
      return res.data;
    },
    *skuDetail({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/sku/getGoodsSku',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      if (res.data) {
        yield put({ type: 'save', payload: { skuDetail: res.data } });
      }
      return res.data;
    },
    *distribution({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/spu/rolledOut',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *addPurchaseList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/purchaseList/addPurchaseList',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    // 添加进货单 list
    *addPurchaseListForList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-order-center/purchase/purchaseList/addPurchaseListForList',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *list({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/spu/list',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *hotList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/spu/list',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *categorySecondLevelTree({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/goods-center/admin/category/categorySecondLevelTree',
        {
          method: 'get',
          params: payload,
          store: true,
        },
        { loading: true },
      );
      return res.data;
    },
    *getCategory({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/category/getCategory',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *getBanner({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/banner/getBanner',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *getGoodsVague({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/spu/getGoodsVague',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *freightCalculate({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/freight/calculate',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *couponListCanReceive({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/coupon/list',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *couponListCanReceiveBySpuId({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/coupon/receive/spu/list/' + payload,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *couponListCanReceiveBySpuIds({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/coupon/receive/spuIds/list',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *couponListReceivedBySpuId({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/coupon/already/receive/spu/list/' +
          payload,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    // 获取能领取的优惠券
    *couponListHasBySpuId({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/coupon/receive/spu/list/' + payload,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *couponReceive({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/coupon/receive',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *userCouponList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/user/coupon/list',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *userCouponListCanUse({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/user/coupon/list/use',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *goodsDetailCouponList({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/purchase/coupon/spu/list/${payload}`,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *getFullPresentGoodsPage({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/spu/getFullPresentGoodsPage',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *getBrandList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/purchase/brand/brand_list',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      yield put({
        type: 'save',
        payload: {
          brandList: res.data.map((item: any) => ({
            ...item,
            logoUrl: ossProcess(item.logoUrl, 'h_48,w_116'),
          })),
        },
      });
      return res.data;
    },
    *categoryList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/categoryRegion/platform/list',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *homeFirstFloor({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/prm/home-page/first-floor',
        {
          method: 'get',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *homeSecondFloor({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/prm/home-page/second-floor',
        {
          method: 'get',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *homeFourthFloor({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/prm/home-page/fourth-floor',
        {
          method: 'get',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *homeFourthFloorDetail({ payload }, { call, put }) {
      // type: 0-满赠 1-满减
      const res = yield call(
        request,
        `/supplier-goods-center/prm/home-page/fourth-floor/${payload.type}`,
        {
          method: 'get',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *homeThirdFloor({ payload }, { call, put }) {
      console.log('payload', payload);
      const res = yield call(
        request,
        '/supplier-goods-center/prm/home-page',
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *categoryRegionList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/prm/home-page/categoryRegionList',
        {
          method: 'get',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *noticeList({ payload }, { call, put }) {
      const res = yield call(
        request,
        '/supplier-goods-center/notice/platform/list',
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *noticeDetail({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/notice/byId/${payload.id}`,
        {
          method: 'post',
          data: payload,
        },
        { loading: true },
      );

      return res.data;
    },
    *couponSum(_, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/purchase/user/coupon/sum`,
        {
          method: 'get',
          params: {
            userType: 1, // 1门店集采商城用户，2小程序用户
          },
        },
        { loading: true },
      );

      return res.data;
    },
    *getHomeActivitys({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/prm/home-page/fourth-floor`,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *getHomeRecommends({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/home-page/columns`,
        {
          method: 'get',
          params: {
            pageNo: 1,
            pageSize: 999,
          },
        },
        { loading: true },
      );
      return res.data;
    },
    *getHomeRecommendGoodsById({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/home-page/columns/${payload.id}`,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *getHomeGoodInfos({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/prm/home-page/fourth-floor/${payload.id}`,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *getHomeInfoGoodsById({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/prm/home-page/fourth-floor-info/${payload.id}`,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *getGoodColumns({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/home-page/columns`,
        {
          method: 'get',
          params: {
            pageNo: 1,
            pageSize: 999,
          },
        },
        { loading: true },
      );
      return res.data;
    },
    *getColumnGoodsById({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/home-page/columns/${payload.id}`,
        {
          method: 'get',
        },
        { loading: true },
      );
      return res.data;
    },
    *getAllCategory({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-goods-center/prm/home-page/categoryRegionList`,
        {
          method: 'get',
          params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
    *fetchDefaultAddress({ payload }, { call, put }) {
      const res = yield call(
        request,
        `/supplier-user-center/purchase/address/defaulted`,
        {
          method: 'get',
          // params: payload,
        },
        { loading: true },
      );
      return res.data;
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    changeSideModal(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    // 启用 immer 之后
    // save(state, action) {
    //   state.name = action.payload;
    // },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(async ({ pathname }) => {
        const params = pathname.match(/^\/goods\/detail\/(\d+)$/);
        if (Array.isArray(params) && params.length) {
          const id = params[1];
          await dispatch({
            type: 'save',
            payload: { goodsId: id },
          });
          await dispatch({
            type: 'detail',
            payload: {
              id,
            },
          });
        }
      });
    },
  },
};

export default GoodsModel;
