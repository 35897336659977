import React, { useEffect, useState } from 'react';
import './index.less';
import { Image } from 'antd';
import Icon from '@/components/Icon';
import { history } from 'umi';
import goodsImg from '@/assets/goods/goods.png';
import { ossProcess } from '@/utils';
interface IProps {
  detail: any;
  hasInfo?: boolean;
  onGoodsClick?: () => void;
  onTenantClick?: () => void;
  className?: string;
}
function Index(props: IProps) {
  const handleGoodsClick = () => {
    if (props.onGoodsClick) {
      props.onGoodsClick();
      return;
    }
    history.push({
      pathname: `/goods/detail/${props.detail.spuId}`,
      query: {
        from: props.category?.title,
      },
    });
  };
  const handleTenantClick = () => {
    if (props.onTenantClick) {
      props.onTenantClick();
      return;
    }
    history.push({
      pathname: `/shop/${props.detail.tenantId}/category`,
    });
  };
  return (
    <div className={`goods-item-components ${props.className}`}>
      <Image
        preview={false}
        src={ossProcess(props.detail.imgSrc || goodsImg)}
        fallback={ossProcess(goodsImg)}
        className="image"
        onClick={handleGoodsClick}
      />
      <div className="goods-item-body">
        <div className="goods-item-title" onClick={handleGoodsClick}>
          {props.detail.title}
        </div>
        <div className="goods-item-price" onClick={handleGoodsClick}>
          <span className="unit">¥</span>
          {props.detail.price}
        </div>
        {props.detail.activityName ? (
          <div className="activity-name-con" onClick={handleGoodsClick}>
            {props.detail.activityName.map((item: string) => (
              <div className="activity-name">{item}</div>
            ))}
          </div>
        ) : null}
        {props.hasInfo ? (
          <div className="goods-item-info" onClick={handleTenantClick}>
            <Icon type="icona-dianpu-421" />
            <div className="shop-name">{props.detail.supplierName}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Index;
