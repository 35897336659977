import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import {
  history,
  connect,
  OrderModelState,
  Loading,
  Dispatch,
  GoodsModelState,
} from 'umi';
import chenggongIcon from '@/assets/common/chenggong.svg';
import SuProgressbar from '@/components/User/SuProgressbar';
import TransferMoney from '@/components/User/transferMoney';
import GoodItem from './components/PayResult/GoodItem';
import './PayResult.less';
interface IProps {
  order: OrderModelState;
  goods: GoodsModelState;
  loading: Loading;
  dispatch: Dispatch;
}
interface IDetail {
  address?: string;
  areaCode?: number | null;
  city?: string;
  consignee?: string;
  county?: string;
  orderId?: string;
  orderNo?: string;
  payPrice?: number;
  phone?: string;
  province?: string;
  status?: 10 | 20;
}
function OrderPayResult(props: IProps) {
  const [detail, setDetail] = useState<IDetail>({});
  const [hotList, setHotList] = useState<any[]>([]);
  const [dataCode, setdataCode] = useState(true);
  const handleList = () => {
    history.replace('/');
  };
  const handleDetail = () => {
    history.replace('/user/order/detail?orderNo=' + detail.orderNos[0]);
  };
  // 热门商品点击跳赚商品详情
  const handleItemClick = (item: any) => {
    history.push('/goods/detail/' + item.spuId);
  };
  useEffect(() => {
    const paramsArray = history.location.pathname.split('/');
    let orderIds = paramsArray[paramsArray.length - 1];

    orderIds = JSON.parse(orderIds);
    console.log(orderIds);

    setdataCode(orderIds.code);
    if (!orderIds.code) {
      if (orderIds) {
        props
          .dispatch({
            type: 'order/getOrderStatus',
            payload: {
              orderIds,
            },
          })
          .then((data: IDetail) => {
            setDetail(data);
          });
      } else {
        history.replace('/');
      }
    }

    props
      .dispatch({
        type: 'goods/hotList',
      })
      .then((data: any) => {
        setHotList(data || []);
      });
  }, []);
  return (
    <div className="order-pay-success-container">
      <div className={detail.status == 14 ? 'sectionstatus' : 'section'}>
        <div className="__title-con">
          <h1>收银台</h1>
        </div>
        <div className="content">
          <div className="c-progress-info">
            {detail.status == 14 ? (
              <TransferMoney current={3} />
            ) : (
              <SuProgressbar current={3} />
            )}
          </div>
          <div className="c-success-info">
            {detail.status == 14 ? (
              <p className="info">
                <img src={chenggongIcon} alt="" className="icon" />
                <span className="title">转账信息已提交审核，请耐心等等</span>
              </p>
            ) : (
              <p className="info">
                <img src={chenggongIcon} alt="" className="icon" />
                <span className="title">
                  你已成功付款 <span className="price">{detail.payPrice}</span>{' '}
                  元
                </span>
              </p>
            )}

            <div className="handle-con">
              <Button type="link" className="custom" onClick={handleList}>
                回到首页
              </Button>
              <span className="split">|</span>
              <Button type="link" className="custom" onClick={handleDetail}>
                查看订单详情
              </Button>
            </div>
          </div>
          {/* <h1>购买成功，我们尽快为您处理</h1>
          <div className="list">
            <div className="item">
              <span>实际付款</span>
              <p>{detail.payPrice}元</p>
            </div>
            <div className="item">
              <span>货物寄送至</span>
              <p>
                {detail.province} {detail.city} {detail.county} {detail.address}{' '}
                {detail.consignee} 收 {detail.phone}
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="section">
        <div className="__title-con">
          <h1>更多精选货品</h1>
        </div>
        <div className="good-list">
          {hotList && hotList.length > 0 ? (
            hotList
              .slice(0, 5)
              ?.map((item: any, index: number) => (
                <GoodItem {...item} key={item.id} onClick={handleItemClick} />
              ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
export default connect(
  ({
    order,
    loading,
    goods,
  }: {
    order: OrderModelState;
    loading: Loading;
    goods: GoodsModelState;
  }) => ({
    order,
    goods,
    loading,
  }),
)(OrderPayResult);
