import React from 'react';
import './index.less';
import Icon from '@/components/Icon';
import { CheckOutlined } from '@ant-design/icons';
interface IProps {
  list: IListItem[];
  current: number;
}
interface IListItem {
  title: React.ReactNode | string;
}

function Pmgressbar(props: IProps) {
  const { list = [], current = 0 } = props;
  const isActivedFun = (item: any, index: numer) => {
    return list.findIndex((item: any) => item.value.includes(current)) > index;
  };
  return (
    <div className="cus-progress-bar">
      {list &&
        list.map((item: any, index: number) => (
          <div
            className={`progress-step ${
              item.value.includes(current) ? 'active' : ''
            } ${isActivedFun(item, index) ? 'active actived' : ''}`}
            key={index}
          >
            {item.value.includes(current) || isActivedFun(item, index) ? (
              <span className="p-num">
                <CheckOutlined className="icon" />
              </span>
            ) : (
              <span className="p-num">{index + 1}</span>
            )}

            {item.title}
          </div>
        ))}
    </div>
  );
}

export default Pmgressbar;
