import React, { useEffect, useState } from 'react';
import {
  connect,
  UserModelState,
  Loading,
  Dispatch,
  ISkuAttribute,
  history,
} from 'umi';
import CouponCon from './components/ConfirmCoupon';
import GiftCell from './components/GiftCell';
import ShippingAddress from '@/components/Order/ShippingAddress';
import AddressCreaterModal from '@/components/Modal/AddressCreater';
import { Table, Button, Input, Spin, message, Checkbox } from 'antd';
import Thead from './components/Shopping/Thead';
import ProductItem from './components/Shopping/ProductItem';
import Icon from '@/components/Icon';
const { Column } = Table;
const { TextArea } = Input;
import './Confirm.less';
import { fixAmount } from '@/utils';

const theadColumns = [
  '货品信息',
  '单价（元）',
  '数量',
  '优惠（元）',
  '金额（元）',
];
interface IFormData {
  purchaseListId?: string[];
  type: 0 | 1;
  orderRequestSkuRO?: {
    num?: string;
    skuId?: string;
  };
  orderRequestSkuROList?: [];
  channel?: 'android' | 'ios' | 'puchase';
  buyerLeaveMessage?: string;
  buyerLeaveMessageROList?: any[];
  addressId?: number | null;
  commissionDeduct?: number;
  userCouponIds?: [];
}
interface IProps {
  user: UserModelState;
  loading: Loading;
  dispatch: Dispatch;
}
let timer: any = null;
function OrderConfirm(props: IProps) {
  const [paramsData, setParamsData] = useState<IFormData>({
    type: 0,
    orderRequestSkuROList: [],
  });
  const [isShowAddressCreater, setIsShowAddressCreater] = useState(false);
  const [buyerLeaveMessageList, setBuyerLeaveMessageList] = useState<any>([]);
  const [detail, setDetail] = useState<any>({});
  const [tableData, setTableData] = useState<any[]>([]);
  const calcOrderAmount = (paramsData: IFormData) => {
    props
      .dispatch({
        type: 'order/calcOrderAmount',
        payload: {
          ...paramsData,
          shippingChannel: 1,
          userCouponIds:
            paramsData?.userCouponIds?.map((item: any) => item.id) || [],
        },
      })
      .then((data: any) => {
        if (!data) {
          setTableData([]);
          return;
        }
        console.log('data', data);

        setDetail(data);
        const newDataList =
          data.info.map((item: any) => {
            item.purchaseSupplier = item.data;
            delete item.data;
            return item;
          }) || [];
        setTableData(newDataList);
      });
  };
  const handleBack = () => {
    history.goBack();
  };
  const onCouponChange = (couponObj: any) => {
    const tempParamsData: any = [...(paramsData?.userCouponIds || [])];
    const index = tempParamsData.findIndex(
      (itemCoupon: any) => itemCoupon.tenantId === couponObj.tenantId,
    );
    if (index > -1 && tempParamsData[index]?.id !== couponObj?.id) {
      tempParamsData.splice(index, 1);
    }
    tempParamsData.push(couponObj);
    setParamsData({
      ...paramsData,
      userCouponIds: tempParamsData,
    });
  };
  //
  const onOrderMessage = (messageObj: any) => {
    const index = buyerLeaveMessageList.findIndex(
      (item: any) => item.tenantId === messageObj.tenantId,
    );
    if (index > -1) {
      buyerLeaveMessageList.splice(index, 1);
    }
    buyerLeaveMessageList.push(messageObj);
    setBuyerLeaveMessageList(buyerLeaveMessageList);
  };

  const onAddressChange = (addressId: number | null) => {
    setParamsData({
      ...paramsData,
      addressId,
    });
  };
  const handleSubmit = () => {
    const formData = { ...paramsData };
    if (!formData.addressId) {
      message.error('请选择收货地址');
      return;
    }
    formData.buyerLeaveMessageROList = buyerLeaveMessageList;
    // formData.channel = 'puchase';
    const skuList: any = tableData.map((item: any) => {
      const skuTemp = item.purchaseSupplier.skuList.map((sku: any) => ({
        skuId: sku.skuId,
        num: sku.num,
      }));
      return skuTemp;
    });
    formData.orderRequestSkuROList = skuList.flat(1);
    formData.orderRequestSkuRO = undefined;
    formData.shippingChannel = 1;
    formData.userCouponIds = formData?.userCouponIds?.map(
      (item: any) => item.id,
    );
    if (formData.type === 1) {
      formData.purchaseListId = undefined;
    }
    formData.orderSource = 'PURCHASE';
    props
      .dispatch({
        type: 'order/takeOrder',
        payload: formData,
      })
      .then((data: any) => {
        if (data) {
          history.replace('/order/pay/' + JSON.stringify(data.orderIdList));
        }
      });
  };

  // 返回进货单
  const goBackCars = () => {
    history.goBack();
  };
  const handleClickTitle = (spuId: any) => {
    history.push({
      pathname: `/goods/detail/${spuId}`,
    });
  };

  const couponSkuList = React.useMemo(
    () =>
      detail.skuList
        ? detail.skuList.map((item: any) => ({
            skuId: item.skuId,
            spuId: item.spuId,
            num: item.num,
          }))
        : [],
    [detail.skuList],
  );
  useEffect(() => {
    if (history.location && history.location.query) {
      const {
        params,
        skuId,
        num,
        shippingChannel,
        orderRequestSkuROList = [],
      } = history.location.query;
      console.log(skuId, num, 'num');

      // 快速补货
      if (orderRequestSkuROList && orderRequestSkuROList.length > 0) {
        setParamsData({
          orderRequestSkuROList,
          type: 0,
        });
        // 进货单
      } else if (typeof params === 'string') {
        const json = JSON.parse(decodeURIComponent(params));
        setParamsData({
          ...json,
          type: 1,
        });
      } else if (typeof skuId === 'string' && typeof num === 'string') {
        setParamsData({
          orderRequestSkuROList: [
            {
              skuId,
              num,
            },
          ],
          type: 0,
        });
      } else {
        handleBack();
      }
    }
  }, []);

  const allSkuNum = React.useMemo(() => {
    return (
      tableData &&
      fixAmount(
        tableData.reduce(
          (prev: any, current: any) =>
            prev +
            current.purchaseSupplier.skuNum +
            current.purchaseSupplier.skuList.reduce(
              (prev: number, cur: any) =>
                prev + (cur.purchaseGiftVO?.quantity || 0),
              0,
            ),
          0,
        ),
      )
    );
  }, [tableData]);

  const allTotalPrice = React.useMemo(() => {
    return detail.totalPrice;
  }, [detail]);

  const allFreightPrice = React.useMemo(() => {
    return detail.transportPrice;
  }, [detail]);

  const allCouponPrice = React.useMemo(() => {
    return (
      tableData &&
      fixAmount(
        tableData.reduce(
          (prev: any, current: any) =>
            prev + current.purchaseSupplier.couponPrice,
          0,
        ),
      )
    );
  }, [tableData]);

  const allActualPrice = React.useMemo(() => {
    console.log('detail', detail);

    return detail.finalPrice;
  }, [detail]);
  const onChange = (e) => {
    paramsData.needDeliveryForm = e.target.checked;
  };
  useEffect(() => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      calcOrderAmount(paramsData);
    }, 300);
  }, [paramsData]);
  return (
    <div className="order-confirm-container">
      <div className="section address-container">
        <div className="section-title top">
          <div className="l">
            <Icon type="icondizhi" className="addr-icon" />
            <span className="addr-title">收货地址</span>
          </div>
          <Button
            className="custom addr-btn"
            type="text"
            icon={<Icon type="icontianjia" className="addr-add-icon" />}
            onClick={() => setIsShowAddressCreater(true)}
          >
            添加新地址
          </Button>
        </div>
        <div className="shipping-address">
          <ShippingAddress onChange={onAddressChange} />
        </div>
      </div>

      <Spin spinning={props.loading.effects['order/calcOrderAmount']}>
        <div className="section-goods-info-container">
          <Thead showDataFlag={true} columns={theadColumns} />
          {tableData &&
            tableData.map((row: any, index: number) => (
              <ProductItem
                key={index}
                {...row}
                showDataFlag={true}
                onCouponChange={onCouponChange}
                handleClickTitle={handleClickTitle}
                goodType="confirm"
                setBuyerLeaveMessage={onOrderMessage}
              />
            ))}
        </div>
        <div className="section-goods-settlement">
          <div className="settlement-info">
            {history.location?.query?.goType ? (
              <div className="back" onClick={goBackCars}>
                <Icon type="iconfanhuijinhuodan" className="icon-back" />
                <span>返回进货单</span>
              </div>
            ) : (
              <span></span>
            )}
            <div className="settlement-price">
              <p className="s-item">
                数量共计：<span className="num">{allSkuNum}</span>
                件，货品总金额：
                <span className="s-price">¥{allTotalPrice}</span>
              </p>
              {detail.activityPrice && detail.activityPrice > 0 ? (
                <p className="s-item">
                  活动优惠：
                  <span className="s-price">¥{detail.activityPrice}</span>
                </p>
              ) : null}
              {detail.discountPrice && detail.discountPrice > 0 ? (
                <p className="s-item">
                  商家优惠：
                  <span className="s-price">¥{detail.discountPrice}</span>
                </p>
              ) : null}
              <p className="s-item">
                运费共计：
                <span className="s-price">¥{allFreightPrice}</span>
              </p>
            </div>
          </div>
          <div className="conclusion-price">
            <span> </span>
            <p className="s-item">
              应付总额：
              <span className="s-price">¥{allActualPrice}</span>
            </p>
          </div>
          <div className="settlement-submit">
            进货单: <Checkbox onChange={onChange}>开具进货清单</Checkbox>
            <Button
              type="primary"
              className="btn"
              onClick={handleSubmit}
              loading={props.loading.effects['order/takeOrder']}
            >
              提交订单
            </Button>
          </div>
        </div>
      </Spin>
      <AddressCreaterModal
        visible={isShowAddressCreater}
        onCancel={() => setIsShowAddressCreater(false)}
      />
    </div>
  );
}

export default connect(
  ({ user, loading }: { user: UserModelState; loading: Loading }) => ({
    user,
    loading,
  }),
)(OrderConfirm);
