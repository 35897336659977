import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Image } from 'antd';
import NumberStep from '@/components/Goods/NumberStep';
export interface GoodItem {
  showDataFlag?: boolean;
  status?: number;
  /**
   * spu名称
   */
  spuTitle: string;
  /**
   * skuId
   */
  skuId: string;
  selectedRowKeys: any[];
  purchaseListId: number;
  setSelectedRowKeys: () => void;
  handleItemDelete: (val: any) => void;
  handleChildrenClickTitle?: (val: any) => void;
  [props: string]: any;
}
/**
 *
 * @param goods 商品信息
 * status状态（0-可买，1-缺货，2-下架，3-已删除）
 * @returns
 */
const GoodsRow = (propsGoods: GoodItem) => {
  const {
    supplierId,
    showDataFlag,
    spuTitle,
    status,
    img,
    skuAttributeVOList,
    skuAttributeDTOList,
    price,
    priceSubtotal,
    num,
    skuId,
    spuId,
    selectedRowKeys = [],
    purchaseListId,
    purchaseGiftVO,
    gift,
    acPrice,
    gives,
    miniNum,
    stock,
    acSubPrice,
    goodType,
    actualPriceSubtotal,
    setSelectedRowKeys,
    salesPromotion,
    cooperationPrice,
  } = propsGoods;
  const handleCheckChange = (e: any) => {
    const bool = e.target.checked;
    const idx = selectedRowKeys.findIndex((id: any) => id === skuId);
    if (bool) {
      if (idx === -1) {
        setSelectedRowKeys([...selectedRowKeys, skuId]);
      }
    } else {
      const idx = selectedRowKeys.findIndex((id: any) => id === skuId);
      if (idx > -1) {
        selectedRowKeys.splice(idx, 1);
        setSelectedRowKeys([...selectedRowKeys]);
      }
    }
  };
  const handleDelete = (purchaseListId: number) => {
    propsGoods.handleItemDelete && propsGoods.handleItemDelete(purchaseListId);
  };
  return (
    <div
      className={`good-row ${
        status === 1 ? 'none-stock' : status === 2 ? 'invalid' : ''
      }`}
      key={skuId}
    >
      {!showDataFlag ? (
        <div className="good-checkbox">
          {(status === 1 && stock === 0) || status === 2 || status === 3 ? (
            <span className="good-stock">{status === 1 ? '无货' : '无效'}</span>
          ) : (
            <Checkbox
              checked={selectedRowKeys.includes(skuId)}
              // value={
              //   (selectedRowKeys && selectedRowKeys.includes(skuId)) || false
              // }
              onChange={handleCheckChange}
            />
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="good-item">
        <Image className="good-img" src={img} preview={false} />
        <div className="good-info">
          <div
            className="title"
            onClick={() =>
              propsGoods.handleChildrenClickTitle &&
              propsGoods.handleChildrenClickTitle(spuId)
            }
          >
            {spuTitle}
          </div>
          <div className="info-row">
            {skuAttributeVOList &&
              skuAttributeVOList.map((sku: any, index: number) => (
                <>
                  <span className="sku-title" key={index}>
                    {sku.name}：{sku.value}
                  </span>
                  {index < skuAttributeVOList?.length - 1 ? '; ' : ''}
                </>
              ))}
            {skuAttributeDTOList &&
              skuAttributeDTOList.map((sku: any, index: number) => (
                <>
                  <span className="sku-title" key={index}>
                    {sku.name}：{sku.value}
                  </span>
                  {index < skuAttributeDTOList?.length - 1 ? '; ' : ''}
                </>
              ))}
          </div>
          {salesPromotion &&
            salesPromotion.map((item) => <div className="tag">{item}</div>)}
          {gives &&
            gives.length > 0 &&
            gives.map((item) => (
              <div className="good-gifts">
                <span className="gift-tag">赠品</span>
                <span className="gift-info">{item.title}</span>
                <span style={{ marginLeft: 10 }}>x{item.giveNum}</span>
              </div>
            ))}
          {purchaseGiftVO && purchaseGiftVO?.spuTitle && (
            <div className="good-gifts">
              <span className="gift-tag">赠品</span>
              <span className="gift-info">{purchaseGiftVO.spuTitle}</span>
              <span style={{ marginLeft: 10 }}>x{purchaseGiftVO.quantity}</span>
            </div>
          )}
        </div>
      </div>

      <div className="unit-price">
        {cooperationPrice !== null && `¥${cooperationPrice}`}
      </div>
      {!showDataFlag ? (
        <div className="unit-price">
          {status < 2 ? (
            <>
              <NumberStep
                value={num}
                min={miniNum || 1}
                key={skuId}
                onChange={(val: any) => {
                  return (
                    num !== val &&
                    propsGoods.handleNumberStep &&
                    propsGoods.handleNumberStep(val, propsGoods, supplierId)
                  );
                }}
              />
              {/* <div className="good-status">已下架</div> */}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="unit-price">{num}</div>
      )}
      <div className="total-price">
        {goodType === 'confirm' ? (
          `¥${acSubPrice || acPrice || 0}`
        ) : goodType === 'shopping' ? (
          `¥${priceSubtotal || 0}`
        ) : status !== 2 && (price !== null || price === 0) ? (
          `¥${price}`
        ) : (
          <></>
        )}
      </div>
      {!showDataFlag ? (
        <div className="good-handle">
          <Button
            type="link"
            className="del"
            onClick={() => handleDelete(purchaseListId)}
          >
            删除
          </Button>
        </div>
      ) : (
        <div className="total-price">
          {goodType === 'confirm'
            ? actualPriceSubtotal || 0
            : priceSubtotal || 0}
        </div>
      )}
    </div>
  );
};

export default GoodsRow;
