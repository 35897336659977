export default [
  {
    path: '/passport/login',
    title: '登录',
    component: '@/pages/Passport/Login',
  },
  {
    path: '/',
    title: '首页',
    component: '../layouts/BasicLayout',
    routes: [
      {
        path: '/',
        component: '@/pages/Home/index',
        title: '首页',
        breadcrumb: '首页',
      },
      {
        path: '/home/activity',
        component: '@/pages/Home/Activity',
        title: '满减活动页',
        breadcrumb: '满减活动页',
      },
      {
        path: '/home/recommend',
        component: '@/pages/Home/Recommend',
        title: '爆款页',
        breadcrumb: '爆款页',
      },
      {
        path: '/notice/:id',
        component: '@/pages/Notice/detail',
        title: '公告详情',
        breadcrumb: '公告详情',
      },
      {
        path: '/shop/:tenantId/category',
        component: '@/pages/Shop/Category',
        title: '店铺分类',
        breadcrumb: '店铺分类',
      },
      {
        path: '/goods/detail/:id',
        component: '@/pages/Goods/Detail',
        title: '商品详情',
        breadcrumb: '商品详情',
      },
      {
        path: '/order/shopping',
        component: '@/pages/Order/Shopping',
        title: '进货单',
      },
      {
        path: '/order/confirm',
        component: '@/pages/Order/Confirm',
        title: '提交订单',
      },
      {
        path: '/order/pay/:id',
        component: '@/pages/Order/Pay',
        title: '订单支付',
      },
      {
        path: '/order/pay/result/:id',
        component: '@/pages/Order/PayResult',
        title: '订单支付',
      },
      {
        path: '/search/shop',
        component: '@/pages/Search/Shop',
        title: '搜索结果',
      },
      {
        path: '/search/more',
        component: '@/pages/Search/More',
        title: '更多好货',
      },
      {
        path: '/user/order/refund/detail',
        component: '@/pages/User/Refund/RefundDetail',
        title: '退款详情',
      },
      // 我的订单+进货车+收货地址+退款售后+优惠券
      {
        path: '/user',
        redirectPath: '/user/purchase',
        component: '@/pages/User',
        title: '订单管理',
        routes: [
          { path: '/user/purchase', component: '@/pages/User/Order/Purchase' },
          { path: '/user/address', component: '@/pages/User/Address' },
          { path: '/user/refund', component: '@/pages/User/Refund' },
          { path: '/user/coupon', component: '@/pages/User/Coupon' },
          {
            path: '/user/fastReplenishment',
            component: '@/pages/User/Order/FastReplenis',
            title: '快速补货',
          },
          {
            path: '/user/order/applyRefund',
            exact: true,
            component: '@/pages/User/Order/ApplyRefund',
            title: '申请退款',
          },
          {
            path: '/user/order/detail',
            exact: true,
            component: '@/pages/User/Order/Detail',
            title: '订单详情',
          },
        ],
      },
    ],
  },
];
