import React from 'react';
import './index.less';

const list = [
  {
    title: '1.进货单',
  },
  {
    title: '2.确认订单',
  },
  {
    title: '3.转账成功',
  },
  {
    title: '4.转账平台审核',
  },
  {
    title: '5.供应商发货',
  },
  {
    title: '6.确认收货',
  },
  {
    title: '7.订单完成',
  },
];
interface IProps {
  current: number;
}
function TransferMoney(props: IProps) {
  const { current } = props;
  return (
    <div className="su-progress">
      {list.map((item: any, index: number) => (
        <div
          className={`progress-item ${current > index + 1 ? `actived` : ''} ${
            current === index + 1 ? 'active' : ''
          }`}
        >
          <div className="circle">{index + 1}</div>
          <div className="title">{item.title}</div>
          <div className="line"></div>
        </div>
      ))}
    </div>
  );
}

export default TransferMoney;
