import React from 'react';
import './index.less';
import { Image } from 'antd';
function MainFooter() {
  const Footer1 = () => {
    return (
      <div className="f1">
        <ul className="f1-ul">
          <li className="f1-item">
            <Image
              width="44px"
              height="48px"
              src={require('@/assets/common/f1.png')}
              preview={false}
            />
            <div className="info">
              <p className="t">正品货源</p>
              <p className="t2">官方授权，安心质保</p>
            </div>
          </li>
          <li className="f1-item">
            <Image
              width="42px"
              height="47px"
              src={require('@/assets/common/f2.png')}
              preview={false}
            />
            <div className="info">
              <p className="t">价格专享</p>
              <p className="t2">批发订货，阶梯定价</p>
            </div>
          </li>
          <li className="f1-item">
            <Image
              width="42px"
              height="47px"
              src={require('@/assets/common/f3.png')}
              preview={false}
            />
            <div className="info">
              <p className="t">服务保障</p>
              <p className="t2">专人对接，多仓直发</p>
            </div>
          </li>
          {/* <li className="f1-item">
            <Image
              width="44px"
              height="47px"
              src={require('@/assets/common/f4.png')}
              preview={false}
            />
            <div className="info">
              <p className="t">品类齐全</p>
              <p className="t2">商品丰富，轻松购物</p>
            </div>
          </li> */}
        </ul>
      </div>
    );
  };
  const Footer2 = () => {
    return (
      <div className="f-company-info">
        <div className="f-c-i-body">
          <div className="f-c-i-link">
            <div className="lf">
              <span className="lfText">宠货通</span>
              {/* <Image
                   src={require('@/assets/common/cht.png')}
                preview={false}
              /> */}
            </div>
            <div className="rg">
              <div>
                <span className="item i3">联系我们</span>
              </div>
              <div className="item i3">咨询邮箱: liujiajia@tianyuanpet.com</div>
            </div>
            <div className="rg">
              <div>
                <span className="item i3">服务时间</span>
              </div>
              <div className="item i3">周末及节假日:10:00-24:00</div>
              <div className="item i3">周一至周五:9:00-24:00</div>
            </div>
            <div className="rg">
              <div>
                <span className="item i3">服务咨询</span>
              </div>
              <div className="item i3">
                <img
                  className="codeImg"
                  src={require('@/assets/common/code.jpg')}
                  alt=""
                />
              </div>
            </div>
            <div className="rg">
              {/* <a
                className="item i1"
                target="_blank"
                // href="https://www.zhuayinline.com/#/"
              >
                隐私政策
              </a> */}
              {/* <a
                target="_black"
                className="item i3"
                href="https://mp.weixin.qq.com/s?__biz=MzkyOTI4Mjg4Ng==&mid=2247485915&idx=1&sn=0ede33d8602d9c58b1673fccbc0e8658&chksm=c20aa7f2f57d2ee4cd31fbfabd5f6cb73ab7e8e34d574d6d66479604ac24fe416d6c31b7626e&token=499117939&lang=zh_CN#rd"
              >
                关于我们
              </a> */}
            </div>
          </div>
          <div className="f-c-i-border"></div>
          <div className="f-c-i">
            <div className="item lf" style={{ textAlign: 'center' }}>
              杭州赛旺电子商务科技有限公司
            </div>
            <div className="item ct">浙ICP备: 2021028033号-1</div>
            {/* <div className="item rg">
              <img
                className="rg-img"
                src={require('@/assets/common/god.png')}
                alt=""
              />
              浙公网安备 33010902002487号
            </div> */}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="main-footer">
      <Footer1 />
      <div className="f-border"></div>
      <Footer2 />
    </div>
  );
}

export default MainFooter;
