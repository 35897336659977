import React, { useEffect, useState } from 'react';
import { GoodsSkuSplicings, GoodsSkuDetail } from 'umi';
import { isEqualIfArray } from '@/utils';
import './index.less';
import NumberStep from '../NumberStep';

interface ISKUSelectorProps {
  skus: GoodsSkuDetail[];
  titles: GoodsSkuSplicings[];
  stock: number | string;
  miniNum: number | string;
  onChange?: (sku: GoodsSkuDetail | undefined, num: number) => void;
}

export default function SKUSelector(props: ISKUSelectorProps) {
  const [selectedArray, setSelectedArray] = useState<number[]>([]);
  const [num, setNum] = useState<number>(1);

  const handleSelectSku = (index: number, x: number) => {
    setSelectedArray(
      selectedArray.map((oldValue, y) => (y === index ? x : oldValue)),
    );
  };

  const getKeyAndValueList = (array: number[]) => {
    return array.map((item, index) => {
      const key = props.titles[index].key;
      const value = props.titles[index].title[item];
      return {
        key,
        value,
      };
    });
  };

  const getSkuItemByKeyAndValueList = (
    keyAndValueList: any,
  ): GoodsSkuDetail | undefined => {
    return props.skus.find((sku: GoodsSkuDetail) =>
      isEqualIfArray(sku.list, keyAndValueList),
    );
  };
  const onNumChange = (value: number) => {
    setNum(value);
  };

  useEffect(() => {
    const keyAndValueList = getKeyAndValueList(selectedArray);
    const sku = getSkuItemByKeyAndValueList(keyAndValueList);
    let newNum = num === 0 && Number(sku?.stock) > 0 ? props.miniNum : num;
    props.onChange && props.onChange(sku, newNum);
  }, [selectedArray, num]);
  useEffect(() => {
    setSelectedArray(new Array(props.titles.length).fill(0));
  }, [props.titles]);
  useEffect(() => {
    if (props.miniNum) {
      setNum(props.miniNum || 0);
    }
  }, [props.stock]);
  return (
    <div className="sku-selector-container">
      {props.titles &&
        props.titles.map((item: GoodsSkuSplicings, index) => (
          <div key={index} className="sku-line">
            <div className="sku-title">{item.key}：</div>
            <div className="sku-items">
              {item.title.map((sku: string, x: number) => (
                <div
                  key={x}
                  className={`sku-item ${
                    selectedArray[index] === x && 'selected'
                  }`}
                  onClick={() => handleSelectSku(index, x)}
                >
                  {sku}
                </div>
              ))}
            </div>
          </div>
        ))}
      <div className="sku-line stock-con">
        <div className="sku-title">数量：</div>
        <NumberStep
          value={num}
          key={props.stock}
          min={props.miniNum <= 0 ? 0 : props.miniNum}
          max={typeof props.stock === 'number' ? props.stock : 0}
          onChange={onNumChange}
        />
        {/* <p>
          起订量：<span>{props.miniNum}</span>
        </p> */}
        <p>
          可售库存：<span>{props.stock}</span>
        </p>
      </div>
    </div>
  );
}
