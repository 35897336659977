import React, { useState, useEffect } from 'react';
import { Button, Timeline, Modal, Empty, Tabs } from 'antd';
import { history, connect, OrderModelState, Loading } from 'umi';
import Breadcrumb from '@/components/Breadcrumb';
import Pmgressbar from '@/components/User/Pmgressbar';
import Icon from '@/components/Icon';
import moment from 'moment';
import { payChannelMap, refundStatusMap } from '@/utils/enum';
import { useLeftTime } from '@/utils';
import './Detail.less';
const { TabPane } = Tabs;
const progressBarList = [
  {
    title: <>提交订单</>,
    value: [10],
  },
  {
    title: <>付款成功</>,
    value: [20],
  },
  {
    title: <>商品出库</>,
    value: [30],
  },
  {
    title: <>等待收货</>,
    value: [40],
  },
  {
    title: <>完 成</>,
    value: [50],
  },
];
let timer: NodeJS.Timeout;
function Detail(props: any) {
  // state
  const [btnTxt, setBtnTxt] = useState('展开');
  const [logisticsShow, setLogisticsShow] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [remindVisible, setRemindVisible] = useState(false);
  const [detailData, setDetailData] = useState<any>({});
  const [logisticsData, setLogisticsData] = useState<any>({});
  const [logisticsTable, setLogisticsTable] = useState<any>({});
  const [logisticsTabKey, setLogisticsTabKey] = useState<string>('0');
  const [leftTime, setLeftTime] = useState('--');
  const [showLogiscticsGoodsMore, setShowLogiscticsGoodsMore] = useState(false);
  // 生命周期
  useEffect(() => {
    const data = history.location.query;
    getDetail(data);
    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    if (leftTime === '00时00分00秒') {
      history.replace('/');
    }
  }, [leftTime]);
  // methods
  // 获取订单详情
  const getDetail = (data: any) => {
    props
      .dispatch({
        type: 'userOrder/getOrderDetail',
        payload: { ...data },
      })
      .then((res: any) => {
        setDetailData({ ...res });
        const warehouseOrderDetailVOList =
          res?.warehouseOrderDetailVOList || [];
        setLogisticsTable(warehouseOrderDetailVOList);
        if (warehouseOrderDetailVOList.length > 0) {
          getLogistics(warehouseOrderDetailVOList[0].id);
        }
        if (res.status === 10) {
          // 倒计时
          props
            .dispatch({
              type: 'order/getTakeOrderInfo',
              payload: {
                orderNo: res.orderNo,
              },
            })
            .then((data: any) => {
              let end = moment(data.expireTime);
              timer = setInterval(() => {
                const diff = moment(end).diff(moment(), 'ms');
                if (diff < 0) {
                  history.replace('/');
                }
                const [HH, mm, ss] = useLeftTime(diff);
                setLeftTime(`${HH}时${mm}分${ss}秒`);
              }, 500);
            });
        }
      });
  };
  // 获取物流详情
  const getLogistics = (id: any) => {
    props
      .dispatch({
        type: 'userOrder/getLogistics',
        payload: {
          id,
        },
      })
      .then((res: any) => {
        // if (res && res.details) {
        //   setBtnTxt('关闭');
        //   setLogisticsShow(true);
        // } else {
        //   setBtnTxt('展开');
        //   setLogisticsShow(false);
        // }
        setLogisticsData({ ...res });
      });
  };

  // logistic

  // 展开关闭物流详情
  const changeLogistics = () => {
    setBtnTxt(btnTxt === '展开' ? '关闭' : '展开');
    setLogisticsShow(!logisticsShow);
  };
  // 确认收货弹窗
  // 打开弹窗
  const onOpenConfirmModal = () => {
    setConfirmVisible(true);
  };
  // 关闭弹窗
  const onCanceConfirmModal = () => {
    setConfirmVisible(false);
  };
  // 弹窗确认
  const handleConfirmModalOk = () => {
    props
      .dispatch({
        type: 'userOrder/orderConfrim',
        payload: {
          orderId: detailData.id,
        },
      })
      .then((res: boolean) => {
        setConfirmVisible(false);
        const data = history.location.query;
        getDetail(data);
      });
  };
  // 跳转退款详情
  const handleDetail = (record: any) => {
    history.push({
      pathname: '/user/order/refund/detail',
      query: {
        refundOrderNo: record.refundOrderNo,
      },
    });
  };
  // 去付款
  const goToPay = (orderDetail: any) => {
    const { orderNo, id } = orderDetail;
    if (history.location.query.confirm === '0') {
      props
        .dispatch({
          type: 'order/getTakeOrderInfo',
          payload: {
            orderNo,
          },
        })
        .then((res: any) => {
          console.log(res);
          if (res.replaceUserIsPay === 1) {
            history.push(`/order/pay/${JSON.stringify([id])}`);
          } else {
            setRemindVisible(true);
          }
        });
      return;
    }
    history.push(`/order/pay/${JSON.stringify([id])}`);
  };
  const handleLogisticsTab = (val: string) => {
    setLogisticsTabKey(val);
    setLogisticsData(logisticsTable[val]);
    getLogistics(logisticsTable[val]?.id);
  };
  return (
    <>
      <Breadcrumb />
      <div className="progress">
        <Pmgressbar list={progressBarList} current={detailData.status} />
      </div>
      <div className="order-detail-con">
        {/* 订单状态 */}
        <div className="order-status">
          <div className="public-top">
            <Icon
              className="icon"
              style={{ fontSize: '22px', marginRight: '10px' }}
              type="icondingdanzhuangtai"
            />
            <span className="public-tit">订单状态</span>
          </div>
          <div className="status-info">
            <div className="left">
              {detailData.status === 10 && (
                <>
                  <span className="status">订单状态：待付款</span>
                  <span className="span">*您还有</span>
                  <span className="span color">{leftTime}</span>
                  <span className="span">来付款,超时订单自动关闭</span>
                </>
              )}
              {detailData.status === 20 && <p>订单状态：待发货</p>}
              {detailData.status === 14 && <p>待付款-转账信息审核中</p>}
              {detailData.status === 15 && <p>待付款-转账信息失败</p>}
              {detailData.status === 30 && <p>订单状态：待收货</p>}
              {detailData.status === 50 && <p>订单状态：交易完成</p>}
              {detailData.status === 60 && <p>订单状态：退款中</p>}
              {detailData.status === 70 && (
                <p>订单状态：交易关闭（退款成功）</p>
              )}
              {detailData.status === 80 && (
                <p>订单状态：交易关闭（订单已取消）</p>
              )}
              {detailData.status === 90 && (
                <p>订单状态：交易关闭（订单超时未付款）</p>
              )}
            </div>
            <div className="right">
              {detailData.status === 10 && (
                <Button
                  className="btn"
                  type="primary"
                  onClick={() => goToPay(detailData)}
                >
                  立即付款
                </Button>
              )}
              {detailData.status === 30 &&
                history.location.query.confirm === '1' &&
                detailData.receivingButton && (
                  <Button
                    className="btn"
                    type="primary"
                    onClick={() => onOpenConfirmModal()}
                  >
                    确认收货
                  </Button>
                )}
              {/* {detailData.status <= 30 && detailData.goRefund === 0 && (
                <Button
                  className="btn-link"
                  type="link"
                  onClick={() =>
                    history.push(
                      `/user/order/applyRefund?orderNo=${history.location.query?.orderNo}`,
                    )
                  }
                >
                  申请退款
                </Button>
              )} */}
              {detailData.status === 20 && detailData.goRefund > 0 && (
                <Button
                  className="btn-link"
                  type="link"
                  onClick={() =>
                    handleDetail({ refundOrderNo: detailData.refundOrderNo })
                  }
                >
                  售后处理中
                </Button>
              )}
              {detailData.status === 30 && detailData.goRefund > 0 && (
                <Button
                  className="btn-link"
                  type="link"
                  onClick={() =>
                    handleDetail({ refundOrderNo: detailData.refundOrderNo })
                  }
                >
                  售后处理中
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* 订单信息 */}
        <div className="order-info">
          <div className="public-top">
            <Icon
              className="icon"
              style={{ fontSize: '22px', marginRight: '10px' }}
              type="icondingdanxinxi"
            />
            <span className="public-tit">订单信息</span>
          </div>
          {/* 订单信息 */}
          <div className="order-item">
            <div className="left-tit">
              <div className="line"></div>
              <span>订单信息</span>
            </div>
            <div className="right">
              <div className="item">
                <span className="label right-tit">订单编号</span>
                <span className="value right-txt">{detailData.orderNo}</span>
              </div>
              {detailData.storeOrderNo && (
                <div className="item">
                  <span className="label right-tit">用户订单编号</span>
                  <span className="value right-txt">
                    {detailData.storeOrderNo || '-'}
                  </span>
                </div>
              )}
              <div className="item">
                <span className="label right-tit">创建时间</span>
                <span className="value right-txt">
                  {moment(detailData.gmtCreate).format('YYYY-MM-DD HH:mm:ss')}
                </span>
              </div>
              <div className="item">
                <span className="label right-tit">付款方式</span>
                <span className="value right-txt">
                  {payChannelMap[detailData.payChannel] || '-'}
                </span>
              </div>
              <div className="item">
                <span className="label right-tit">付款时间</span>
                <span className="value right-txt">
                  {(detailData.gmtPay &&
                    moment(detailData.gmtPay).format('YYYY-MM-DD HH:mm:ss')) ||
                    '-'}
                </span>
              </div>
              <div className="item">
                <span className="label right-tit">进货单：</span>
                <span className="value right-txt">
                  {detailData.needDeliveryForm == true ? '有' : '无'}
                </span>
              </div>
            </div>
          </div>
          {/* 收货人信息 */}
          <div className="order-item order-receiving">
            <div className="left-tit">
              <div className="line"></div>
              <span>收货人信息</span>
            </div>
            <div className="right">
              <div className="item">
                <span className="label right-tit">收货人</span>
                <span className="value right-txt">
                  {detailData.consignee || '-'}
                </span>
              </div>
              <div className="item">
                <span className="label right-tit">联系电话</span>
                <span className="value right-txt">
                  {detailData.phone || '-'}
                </span>
              </div>
              <div className="item">
                <span className="label right-tit" style={{ minWidth: 65 }}>
                  收货地址
                </span>
                <span className="value right-txt">
                  {detailData.address || '-'}
                </span>
              </div>
            </div>
          </div>
          {/* 买家留言 */}
          <div className="order-item order-receiving">
            <div className="left-tit">
              <div className="line"></div>
              <span>买家留言</span>
            </div>
            <div className="right">
              <div className="item" style={{ marginTop: 0 }}>
                <span className="label right-txt" style={{ width: '100%' }}>
                  {detailData.buyerLeaveMessage || '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
        {logisticsTable.length >= 1 && (
          <div className="logistics-container">
            <Tabs
              onChange={handleLogisticsTab}
              type="card"
              className="custom"
              activeKey={logisticsTabKey}
            >
              {logisticsTable.map((item: any, index: number) => (
                <TabPane tab={`包裹${index + 1}`} key={index}></TabPane>
              ))}
            </Tabs>
            <>
              <div className="order-item order-logistics">
                <div className="right">
                  <div className="item">
                    <span className="label right-tit">物流公司</span>
                    <span className="value right-txt">
                      {logisticsData.shipName || '-'}
                    </span>
                  </div>
                  <div className="item">
                    <span className="label right-tit">快递单号</span>
                    <span className="value right-txt">
                      {logisticsData.shipNo || '-'}
                    </span>
                  </div>
                  <div className="item">
                    <span className="label right-tit">物流进度</span>
                    <span className="value right-txt">
                      {logisticsData?.details
                        ? logisticsData.details.length > 0
                          ? logisticsData.details[0].status
                          : '-'
                        : '-'}
                    </span>
                  </div>
                  <div className="item">
                    <span className="label right-tit">客服电话</span>
                    <span className="value right-txt">
                      {logisticsData.shipPhone || '-'}
                    </span>
                  </div>
                  <div className="item">
                    <span className="label right-tit">发货时间</span>
                    <span className="value right-txt">
                      {(logisticsData.gmtShip &&
                        moment(logisticsData.gmtShip).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )) ||
                        '-'}
                    </span>
                  </div>
                  <div
                    className={`logistics-btn ${
                      btnTxt === '关闭' ? 'close' : ''
                    }`}
                    onClick={() => changeLogistics()}
                  >
                    <span className="logistics-text">{btnTxt}物流信息</span>
                    <Icon
                      className="icon"
                      style={{
                        fontSize: '12px',
                        marginLeft: '10px',
                        color: '#1473EC',
                      }}
                      type="iconxiangxia"
                    />
                  </div>
                </div>
              </div>
              {/* 具体物流详情 */}
              {logisticsShow && (
                <div className="logistics">
                  <div className="left"></div>
                  <div className="right-con">
                    {logisticsData.details !== undefined &&
                    logisticsData.details.length > 0 ? (
                      <Timeline>
                        {logisticsData.details !== undefined &&
                          logisticsData.details.length > 0 &&
                          logisticsData.details.map((ele: any, idx: number) => {
                            return (
                              <Timeline.Item
                                key={idx}
                                className={idx === 0 ? 'time-item active' : ''}
                                dot={
                                  idx === 0 && (
                                    <Icon
                                      className="icon"
                                      style={{
                                        fontSize: '20px',
                                        marginTop: '-4px',
                                      }}
                                      type="iconyiqianshou"
                                    />
                                  )
                                }
                                color="gray"
                              >
                                <span
                                  className={
                                    idx === 0 ? 'status active' : 'status'
                                  }
                                >
                                  {ele.status}
                                </span>
                                <span className="time">{ele.time}</span>
                                <span className="info">{ele.context}</span>
                              </Timeline.Item>
                            );
                          })}
                      </Timeline>
                    ) : (
                      <Empty
                        style={{ marginTop: '70px' }}
                        description="暂无物流信息"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
            <div className="goods-info">
              <div
                className={`good-list ${showLogiscticsGoodsMore ? 'more' : ''}`}
              >
                {logisticsTable[logisticsTabKey]?.skuInfo.map(
                  (good: any, index: number) => (
                    <div className="good-item" key={good.id}>
                      <div className="good1 good-tit">
                        <img src={good.url} alt="" />
                        <div className="good">
                          <p>{good.spuTitle}</p>
                          <p className="attribute">{good.skuTitle}</p>
                        </div>
                      </div>
                      <div className="good2 good-tit">
                        本次发货数量：{good.qualitySkuNum}
                      </div>
                    </div>
                  ),
                )}
              </div>
              {logisticsTable[logisticsTabKey]?.skuInfo &&
                logisticsTable[logisticsTabKey]?.skuInfo.length > 2 &&
                !showLogiscticsGoodsMore && (
                  <p
                    className="good-more"
                    onClick={() => setShowLogiscticsGoodsMore(true)}
                  >
                    查看更多货品
                  </p>
                )}
            </div>
          </div>
        )}

        {/* 货品信息 */}
        <div className="goods-info">
          <div className="good-top">
            <div className="good1 good-tit" style={{ textAlign: 'left' }}>
              货品信息
            </div>
            <div className="good2 good-tit">单价（元）</div>
            <div className="good3 good-tit">数量</div>
            <div className="good4 good-tit">优惠（元）</div>
            <div className="good5 good-tit">货品状态</div>
            <div className="good6 good-tit">金额（元）</div>
          </div>
          <div className="good-list">
            {/* 每一个 */}
            {detailData.purchaseOrderSkuDetailVOList !== undefined &&
              detailData.purchaseOrderSkuDetailVOList.map((good: any) => {
                return (
                  // <div className="good-item good-item-gift" key={good.id}>
                  <div className="good-item" key={good.id}>
                    <div className="good1 good-tit">
                      <img src={good.skuImg} alt="" />
                      <div className="good">
                        <p>{good.spuTitle}</p>
                        {good.attributeVOList !== undefined &&
                          good.attributeVOList.map((j: any) => {
                            return (
                              <p className="attribute" key={j.value}>
                                {j.name}:{j.value}
                              </p>
                            );
                          })}
                      </div>
                    </div>
                    <div className="good2 good-tit">
                      {!good.isPresent ? (
                        <>¥{good.price}</>
                      ) : (
                        <div className="good-gift-tag">赠品</div>
                      )}
                    </div>
                    <div className="good3 good-tit">{good.num}</div>
                    <div className="good4 good-tit">
                      {good.acSubPrice !== null ? `¥${good.acSubPrice}` : '-'}
                    </div>
                    <div className="good4 good-tit">
                      {good.deliverStatus === 0
                        ? '未发货'
                        : good.deliverStatus === 1
                        ? '已发货'
                        : '-'}
                    </div>
                    <div className="good-tit">
                      {good.actualPriceSubtotal !== null
                        ? `¥${good.actualPriceSubtotal}`
                        : '-'}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="good-box">
            <div className="good-price">
              <div className="row">
                <div className="label-row">总货总金额：</div>
                <div className="value-row" style={{ color: '#2D3146' }}>
                  ¥{detailData.skuTotalPrice || 0}
                </div>
              </div>
              <div className="row">
                <div className="label-row">运费总金额：</div>
                <div className="value-row" style={{ color: '#2D3146' }}>
                  ¥{detailData.freightPrice || 0}
                </div>
              </div>
              <div className="row">
                <div className="label-row">优惠总金额：</div>
                <div className="value-row" style={{ color: '#2D3146' }}>
                  ¥{detailData.discountPrice || detailData.couponPrice || 0}
                </div>
              </div>
              {detailData.changePrice > 0 && (
                <div className="row">
                  <div className="label-row">商品改价：</div>
                  <div className="value-row" style={{ color: '#2D3146' }}>
                    -¥{detailData.changePrice || 0}
                  </div>
                </div>
              )}
            </div>
            <div className="good-total">
              <div className="row">
                <div className="label-row">付款总金额：</div>
                <div className="value-row">¥{detailData.actualPrice || 0}</div>
              </div>
              {/* <div className="row">
                <div className="label-row">退款总金额：</div>
                <div className="value-row">¥0</div>
              </div> */}
            </div>
          </div>
        </div>
        {/* 确认收货弹窗 */}
        <Modal
          visible={confirmVisible}
          onOk={handleConfirmModalOk}
          onCancel={onCanceConfirmModal}
          wrapClassName="purchase-modal-custom"
          title={
            <div className="modal-tit">
              <Icon
                className="icon"
                style={{ fontSize: '24px', marginRight: '13px' }}
                type="icondingdanhao"
              />
              <p>操作提醒</p>
            </div>
          }
          width="640px"
        >
          <div className="modal-body">
            <p className="modal-p-child1">请确认是否收到货？</p>
          </div>
        </Modal>
        {/* 提醒信息弹窗 */}
        <Modal
          visible={remindVisible}
          onOk={() => setRemindVisible(false)}
          cancelText={<></>}
          wrapClassName="purchase-modal-custom"
          title={
            <div className="modal-tit">
              <Icon
                className="icon"
                style={{ fontSize: '24px', marginRight: '13px' }}
                type="icondingdanhao"
              />
              <p>操作提醒</p>
            </div>
          }
          width="640px"
        >
          <div className="modal-body">
            <p className="modal-p-child1">
              小程序用户还未付款，您暂时无法对该订单进行支付
            </p>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default connect(
  ({ order, loading }: { order: OrderModelState; loading: Loading }) => ({
    order,
    loading,
  }),
)(Detail);
