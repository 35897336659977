import React, { useEffect, useState } from 'react';
import './index.less';
import { Image } from 'antd';
import { history } from 'umi';
import goodsImg from '@/assets/goods/goods.png';
import { ossProcess } from '@/utils';
interface IItem {
  id: number;
  img: string;
  price: number;
  sales: number;
  title: string;
  tag?: number;
  [props: string]: any;
}
function Item(props: IItem) {
  const [title, setTitle] = useState('');
  const [tags] = useState(['满赠']);
  const goto = () => {
    let query = {};
    if (title) {
      query = {
        from: title,
      };
    }
    history.push({
      pathname: `/goods/detail/${props.id}`,
      query,
    });
  };
  const tag = React.useMemo(() => {
    if (typeof props.tag === 'number') {
      return tags[props.tag];
    }
    return '';
  }, [props.tag, tags]);
  useEffect(() => {
    const query = history.location.query;
    if (
      history.location &&
      history.location.pathname.indexOf('search/shop') !== -1
    ) {
      if (query && query.categoryId) {
        setTitle(query.categoryTitle);
      } else if (query && query.keyWord) {
        setTitle('搜索结果');
      } else {
        setTitle('');
      }
    }
  }, []);
  return (
    <div className={`res-item ${props.className}`} onClick={() => goto()}>
      <Image
        preview={false}
        src={ossProcess(props.img || goodsImg)}
        fallback={ossProcess(goodsImg)}
      />
      <div className="top-con">
        <div className="price">
          <span className="rmb">¥ </span>
          {props.price}
        </div>
        <div className={`tag tag-${props.tag}`}>{tag}</div>
      </div>
      <p className="title">{props.title}</p>
      <div className="info">
        <span className="num">已售 {props.sales}</span>
      </div>
    </div>
  );
}

export default Item;
