import React, { useState } from 'react';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import styles from './toggle.less';
interface IProps {
  onChange?: (isShowMore: boolean) => void;
}
export default function toggle(props: IProps) {
  const [isShowMore, setIsShowMore] = useState(false);
  const handleToggle = React.useCallback(() => {
    setIsShowMore(!isShowMore);
    props.onChange && props.onChange(!isShowMore);
  }, [isShowMore, props.onChange]);
  return (
    <div className={styles.btn} onClick={handleToggle}>
      <span>{isShowMore ? '收起' : '展开'}</span>{' '}
      {isShowMore ? <UpOutlined /> : <DownOutlined />}
    </div>
  );
}
