import React from 'react';
import { Button } from 'antd';
import Icon from '@/components/Icon';
import { history } from 'umi';
import './index.less';
interface IProps {
  [props: string]: any;
}
function ResultRefund(props: IProps) {
  const goto = (url: string) => {
    history.push(url);
  };
  return (
    <div className="result-refund">
      <Icon className="icon" type="icona-tijiaowancheng23x" />
      <p className="success-status">申请已提交，等待商家处理</p>
      <p>
        <Icon className="icon-que" type="icona-Question3" />{' '}
        <span className="s">说</span>
        <span className="m">明</span>
      </p>
      <p className="result-row">
        商家同意后，退款成功， 资金将原路返回至您的资金账户
      </p>
      <p className="result-row">
        商家拒绝后，请修改退款/退货申请，重新提交申请
      </p>
      <div className="handle-con">
        <Button
          type="link"
          className="custom"
          onClick={() => goto('/user/purchase')}
        >
          返回直采订单列表
        </Button>
        <span className="split">|</span>
        <Button
          type="link"
          className="custom"
          onClick={() => goto('/user/refund')}
        >
          返回退款/退货列表
        </Button>
      </div>
    </div>
  );
}

export default ResultRefund;
