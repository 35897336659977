import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, Dispatch } from 'umi';
import { Swiper, SwiperSlide } from 'swiper/react';

import './index.less';
let timer: any;
interface IProps {
  intervalTime?: number;
  dispatch: Dispatch;
}
interface listItem {
  id: Number;
  title: String;
  link: String;
}
function Index(props: IProps) {
  const [visualHeight] = useState(50);
  const [itemHeight] = useState(30);
  const [list, setList] = useState<listItem[]>([]);
  const [clientHeight, setClientHeight] = useState(0);
  const [top, setTop] = useState(0);
  const listRef = React.useRef<any>(null);
  // 公告
  useEffect(() => {
    // props
    //   .dispatch({
    //     type: 'goods/noticeList',
    //     payload: {
    //       pageNo: 1,
    //       pageSize: 10,
    //     },
    //   })
    //   .then((data: any) => {
    //     if (data) {
    //       setList(data.items);
    //     }
    //   });
    setList([
      {
        id: 1,
        title: '宠货通批发服务平台专注于服务批发大客户',
        link:
          'https://mp.weixin.qq.com/s?__biz=MzkyOTI4Mjg4Ng==&mid=2247486108&idx=1&sn=835a8ab952d5bd204c7ecd088aeefa47&chksm=c20aa4b5f57d2da3030d7fd5754f4cdd2ca3d1f7b846ccd74a0906507bfe94b05b892282c5d5&token=880786748&lang=zh_CN#rd',
      },
    ]);
  }, []);
  // useEffect(() => {
  //   if (listRef.current && list.length) {
  //     setClientHeight(listRef.current.clientHeight);
  //   }
  // }, [listRef, list]);
  // useEffect(() => {
  //   if (clientHeight) {
  //     clearInterval(timer);
  //     timer = setInterval(() => {
  //       if (top + itemHeight + visualHeight > clientHeight) {
  //         setTop(0);
  //       } else {
  //         setTop(top + itemHeight);
  //       }
  //     }, props.intervalTime || 3000);
  //   }
  // }, [clientHeight, top, props.intervalTime, itemHeight, visualHeight]);

  return (
    <div className="home-notice-component">
      {list?.length > 3 ? (
        <Swiper
          className="anno-list"
          slidesPerView={3}
          autoplay={{
            disableOnInteraction: true, //false: 鼠标操作屏幕后继续自动滚屏
          }}
          loop
          direction="vertical"
          onSwiper={(swiper) => {
            //鼠标悬浮暂停效果
            swiper.$el[0].addEventListener('mouseover', () =>
              swiper.autoplay.stop(),
            );
            //鼠标移开后继续自动滚屏效果
            swiper.$el[0].addEventListener('mouseleave', () =>
              swiper.autoplay.start(),
            );
          }}
        >
          {list.map((item: any) => (
            <SwiperSlide className="anno-item" key={item.id}>
              <p className="content">{item.title}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="anno-list">
          {list.map((item: any) => (
            <a
              href={item.link}
              className="anno-item"
              target="_blank"
              key={item.id}
            >
              <p className="content">{item.title}</p>
            </a>
          ))}
        </div>
      )}

      {/* {list.map((item: any) => (
        <Link to={`/notice/${item.id}`} className="anno-item" key={item.id}>
          <span>[公告]</span> <p className="content">{item.title}</p>
        </Link>
      ))} */}
    </div>
  );
}
export default connect()(Index);
